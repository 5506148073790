import Api from "../Api";
import {
  BACKEND_AUTH_URL,
  LOGIN_URL,
  REFRESH_TOKEN_URL,
  REGISTER_URL,
  LOGOUT_URL,
  FORGOT_PASSWORD_URL,
  RESET_PASSWORD_URL,
  ACTIVATE_ACCOUNT_URL,
  USERS_URL,
  CHANGE_PASSWORD_URL,
} from "../constants/endpoints";
import { getCaptchaToken } from "../../shared/utils/utils";

export async function login(data) {
  const recaptchaToken = await getCaptchaToken("login");
  const payload = { ...data, recaptchaToken };

  return Api.post(`${BACKEND_AUTH_URL}${LOGIN_URL}`, payload).then(response => {
    Api.accessToken = response.accessToken;
    Api.refreshToken = response.refreshToken;
    return response;
  });
}

export async function forgotPassword(data) {
  const recaptchaToken = await getCaptchaToken("forgotPassword");
  const payload = { ...data, recaptchaToken };

  return Api.post(`${BACKEND_AUTH_URL}${FORGOT_PASSWORD_URL}`, payload);
}

export function resetPassword(data) {
  return Api.post(`${BACKEND_AUTH_URL}${RESET_PASSWORD_URL}`, data);
}

export function activateAccount(data) {
  return Api.post(`${BACKEND_AUTH_URL}${ACTIVATE_ACCOUNT_URL}`, data);
}

export function logout() {
  return Api.delete(`${BACKEND_AUTH_URL}${LOGOUT_URL}`).then(response => {
    Api.accessToken = null;
    Api.refreshToken = null;
    return response;
  });
}

export function register(data) {
  return Api.post(`${BACKEND_AUTH_URL}${REGISTER_URL}`, data);
}

export function changeUserRole(userId, roles) {
  return Api.patch(`${BACKEND_AUTH_URL}${REGISTER_URL}/${userId}`, {
    roles,
  });
}

export function changeUserPermissions(userId, roles, permissions) {
  return Api.patch(`${BACKEND_AUTH_URL}${REGISTER_URL}/${userId}`, {
    roles,
    permissions,
  });
}

export function disableUser(userId) {
  return Api.delete(`${BACKEND_AUTH_URL}${REGISTER_URL}/${userId}`);
}

export function restoreUser(userId) {
  return Api.post(`${BACKEND_AUTH_URL}${REGISTER_URL}/${userId}/reactivations`);
}

export function refreshToken(refreshToken) {
  return Api.post(`${BACKEND_AUTH_URL}${REFRESH_TOKEN_URL}`, { refreshToken })
    .then(response => {
      Api.accessToken = response.accessToken;
      return Promise.resolve(response);
    })
    .catch(response => Promise.reject(response));
}

export function getUsers() {
  return Api.get(`${BACKEND_AUTH_URL}${USERS_URL}`);
}

export function getUserProfile(userId) {
  return Api.get(`${BACKEND_AUTH_URL}${USERS_URL}/${userId}`);
}

export function updateUserProfile(userId, userData) {
  return Api.put(`${BACKEND_AUTH_URL}${USERS_URL}/${userId}`, {
    data: {
      userId,
      ...userData,
    },
  });
}

export function updateUserAvatar(userId, file) {
  const bodyFormData = new FormData();
  bodyFormData.set("file", file);
  return Api.post(
    `${BACKEND_AUTH_URL}${USERS_URL}/${userId}/avatar`,
    bodyFormData,
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  );
}

export function changeUserPassword(
  oldPassword,
  newPassword,
  repeatedNewPassword
) {
  const url = `${BACKEND_AUTH_URL}${CHANGE_PASSWORD_URL}`;
  const payload = {
    data: {
      oldPassword,
      newPassword,
      repeatedNewPassword,
    },
  };

  return Api.put(url, payload);
}
