import React from "react";
import isEmpty from "lodash/isEmpty";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import WarningIcon from "@material-ui/icons/Warning";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";
import {
  SUCCESS_VARIANT_TYPE,
  WARNING_VARIANT_TYPE,
  ERROR_VARIANT_TYPE,
  INFO_VARIANT_TYPE
} from "../snackbars";

const variantIcon = {
  [SUCCESS_VARIANT_TYPE]: CheckCircleIcon,
  [WARNING_VARIANT_TYPE]: WarningIcon,
  [ERROR_VARIANT_TYPE]: ErrorIcon,
  [INFO_VARIANT_TYPE]: InfoIcon
};

function MySnackbarContentWrapper({
  classes,
  message,
  additionalDetails,
  variant,
  onClose,
  showMore
}) {
  const Icon = variantIcon[variant] || InfoIcon;
  return (
    <SnackbarContent
      className={classes[variant]}
      aria-describedby="client-snackbar"
      id="client-snackbar"
      message={
        <span id="client-snackbar-message" className={classes.message}>
          <Icon id="client-snackbar-close" className={classes.iconVariant} />
          {message}
        </span>
      }
      action={[
        !isEmpty(additionalDetails) && (
          <Button key="undo" color="primary" size="small" onClick={showMore}>
            More Info
          </Button>
        ),
        <IconButton
          id={"close-snackbar-button"}
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>
      ]}
    />
  );
}

export default MySnackbarContentWrapper;
