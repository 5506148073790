import validateRequired from "./validateRequired";
import validateEmail from "./validateEmail";
import validateURL from "./validateURL";
import validateText from "./validateText";
import validateNumber from "./validateNumber";
import validateDate from "./validateDate";
import validateObject from "./validateObject";
import validateVersion from "./validateVersion";
import validateJSON from "./validateJSON";
import validateJSONSchema from "./validateJSONSchema";

const REQUIRED = "required";
const EMAIL = "Email";
const TEXT = "Text";
const NUMBER = "Number";
const DATE = "Date";
const OBJECT = "Object";
const JSON_VALIDATION = "JSON";
const JSON_SCHEMA_VALIDATION = "JSONSchema";
const VERSION = "Version";
const URL = "URL";

const VALIDATORS = {
  [REQUIRED]: validateRequired,
  [EMAIL]: validateEmail,
  [URL]: validateURL,
  [TEXT]: validateText,
  [NUMBER]: validateNumber,
  [DATE]: validateDate,
  [OBJECT]: validateObject,
  [VERSION]: validateVersion,
  [JSON_VALIDATION]: validateJSON,
  [JSON_SCHEMA_VALIDATION]: validateJSONSchema
};

function validateField(scope, field) {
  const requiredValidator = {
    name: "required",
    params: field.required
  };
  const validators = [requiredValidator, ...field.validators];
  return value =>
    validators.reduce(
      (error, validator) =>
        error ||
        (VALIDATORS[validator.name] &&
          VALIDATORS[validator.name](
            value,
            validator.params,
            field.type,
            scope
          )),
      undefined
    );
}

export default validateField;
