import React from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ButtonMUI from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Fab from "@material-ui/core/Fab";
import {
  getHoverColor,
  getButtonColor,
  getButtonLabelColor,
} from "../../styles/theme";
import Tooltip from "../tooltip/Tooltip";
import { useTheme } from "../../../store/themeContext";

function Button({
  id,
  label,
  onClick,
  type,
  fullWidth,
  disabled,
  color,
  Icon,
  disabledTooltip,
  tooltip,
  showIcon,
  variant,
}) {
  const isDesktop = useMediaQuery("(min-width:768px)");
  const isSecondaryColor = color === "secondary";

  const { isDarkModeEnabled } = useTheme();

  const classes = useStyles({
    backgroundButtonColor: getButtonColor(color, isDarkModeEnabled),
    hoverButtonColor: getHoverColor(color, isDarkModeEnabled),
    buttonLabelColor: getButtonLabelColor(color, disabled),
  });

  const buttonColor = isSecondaryColor ? "secondary" : "primary";

  return (
    <Tooltip title={disabled ? disabledTooltip : tooltip}>
      {isDesktop || !showIcon ? (
        <ButtonMUI
          id={id}
          type={type}
          variant={variant || "contained"}
          className={classes.button}
          classes={{
            label: classes.label,
          }}
          onClick={onClick}
          fullWidth={fullWidth}
          disabled={disabled}
          color={buttonColor}
        >
          {showIcon && Icon && <Icon className={classes.icon} />}
          {label}
        </ButtonMUI>
      ) : (
        <Fab
          id={id}
          type={type}
          className={classes.marginFab}
          classes={{
            label: classes.label,
          }}
          size="medium"
          onClick={onClick}
          disabled={disabled}
          color={buttonColor}
        >
          <Icon />
        </Fab>
      )}
    </Tooltip>
  );
}

const useStyles = makeStyles(_theme => ({
  button: props => ({
    margin: "5px",
    minWidth: "138px",
    backgroundColor: props.backgroundButtonColor,
    "&:hover": {
      backgroundColor: props.hoverButtonColor,
    },
    "&:active": {
      backgroundColor: props.hoverButtonColor,
    },
    "&:focus": {
      backgroundColor: props.hoverButtonColor,
    },
  }),
  marginFab: props => ({
    margin: "0 8px 0 8px",
    backgroundColor: props.buttonColor,
  }),
  icon: {
    fontSize: 20,
    opacity: 0.9,
    marginRight: "6px",
  },
  label: props => ({
    display: "flex",
    justifyContent: "space-evenly",
    color: props.buttonLabelColor,
  }),
}));

export default Button;
