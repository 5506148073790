import isObject from "lodash/isObject";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import { JSON_FIELD_TYPE, isDateFiledType } from "../constants/fields";

export const requiredError = "Field is required";
export const noError = "";

export default function validateRequired(value, required, type) {
  if (!required) {
    return noError;
  }

  const isJSONFieldType = type === JSON_FIELD_TYPE;
  const isDateFieldType = isDateFiledType(type);

  if (isJSONFieldType) {
    const hasValue = value && value.replace(/[\r\n\t" "]+/g, "") !== "{}";
    return hasValue ? noError : requiredError;
  }

  if (isDateFieldType) {
    const hasValue = !isNil(value);
    return hasValue ? noError : requiredError;
  }

  if (isObject(value)) {
    const hasValue = !isEmpty(value);
    return hasValue ? noError : requiredError;
  }

  const hasValue = !isNil(value);

  if (hasValue) {
    return noError;
  }

  return requiredError;
}
