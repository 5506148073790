import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import get from "lodash/get";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";
import { Box, FormHelperText } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";

const DEFAULT_FORMATS = {
  Date: "yyyy-MM-dd",
  Time: undefined,
  DateTime: undefined,
};

function DateField({
  id,
  input: { onChange, value, type },
  meta,
  label,
  required,
  field,
  disabled,
}) {
  let DateComponent = null;
  switch (type) {
  case "Date":
    DateComponent = KeyboardDatePicker;
    break;
  case "Time":
    DateComponent = KeyboardTimePicker;
    break;
  case "DateTime":
    DateComponent = KeyboardDateTimePicker;
    break;
  default:
    break;
  }
  const helperText = meta.touched ? meta.error : undefined;
  const hasError = meta.error && meta.touched;
  const format = get(field.params, "format", DEFAULT_FORMATS[type]);
  const isInitialDate = get(field.params, "isInitialDate", false);

  const handleChange = e => {
    let date;
    const HOURS = 23;
    const MINUTES = 59;
    const SECONDS = 59;
    const MILLISECONDS = 99;

    if (type === "DateTime") {
      date = e ? new Date(e) : undefined;
    } else {
      date = isInitialDate
        ? new Date(e?.getFullYear(), e?.getMonth(), e?.getDate())
        : new Date(e?.getFullYear(), e?.getMonth(), e?.getDate(),
          HOURS, MINUTES, SECONDS, MILLISECONDS);
    }
    onChange(date);
  };

  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DateComponent
          id={id}
          required={required}
          label={label}
          ampm={false}
          value={value || null}
          onChange={handleChange}
          format={format}
          error={hasError}
          helperText={helperText}
          clearable
          disabled={disabled}
        />
      </MuiPickersUtilsProvider>
      <FormHelperText>
        {type === "DateTime" && value && (
          <Box component={"span"} display="flex" alignItems="center">
            <InfoIcon fontSize="small" />
            Database value {value instanceof Date ? value.toISOString() : value}
          </Box>
        )}
      </FormHelperText>
    </>
  );
}

export default DateField;
