import { useCallback, useEffect, useState } from "react";
import { useSnackbar } from "../../../../store/snackbarContext";

function useCustomSnackbar() {
  const {
    snackbar, open, closeSnackbar, isBackendSnackbar, openShowMore, errorDetails,
  } = useSnackbar();

  const onClose = useCallback(
    (_event, reason) => reason !== "clickaway" && closeSnackbar(),
    [closeSnackbar]
  );

  const [internalSnackbar, setInternalSnackbar] = useState({});
  useEffect(() => {
    if (!isBackendSnackbar) {
      setInternalSnackbar(snackbar);
    }
  }, [snackbar, isBackendSnackbar]);

  const { variant, message, additionalDetails } = internalSnackbar;

  const anchorOrigin = {
    vertical: "bottom",
    horizontal: "center",
  };

  return {
    open,
    variant,
    message,
    onClose,
    closeSnackbar,
    anchorOrigin,
    openShowMore,
    additionalDetails,
    errorDetails,
  };
}

export default useCustomSnackbar;
