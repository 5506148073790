import React from "react";
import ListItem from "@material-ui/core/ListItem";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import InfiniteScrollContainer from "../../infinite-scroll-container/InfiniteScrollContainer";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ListItemMultipleSubtitles from "./ListItemMultipleSubtitles";

const useStyles = makeStyles(_theme => ({
  itemText: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  noDataRow: {
    textAlign: "center",
    width: "100%",
  },
  noDataLabel: {
    padding: "12px",
  },
}));

function AvailableItemsList({
  id,
  items,
  selectedItems,
  selectItem,
  deselectItem,
  disabled,
}) {
  const hasItems = items.length === 0;

  const classes = useStyles();

  const EXTRA_HEIGHT = 80;
  const NORMAL_HEIGHT = 60;

  const heightPerItem = items.some(
    item => item.extraSubtitles && item.extraSubtitles.length > 0)
    ? EXTRA_HEIGHT
    : NORMAL_HEIGHT;

  const itemRenderFunction = item => {
    const isItemSelected = !!selectedItems.find(
      i => i.value === item.value
    );
    const onClickItem = () =>
      isItemSelected ? deselectItem(item) : selectItem(item);
    return (
      <ListItem
        key={`${id}-item-${item.value}`}
        button={!disabled}
        dense
        onClick={() => !disabled && onClickItem()}
        id={`${id}-item-${item.value}`}
      >
        <ListItemIcon>
          <Checkbox
            color={"primary"}
            id={`${id}-checkbox-item-${item.value}`}
            checked={isItemSelected}
            disabled={disabled}
            tabIndex={-1}
            disableRipple
          />
        </ListItemIcon>
        <ListItemText
          primary={item.label}
          classes={{
            root: classes.itemText,
          }}
          primaryTypographyProps={{
            noWrap: true,
            className: classes.itemText,
          }}
          secondary={<ListItemMultipleSubtitles mainSubtitle={item.subtitle} extras={item.extraSubtitles} />}
          disableTypography={true}
        />
      </ListItem>
    );
  };

  return hasItems ? (
    <div className={classes.noDataRow}>
      <div id={`${id}-no-items-label`} className={classes.noDataLabel}>
        {"No items"}
      </div>
    </div>
  ) : (
    <InfiniteScrollContainer
      items={items}
      initialItems={10}
      heightPerItem={heightPerItem}
      maxHeight={288}
      component={itemRenderFunction}
    />
  );
}

export default AvailableItemsList;
