import React, { useState, useEffect, useCallback } from "react";
import find from "lodash/find";
import get from "lodash/get";
import {
  getUrlParam,
  updateUrlParams,
  redirect,
  getAllAvailableVersions,
  getAllAvailableVersionsWithoutTags,
  createConsoleError,
} from "../shared/utils/utils";
import { getApps, getLanguages, getCountries } from "../http/config";

const ConfigurationContext = React.createContext();

function useConfiguration() {
  const context = React.useContext(ConfigurationContext);
  if (!context) {
    throw new Error(
      "[COCO STATE ERROR] useConfiguration must be used within a ConfigurationProvider"
    );
  }
  return context;
}

function ConfigurationProvider(props) {
  const [scope, setScope] = useState({
    application: getUrlParam("application"),
    country: getUrlParam("country"),
    version: getUrlParam("version"),
  });

  const { application, country, version } = scope;

  const [platforms, setPlatforms] = useState([]);

  const [applications, setApplications] = useState([]);

  const [countries, setCountries] = useState([]);
  const [countriesList, setCountriesList] = useState([]);

  const [versions, setVersions] = useState([]);

  const [availableVersions, setAvailableVersions] = useState([]);
  const [availableVersionsWithoutTags, setAvailableVersionsWithoutTags] = useState([]);

  const [languages, setLanguages] = useState([]);

  useEffect(() => {
    const performGetAppsRequest = async () => {
      try {
        const response = await getApps();
        setApplications(response.applications);
      } catch (error) {
        createConsoleError("Error", error);
      }
    };
    performGetAppsRequest();
  }, []);

  const refreshVersions = useCallback(async () => {
    if (application) {
      try {
        const response = await getApps(application);
        setCountries(response.countries);
        setPlatforms(response.platforms);
        setAvailableVersions(getAllAvailableVersions(response));
        setAvailableVersionsWithoutTags(
          getAllAvailableVersionsWithoutTags(response)
        );
      } catch (error) {
        createConsoleError("Error", error);
      }
    }
  }, [application]);

  useEffect(() => {
    updateUrlParams({ application });
    refreshVersions();
  }, [application, refreshVersions]);

  useEffect(() => {
    updateUrlParams({ country });
    const newVersions = get(
      find(countries, { country }),
      "versions",
      []
    );
    setVersions(newVersions);
  }, [country, countries]);

  useEffect(() => {
    async function performGetLanguages() {
      try {
        const languages = await getLanguages(country);
        setLanguages(languages);
      } catch (error) {
        createConsoleError("Error", error);
      }
    }
    if (country) {
      performGetLanguages();
    } else {
      setLanguages([]);
    }
  }, [country]);

  useEffect(() => {
    updateUrlParams({ version });
  }, [version]);

  useEffect(() => {
    if (!!application) {
      redirect();
    }
  }, [application, country, version]);

  const onChangeApp = useCallback(value => {
    setScope({
      application: value,
      country: null,
      version: null,
    });
  }, []);

  const onChangeCountry = useCallback(
    value => {
      setScope({
        application,
        country: value,
        version: null,
      });
    },
    [application]
  );

  const onChangeVersion = useCallback(
    value => {
      setScope({
        application,
        country,
        version: value,
      });
    },
    [application, country]
  );

  const getCountriesList = useCallback(
    async () => {
      const countriesListInfo = countriesList.length !== 0
        ? countriesList
        : await getCountries();

      setCountriesList(countriesListInfo);

      return countriesListInfo;
    },
    [countriesList, setCountriesList]
  );

  const state = {
    application,
    country,
    version,

    scope,

    applications,
    setApplications,

    countries,
    setCountries,

    getCountriesList,

    versions,
    setVersions,

    setScope,

    availableVersions,
    availableVersionsWithoutTags,

    onChangeApp,
    onChangeCountry,
    onChangeVersion,

    refreshVersions,

    languages,

    platforms,

    ...props.store,
  };

  return <ConfigurationContext.Provider value={state} {...props} />;
}

export { ConfigurationProvider, useConfiguration };
