import React from "react";
import find from "lodash/find";
import get from "lodash/get";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Paper from "@material-ui/core/Paper";
import FormLabel from "@material-ui/core/FormLabel";
import Divider from "@material-ui/core/Divider";
import FormHelperText from "@material-ui/core/FormHelperText";
import { FIELD_COMPONENTS } from "../form-builder/constants/fields";
import { RED } from "../../styles/theme";
import isString from "lodash/isString";
import { stringToId } from "../../utils/utils";

function ObjectField({
  id,
  field,
  label,
  meta,
  required,
  relatedContents,
  input: { name, onChange, value, ...restInput },
  ...rest
}) {
  const hasError = meta.touched ? !!find(meta.error, undefined) : undefined;
  const classes = useStyles({ hasError });
  const itemsShape = find(
    field.validators,
    validator => validator.name === "Object"
  ).params.shape;
  const fields = Object.keys(itemsShape).map(key => ({
    label: itemsShape[key].label,
    onChange: event =>
      onChange({
        ...value,
        [key]: get(event, "target.value", event || null),
      }),
    meta: {
      error: get(meta, `error[${key}]`, null),
      touched: meta.touched,
    },
    value: get(value, key, ""),
    type: itemsShape[key].type,
    required: itemsShape[key].required,
    validators: itemsShape[key].validators,
  }));

  const helperText = getHelperText(hasError, meta);

  return (
    <React.Fragment>
      <Paper className={classes.paper} id={id}>
        <FormLabel
          className={classes.label}
          component={"div"}
          focused={false}
          required={required}
          error={hasError}
        >
          {label}
        </FormLabel>
        <Divider className={classes.divider} />
        <div className={classes.fieldsContainer}>
          {fields.map(field => {
            const FieldComponent = FIELD_COMPONENTS[field.type];
            return (
              <div className={classes.padding} key={`${id}-${field.label}`}>
                <FieldComponent
                  id={stringToId(`${id}-${field.label}`)}
                  input={{
                    name: field.label,
                    onChange: field.onChange,
                    value: field.value,
                    type: field.type,
                  }}
                  label={field.label}
                  validators={field.validators}
                  field={field}
                  required={field.required}
                  meta={field.meta}
                  relatedContents={relatedContents}
                  fullWidth
                  rest={rest}
                />
              </div>
            );
          })}
        </div>
      </Paper>
      <FormHelperText error={hasError}>{helperText}</FormHelperText>
    </React.Fragment>
  );
}

const useStyles = makeStyles(theme => ({
  label: {
    flexGrow: 1,
    padding: "16px",
  },
  paper: {
    flexGrow: 1,
    border: `1px solid ${theme.palette.divider}`,
    borderColor: props => (props.hasError ? RED : theme.palette.divider),
  },
  divider: {
    marginBottom: "4px",
    backgroundColor: props => (props.hasError ? RED : theme.palette.divider),
  },
  title: {
    marginBottom: "6px",
  },
  fieldsContainer: {
    padding: "0 8px 16px 8px",
  },
  padding: {
    padding: "16px 12px",
  },
}));

function getHelperText(hasError, meta) {
  if (!hasError) {
    return null;
  }

  if (isString(meta.error)) {
    return meta.error;
  }

  return "Some fields are invalid";
}

export default ObjectField;
