import React, { useState, useEffect } from "react";
import { getModules } from "../http/config";
import { updateUrlParams, getUrlParam, createConsoleError } from "../shared/utils/utils";
import { useConfiguration } from "./configurationContext";

const ModuleContext = React.createContext();

function useModule() {
  const context = React.useContext(ModuleContext);
  if (!context) {
    throw new Error(
      "[COCO STATE ERROR] useModule must be used within a ModuleProvider"
    );
  }
  return context;
}

function ModuleProvider(props) {
  const { application, country, version } = useConfiguration();

  const [module, setModule] = useState();
  const [modules, setModules] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  function setUrlParams(module){
    updateUrlParams({ module: module.slug });
  }

  useEffect(() => {
    try {
      module && setUrlParams(module);
    } catch(error) {
      createConsoleError("Error setting module slug", error);
    }
  }, [module]);

  useEffect(() => {
    async function performGetModulesRequest() {
      setIsLoading(true);
      try {
        const response = await getModules(application, country, version);

        const newModules = response.modules;
        const moduleInUrlParam = getUrlParam("module");
        const selectedModule = newModules.find(
          itemModule => itemModule.slug === moduleInUrlParam
        );

        setModules(newModules);
        setModule(selectedModule);
      } catch (error) {
        createConsoleError("Error fetching Modules", error);
      }
      setIsLoading(false);
    }

    const isConfigSelected = !!application;

    if (isConfigSelected) {
      performGetModulesRequest();
    } else {
      setModule();
      setModules([]);
    }
  }, [application, country, version]);

  const state = {
    module,
    setModule,
    modules,
    isLoading,
    ...props.store,
  };

  return <ModuleContext.Provider value={state} {...props} />;
}

export { ModuleProvider, useModule };
