import React from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ButtonMUI from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "../tooltip/Tooltip";

const useStyles = makeStyles(_theme => ({
  button: {
    margin: "5px",
  },
  paddingIcon: {
    paddingLeft: "6px",
    marginRight: "2px !important",
  },
  icon: {
    fontSize: 20,
    opacity: 0.9,
    marginRight: "6px",
  },
}));

function SecondaryButton({
  id,
  label,
  onClick,
  type,
  fullWidth,
  disabled,
  color,
  Icon,
  tooltip,
  showIcon,
  size,
  disabledTooltip,
}) {
  const isDesktop = useMediaQuery("(min-width:768px)");
  const classes = useStyles();
  return (
    <Tooltip title={disabled ? disabledTooltip : tooltip}>
      {isDesktop || !showIcon ? (
        <ButtonMUI
          id={id}
          type={type}
          variant="outlined"
          className={classes.button}
          size={size || "medium"}
          onClick={onClick}
          fullWidth={fullWidth}
          disabled={disabled}
          color={color || "default"}
        >
          {showIcon && Icon && <Icon className={classes.icon} />}
          {label}
        </ButtonMUI>
      ) : (
        <IconButton
          id={id}
          onClick={onClick}
          disabled={disabled}
          color="inherit"
        >
          <Icon />
        </IconButton>
      )}
    </Tooltip>
  );
}

export default SecondaryButton;
