import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { Router, Switch, Redirect } from "react-router-dom";
import history, {
  routes,
  CONFIG_PATHNAME,
  BASE_PATHNAME,
  OLD_DASHBOARD_URL
} from "./routes";
import RouteWithSubRoutes from "./shared/components/route-with-sub-routes/RouteWithSubRoutes";
import Loader from "./shared/components/loader/Loader";
import { ErrorBoundary } from "./shared/components/error-boundary/ErrorBoundary";
import Providers from "./store";
import { APP_TITLE } from "./shared/constants/config";
import AppErrorFallback from "./shared/components/error-boundary/AppErrorFallback";

function App() {
  const classes = useStyles({});
  const redirectToConfig = {
    pathname: CONFIG_PATHNAME,
    search: history.location.search
  };

  useEffect(() => {
    document.title = APP_TITLE;
  }, []);

  return (
    <ErrorBoundary FallbackComponent={AppErrorFallback}>
      <Providers>
        <Grid container className={classes.appContainer}>
          <Router history={history}>
            <React.Suspense fallback={<Loader isLoading isFullScreen />}>
              <Switch>
                <Redirect exact from={BASE_PATHNAME} to={redirectToConfig} />
                <Redirect from={OLD_DASHBOARD_URL} to={redirectToConfig} />
                {routes.map((route, i) => (
                  <RouteWithSubRoutes key={i} {...route} />
                ))}
              </Switch>
            </React.Suspense>
          </Router>
        </Grid>
      </Providers>
    </ErrorBoundary>
  );
}

const useStyles = makeStyles({
  appContainer: {
    height: "100%",
    maxHeight: "100%"
  }
});

export default App;
