import { useState, useEffect } from "react";
import get from "lodash/get";
import { createConsoleError } from "../../../utils/utils";

export default function useLanguageField(relatedContents) {
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function getLanguagesOptions() {
      setIsLoading(true);
      try {
        const relatedContent = get(relatedContents, "language", []);
        setOptions(
          relatedContent.map(option => ({
            label: option.published.name,
            value: option._id,
          }))
        );
      } catch (error) {
        createConsoleError("Error loading languages!", error);
      }
      setIsLoading(false);
    }

    getLanguagesOptions();
  }, [relatedContents]);

  return {
    options,
    isLoading,
  };
}
