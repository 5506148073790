import React from "react";
import qs from "query-string";
import get from "lodash/get";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import isArray from "lodash/isArray";
import reduce from "lodash/reduce";
import find from "lodash/find";
import uniqBy from "lodash/uniqBy";
import flatten from "lodash/flatten";
import every from "lodash/every";
import uniq from "lodash/uniq";
import isEqual from "lodash/isEqual";
import isEmpty from "lodash/isEmpty";
import isObject from "lodash/isObject";
import omitBy from "lodash/omitBy";
import isUndefined from "lodash/isUndefined";
import isNull from "lodash/isNull";
import isNil from "lodash/isNil";
import history from "../../routes";
import {
  MULTI_RELATION_FIELD_TYPE,
  SINGLE_RELATION_FIELD_TYPE,
  OBJECT_FIELD_TYPE,
  JSON_FIELD_TYPE,
  LINKED_FIELD_TYPE,
} from "../components/form-builder/constants/fields";
import { getTargetLinkedField } from "../components/linked-field/LinkedField";
import { getContents, getLanguages } from "../../http/config";
import { ENABLE_CAPTCHA, JSON_INDENTATION } from "../../shared/constants/config";

export const updateUrlParams = query =>
  history.push({
    pathname: history.location.pathname,
    search: qs.stringify(
      omitBy(
        {
          ...qs.parse(history.location.search),
          ...query,
        },
        value => isUndefined(value) || isNull(value)
      )
    ),
  });

const ignoreQueryPrefix = {
  ignoreQueryPrefix: true,
};

export const getUrlParam = param =>
  get(qs.parse(history.location.search, ignoreQueryPrefix), param);

export const isArrayEqual = (arr1 = [], arr2 = []) => isEqual(arr1, arr2);

export const isObjEqual = (obj1 = {}, obj2 = {}) => {
  const obj1Keys = Object.keys(obj1);
  if (obj1Keys.length !== Object.keys(obj2).length) {
    return false;
  }

  let result = true;
  obj1Keys.forEach(field => {
    result =
      (isArray(obj1[field])
        ? isArrayEqual(obj1[field], obj2[field])
        : isEqual(obj1[field], obj2[field])) && result;
  });
  return result;
};

export const addUrlParams = (url, params) => {
  let firstParam = url.indexOf("?") === -1;
  let urlWithParams = url;
  params.forEach(({ param, value }) => {
    if (value) {
      if (firstParam) {
        urlWithParams = `${urlWithParams}?${param}=${value}`;
        firstParam = false;
      } else {
        urlWithParams = `${urlWithParams}&${param}=${value}`;
      }
    }
  });
  return urlWithParams;
};

export function removeAllCharacters(string = "", characters = []) {
  const charactersToRemove = characters.join("|");
  const regex = new RegExp(charactersToRemove, "g");
  return string.replace(regex, "");
}

export function objectToText(object = {}) {
  const charactersToRemove = ["{", "}", "\"", ","];
  const prettifyObject = JSON.stringify(object, null, JSON_INDENTATION)
    .replace(/\[/g, "")
    .replace(/\]/g, "");
  return removeAllCharacters(prettifyObject, charactersToRemove);
}

export const isDeepEqual = (object1 = {}, object2 = {}) =>
  isObjEqual(object1, object2) && isObjEqual(object2, object1);

export function removeEmptyObjects(array) {
  return array.filter(value => Object.keys(value).length !== 0);
}

export function arrayOfObjectsToObjects(array) {
  return array.reduce(
    (object, element) => ({
      ...object,
      ...element,
    }),
    {}
  );
}

export function isContentPublished(content) {
  const contents = Array.isArray(content) ? content : [content];
  return contents.reduce(
    (allPublished, { published }) => allPublished || !isEmpty(published),
    false
  );
}

export function isContentDraft(content) {
  const contents = Array.isArray(content) ? content : [content];
  return !!contents.find(
    ({ published, draft, deletedAt }) =>
      (!isEmpty(draft) && !isDeepEqual(published, draft)) || deletedAt
  );
}

export function getPublishedVersionContent(modules, versionContent) {
  return modules
    .filter(module => !module.isCore)
    .reduce(
      (publishedContent, module) => ({
        ...publishedContent,
        [module.name]: getPublishedContent(
          module,
          versionContent.filter(content => module.slug === content.module)
        ),
      }),
      {}
    );
}

export function getDraftVersionContent(modules, versionContent) {
  return modules
    .filter(module => !module.isCore)
    .reduce(
      (publishedContent, module) => ({
        ...publishedContent,
        [module.name]: getDraftContent(
          module,
          versionContent.filter(content => module.slug === content.module)
        ),
      }),
      {}
    );
}

function isJSONField(field) {
  return field.type === JSON_FIELD_TYPE;
}

export function isValidJSONValue(value) {
  try {
    JSON.parse(value);
    return true;
  } catch (error) {
    return false;
  }
}

export function getDraftContent(module, populatedContents) {
  return populatedContents
    .filter(content => !content.deletedAt)
    .sort((a, b) => a.draft.sequence - b.draft.sequence)
    .map(content => {
      const position = get(content, "draft.sequence", undefined);
      const positionLabel = `position ${position + 1}`;
      const hasPositionDefined = !isNil(position);
      const contentObject = arrayOfObjectsToObjects(
        module.fields.map(field => {
          const valueRaw = get(content, `draft.${field.slug}`, undefined);
          const value =
            isJSONField(field) && isValidJSONValue(valueRaw)
              ? JSON.parse(valueRaw)
              : valueRaw;
          return {
            [field.label]: value,
          };
        })
      );
      return hasPositionDefined
        ? {
          [positionLabel]: contentObject,
        }
        : contentObject;
    });
}

export function getPublishedContent(module, populatedContents) {
  return populatedContents
    .sort((a, b) => a.published.sequence - b.published.sequence)
    .map(content => {
      const position = get(content, "published.sequence", undefined);
      const positionLabel = `position ${position + 1}`;
      const hasPositionDefined = !isNil(position);
      const contentObject = arrayOfObjectsToObjects(
        module.fields.map(field => {
          const valueRaw = get(content, `published.${field.slug}`, undefined);
          const value =
            isJSONField(field) && isValidJSONValue(valueRaw)
              ? JSON.parse(valueRaw)
              : valueRaw;
          return {
            [field.label]: value,
          };
        })
      );
      return hasPositionDefined
        ? {
          [positionLabel]: contentObject,
        }
        : contentObject;
    });
}

export function getSelectedContent(content, populatedContents) {
  return !!content
    ? [populatedContents.find(({ _id }) => _id === content._id)]
    : populatedContents;
}

export function checkIfIsMultiContent(module, content) {
  return !module.isSingle && !content;
}

export function checkIfIsSingleContent(module, content) {
  const isSingleContent = module.isSingle && content;
  const isMultipleContentSelected = !module.isSingle && content;
  return !!isSingleContent || !!isMultipleContentSelected;
}

export const redirect = (pathname, data, removeQueryParams) => {
  history.push({
    pathname: pathname || history.location.pathname,
    search: removeQueryParams ? null : history.location.search,
    state: { ...data },
  });
};

export const getModule = (modules, slug, scope, isCore) =>
  find(
    modules,
    module =>
      module.slug === slug && module.scope === scope && module.isCore === isCore
  );

export const showInfoLabel = module =>
  reduce(
    module.fields,
    (result, { type }) =>
      result ||
      type === MULTI_RELATION_FIELD_TYPE ||
      type === SINGLE_RELATION_FIELD_TYPE,
    false
  );

export function getLanguageOptions(languages) {
  return languages.map(({ abbreviation, name }) => ({
    label: name,
    value: abbreviation,
  }));
}

export function getAppOptions(apps) {
  return apps
    .map(({ name, slug }) => ({
      label: name,
      value: slug,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
}

export function getAppOptionsById(apps) {
  return apps.map(({ name, _id }) => ({
    label: name,
    value: _id,
  }));
}

export function getReleaseTrainsOptions(releaseTrains) {
  return releaseTrains.map(({published: { release_number }}) => ({
    label: release_number,
    value: release_number,
  }));
}

export function getAppSlugById(id, apps) {
  return get(
    apps.find(app => app._id === id),
    "slug",
    null
  );
}

export function getAppIdBySlug(slug, apps) {
  return get(
    apps.find(app => app.slug === slug),
    "_id",
    null
  );
}

export function getCountryOptions(countries) {
  return countries
    .map(({ country, label }) => ({
      label: `${country} (${label})`,
      value: country,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
}

export function getCountryOptionsById(countries) {
  return countries.map(({ country, label, countryId }) => ({
    label: `${label} (${country})`,
    value: countryId,
  }));
}

export function getMultipleCountryOptionsById(countries) {
  return countries.map(({ _id, name, slug, flag }) => ({
    label: `${flag} ${name.common} (${slug})`,
    value: _id,
    subtitle: name.official,
  }));
}

export function getMultipleModulesOptionsById(modules = []) {
  return modules.map(({ _id, name, isCore, isSingle }) => ({
    label: name,
    value: _id,
    subtitle: `${isSingle ? "Single Content" : "Multiple Content"} ${isCore ? "Core" : ""
    } Module`,
  }));
}

export function getModuleOptions(modules = []) {
  return modules.map(module => ({
    label: module.name,
    value: module.slug,
    scope: module.scope,
  }));
}

export function getVersions(countries) {
  return uniqBy(
    reduce(
      countries,
      (result, country) => [...result, ...country.versions],
      []
    ),
    "version"
  );
}

export function getVersionOptions(versions) {
  return sortVersions(versions).map(({ version, fromVersion }) => ({
    label: version,
    value: version,
    icon: fromVersion ? <LocalOfferIcon /> : null,
  }));
}

export function sortVersions(versions) {
  return versions.sort((a, b) => {
    if (a.version === b.version) {
      return 0;
    }
    return a.version < b.version ? -1 : 1;
  });
}

export function getVersionLabel(version, versions) {
  const tags = versions.filter(v => v.fromVersion === version);

  const hasTags = tags.length > 0;

  const tagsLabel = (
    <span>
      {version}
      {tags.map(tag => (
        <span key={tag} style={{ fontSize: 15 }}>
          <LocalOfferIcon
            fontSize={"inherit"}
            style={{ marginLeft: 8, marginRight: 1, paddingTop: 4 }}
          />
          <span style={{ fontSize: 12 }}>{tag.version}</span>
        </span>
      ))}
    </span>
  );

  return hasTags && tagsLabel;
}

export function getVersionsWithoutTags(versions) {
  return sortVersions(versions)
    .filter(v => !v.fromVersion)
    .map(({ version }) => ({
      label: version,
      customLabel: getVersionLabel(version, versions),
      value: version,
    }));
}

export function getUsersOptions(users) {
  return users.map(user => ({
    label: user.email,
    value: user._id,
  }));
}

function getRelatedModuleMulti(validators) {
  const multiRelationValidator = find(
    validators,
    validator => validator.name === "MultiRelation"
  );
  const target = get(multiRelationValidator, "params.target");
  return [target];
}

function getRelatedModuleSingle(validators) {
  const multiRelationValidator = find(
    validators,
    validator => validator.name === "SingleRelation"
  );
  const target = get(multiRelationValidator, "params.target");
  return [target];
}

export function getRelatedModuleObject(validators) {
  const items = find(validators, validator => validator.name === "Object")
    .params.shape;
  const fields = Object.keys(items).map(key => ({
    type: items[key].type,
    validators: items[key].validators,
  }));
  return getRelatedModules(fields);
}

export function getRelatedModuleLinkedField(validators, fields) {
  const targetField = getTargetLinkedField(validators, fields);

  return (
    GET_RELATED_MODULES_PER_FIELD[targetField.type] &&
    GET_RELATED_MODULES_PER_FIELD[targetField.type](
      targetField.validators,
      fields
    )
  );
}

const GET_RELATED_MODULES_PER_FIELD = {
  [MULTI_RELATION_FIELD_TYPE]: getRelatedModuleMulti,
  [SINGLE_RELATION_FIELD_TYPE]: getRelatedModuleSingle,
  [OBJECT_FIELD_TYPE]: getRelatedModuleObject,
  [LINKED_FIELD_TYPE]: getRelatedModuleLinkedField,
};

export function getRelatedModules(fields = []) {
  return uniq(
    fields.reduce((relatedModules, { type, validators }) => {
      const getRelatedModuleField = GET_RELATED_MODULES_PER_FIELD[type];

      if (getRelatedModuleField) {
        const relatedModule = getRelatedModuleField(validators, fields);
        if (relatedModule?.[0]) {
          return relatedModules.concat(relatedModule);
        }
      }

      return relatedModules;
    }, [])
  );
}

export function getRelatedContentLanguageField(country) {
  return new Promise(async resolve => {
    const languages = await getLanguages(country);
    resolve(
      languages.map(({ abbreviation, name }) => ({
        _id: abbreviation,
        module: "language",
        draft: {
          name,
        },
        published: {
          name,
        },
      }))
    );
  });
}

export async function getRelatedContents(modules, module, scope) {
  const { app, country, version } = scope;
  const relatedModules = getRelatedModules(module.fields);
  const arrayOfRelatedContents = await Promise.all(
    relatedModules.map(mod => {
      if (mod === "language") {
        return getRelatedContentLanguageField(country);
      } else {
        const scope = modules.find(({ slug }) => mod === slug)?.scope;
        return scope ? getContents({ slug: mod, scope }, app, country, version): null;
      }
    })
  );
  return arrayOfRelatedContents.reduce((relatedContents, contents) => {
    if (contents?.[0]) {
      relatedContents[contents[0].module] = contents;
    }
    return relatedContents;
  }, {});
}

export async function getRelatedVersionContents(modules, scope) {
  const arrayOfRelatedVersionContents = await Promise.all(
    modules.map(module => getRelatedContents(modules, module, scope))
  );
  return arrayOfObjectsToObjects(
    removeEmptyObjects(arrayOfRelatedVersionContents)
  );
}

function getRelatedValue(content, value) {
  const relatedDraftValue = get(content, "draft.name", "");
  const relatedPublishedValue = get(content, "published.name", "");

  const relatedValue = relatedPublishedValue || relatedDraftValue || value;

  if (isArray(relatedValue)) {
    const publishedHint = get(content, "published.hint", "");
    const draftHint = get(content, "draft.hint", "");
    return publishedHint || draftHint || value;
  }

  return relatedValue;
}

export function populateRelatedValue(field, value, relatedContents, fields) {
  const relatedModules =
    GET_RELATED_MODULES_PER_FIELD[field.type] &&
    GET_RELATED_MODULES_PER_FIELD[field.type](field.validators, fields);
  const hasRelatedModules = relatedModules && relatedModules.length > 0;

  if (hasRelatedModules) {
    const contentRelated = relatedContents[relatedModules[0]];

    if (isArray(value)) {
      return value.map(val => {
        const content = contentRelated.find(content => content._id === val);
        return getRelatedValue(content, val);
      });
    }

    if (isObject(value)) {
      const items = find(
        field.validators,
        validator => validator.name === "Object"
      ).params.shape;
      return Object.keys(items).reduce((_values, field) => {
        return {
          ...value,
          [field]: populateRelatedValue(
            items[field],
            value[field],
            relatedContents,
            fields
          ),
        };
      }, value);
    }

    const content = contentRelated.find(content => content._id === value);
    return getRelatedValue(content, value);
  }
  return value;
}

export function populateRelatedContent(module, contents, relatedContents) {
  return contents.map(content =>
    reduce(
      module.fields,
      (populatedContent, field) => {
        const draftValue = populatedContent.draft[field.slug];
        const publishValue = populatedContent.published[field.slug];
        if (draftValue) {
          const populatedValue = populateRelatedValue(
            field,
            draftValue,
            relatedContents,
            module.fields
          );
          populatedContent.draft[field.slug] = populatedValue;
        }
        if (publishValue) {
          const populatedValue = populateRelatedValue(
            field,
            publishValue,
            relatedContents,
            module.fields
          );
          populatedContent.published[field.slug] = populatedValue;
        }
        return populatedContent;
      },
      {
        ...content,
        draft: {
          ...content.draft,
        },
        published: {
          ...content.published,
        },
      }
    )
  );
}

export function populateRelatedVersionContent(
  modules,
  versionContent,
  relatedVersionContents
) {
  const arrayOfPopulateRelatedVersionContent = modules.map(module =>
    populateRelatedContent(
      module,
      versionContent.filter(content => content.module === module.slug),
      relatedVersionContents
    )
  );

  return flatten(arrayOfPopulateRelatedVersionContent);
}

export function setWithForTesting(width) {
  Object.defineProperty(window, "innerWidth", {
    writable: true,
    configurable: true,
    value: width,
  });
  window.matchMedia = jest.fn().mockImplementation(query => {
    return {
      matches: width >= 768 ? true : false,
      media: query,
      onchange: null,
      addListener: jest.fn(),
      removeListener: jest.fn(),
    };
  });
  const height = Math.round((width * 9) / 16);
  return { width, height };
}

export function getApplicationLabel(slug, applications = []) {
  return get(
    applications.find(app => app.slug === slug),
    "name",
    slug
  );
}

export function getUserEmail(userId, users = []) {
  return get(
    users.find(user => user._id === userId),
    "email",
    userId
  );
}

export function getApplicationLabelById(id, applications = []) {
  return get(
    applications.find(app => app._id === id),
    "name",
    id
  );
}

export function getCountryLabel(slug, countries = []) {
  const country = countries.find(({ country }) => country === slug);

  return country ? `${country.label} (${slug})` : slug;
}

export function getCountryLabelById(id, countries = []) {
  const country = countries.find(
    ({ countryId, _id }) => countryId === id || id === _id
  );
  return `${country.name.common} (${country.slug})`;
}

export function getMinVersions(country, countries = []) {
  return countries
    .find(c => country === c.country)
    ?.minVersions.reduce(
      (acc, { platform, ...others }) => ({ ...acc, [platform]: others }),
      {}
    );
}

export function getCountryLabelBySlug(slug, countries = []) {
  const country = countries.find(country => country.slug === slug);
  if (country) {
    return `${country.name.common} (${country.slug})`;
  }
  return slug;
}

export function getModuleLabelById(id, modules = []) {
  const module = modules.find(({ _id }) => id === _id);
  return module.name;
}

export function getModuleLabel(slug, modules = []) {
  const module = modules.find(module => module.slug === slug);
  if (module) {
    return module.name;
  }
  return slug;
}

export function getModuleScope(slug, modules = []) {
  const module = modules.find(module => module.slug === slug);
  if (module) {
    return module.scope;
  }
  return "version";
}

export function getLabels(values, applications, countries) {
  return reduce(
    values,
    (values, value, key) => {
      const hasValue = !!value;
      if (!hasValue) {
        return values;
      }
      if (key === "application") {
        return {
          ...values,
          application: getApplicationLabel(value, applications),
        };
      }
      if (key === "country") {
        return {
          ...values,
          country: getCountryLabel(value, countries),
        };
      }
      if (key === "countryIds") {
        return {
          ...values,
          countries: value.map(countryId => {
            const countrySlug = get(
              countries.find(country => countryId === country.countryId),
              "country",
              countryId
            );
            return getCountryLabel(countrySlug, countries);
          }),
        };
      }

      return values;
    },
    values
  );
}

export function getLabelsById(values, applications, countries, modules) {
  return reduce(
    values,
    (values, value, key) => {
      const hasValue = !!value;
      if (!hasValue) {
        return values;
      }
      if (key === "applicationId") {
        return {
          ...values,
          application: getApplicationLabelById(value, applications),
        };
      }
      if (key === "countryIds") {
        return {
          ...values,
          countries: value.map(countryId =>
            getCountryLabelById(countryId, countries)
          ),
        };
      }
      if (key === "moduleIds") {
        return {
          ...values,
          modules: value.map(moduleId => getModuleLabelById(moduleId, modules)),
        };
      }

      return values;
    },
    values
  );
}

export function parseJwt(jwtToken) {
  const base64Url = jwtToken.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const RADIX = 16;
  const POSITION_START = -2;
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        const cad = `00${c.charCodeAt(0).toString(RADIX)}`.slice(POSITION_START);
        return `%${cad}`;
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

export function getUserRoleFromJWT() {
  const jwtToken = sessionStorage.getItem("accessToken");

  if (!jwtToken) {
    return [];
  }
  const user = parseJwt(jwtToken);

  return getUserRoles(user);
}

export function getUserRoles(user) {
  return user.roles;
}

export function getUserIdFromJWT() {
  const jwtToken = sessionStorage.getItem("accessToken");

  if (!jwtToken) {
    return null;
  }
  const user = parseJwt(jwtToken);

  return user.id;
}

export function getTranslatableValue(language, values = []) {
  return get(
    values.find(({ lang }) => lang === language),
    "value",
    undefined
  );
}

export function getAllAvailableVersions(application) {
  const countries = application.countries;

  return uniq(
    flatten(countries.map(({ versions }) =>
      sortVersions(versions).map(({ version }) => version))
    ));
}

export function getAllAvailableVersionsWithoutTags(application) {
  const countries = application.countries;

  return uniq(
    flatten(
      countries.map(({ versions }) =>
        versions
          .filter(version => !version.fromVersion)
          .map(({ version }) => version)
      )
    )
  );
}

export function getContentValue(content, key, language) {
  const value = get(content, key, "");
  if (isArray(value)) {
    return language
      ? get(
        value.find(value => value.lang === language),
        "value",
        ""
      )
      : get(value, "[0]", "");
  }
  return value;
}

export function getModuleTitle(module, content) {
  const isMultiContent = !module.isSingle;
  const moduleName = module.name;

  if (isMultiContent) {
    const isNewMultiContent = content && isEmpty(content);

    return isNewMultiContent ? `Add New ${moduleName}` : moduleName;
  }

  return moduleName;
}

export function validatePassword(password, repeatedPassword) {
  const errors = {};
  const passwordsDoNotMatch =
    password && repeatedPassword && password !== repeatedPassword;

  if (passwordsDoNotMatch) {
    errors.password = " ";
    errors.repeatedPassword = "Passwords do not match!";
  }

  errors.password = {
    min8chars: {
      label: "Min 8 chars",
      failed: false,
    },
    minOneUpperCase: {
      label: "Min one uppercase",
      failed: false,
    },
    minOneLowerCase: {
      label: "Min one lowercase",
      failed: false,
    },
    minOneNumber: {
      label: "Min one number",
      failed: false,
    },
  };

  const MIN_CHARS = 8;
  const isMin8chars = password && password.length < MIN_CHARS;

  if (isMin8chars) {
    errors.password.min8chars.failed = true;
  }

  const oneUpperCaseRegex = /[A-Z]+/;
  const hasNotOneUppercase = oneUpperCaseRegex.exec(password) === null;
  if (hasNotOneUppercase) {
    errors.password.minOneUpperCase.failed = true;
  }

  const oneLowerCaseRegex = /[a-z]+/;
  const hasNotOneLowercase = oneLowerCaseRegex.exec(password) === null;
  if (hasNotOneLowercase) {
    errors.password.minOneLowerCase.failed = true;
  }

  const oneNumberRegex = /\d+/;
  const hasNotANumber = oneNumberRegex.exec(password) === null;
  if (hasNotANumber) {
    errors.password.minOneNumber.failed = true;
  }

  const isPasswordCorrect = every(
    Object.keys(errors.password).map(prop => errors.password[prop]),
    ["failed", false]
  );

  return isPasswordCorrect
    ? {
      ...errors,
      password: undefined,
    }
    : errors;
}

export function isValidDateValue(value) {
  return !isNaN(Date.parse(value));
}

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) {
    return "0 Bytes";
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export function loadRecaptcha(sitekey, language = "en") {
  const id = "g-recaptcha-api";
  const idTag = "g-recaptcha-root";
  const config = {
    sitekey,
    theme: "light",
    size: "invisible",
  };

  if (!ENABLE_CAPTCHA) {
    return;
  }

  if (document.getElementById(id)) {
    window.grecaptcha.render(idTag, config);
  } else {
    const script = document.createElement("script");

    window.onloadCallback = () => {
      window.grecaptcha.render(idTag, config);
    };

    script.id = id;
    script.async = true;
    script.defer = true;
    script.src =
      `https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit&hl=${language}`;

    document.body.appendChild(script);
  }
}

export async function getCaptchaToken(action) {
  if (ENABLE_CAPTCHA && window.grecaptcha) {
    try {
      return await window.grecaptcha.execute({ action });
    } catch (error) {
      createConsoleError("CAPTCHA ERROR", error);
    }
  }
  return undefined;
}

export function stringToId(value) {
  return String(value).replace(/\./g, "-").replace(/\s+/g, "-");
}

const filteredPlatformOptions = ["any"];

export function getPlatformOptions(modules, moduleSlug) {
  const module = modules.find(module => module.slug === moduleSlug);
  const platformField = module.fields.find(field => field.slug === "platform");
  return platformField.options.filter(
    option => !filteredPlatformOptions.includes(option.value)
  );
}

export function getPlatformLabel(slug, platforms = []) {
  return get(
    platforms.find(p => p.slug === slug),
    "label",
    ""
  );
}

export function hasFieldError(meta = {}) {
  const { touched = true, error } = meta;

  if (!error) {
    return false;
  }

  const isStringValue = typeof error === "string";

  if (isStringValue) {
    return error && touched;
  }

  const errors = Object.keys(error).map(err => error[err]);

  return touched && !every(errors, { failed: false });
}

export function getFAQsFileName(application, country, version) {
  const timeStamp = new Date().getTime();

  return `FAQs-${application}-${country}-${version}-${timeStamp}.xlsx`;
}

export function getFAQsData(populatedContents, languages) {
  return reduce(
    populatedContents,
    (sheets, content) => {
      languages.forEach(language => {
        sheets[language.name].push({
          section: content.draft.section,
          question: getTranslatableValue(
            language.abbreviation,
            content.draft.question
          ),
          answer: getTranslatableValue(
            language.abbreviation,
            content.draft.answer
          ),
        });
      });
      return sheets;
    },
    languages.reduce(
      (result, language) => ({
        ...result,
        [language.name]: [],
      }),
      {}
    )
  );
}

export function getCreativePackageFileName(application, country) {
  const timeStamp = new Date().getTime();

  return `CreativePackageList-${application}-${country}-${timeStamp}.xlsx`;
}

export function getCreativePackageData(populatedContents) {
  return {
    sheet: populatedContents.map(content => ({
      creative_package_id: content.draft.creative_package_id,
      title: content.draft.title,
      market: content.country,
    })),
  };
}

export function generateFile(
  data,
  filename = "file",
  type = "application/octet-stream;"
) {
  const fileName = filename;
  const url = URL.createObjectURL(new Blob([data], { type }));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
}

/**
 * Create a new console error object with the provided title and the error itself.
 *
 * @param {String} title - Title.
 * @param {String} error - Error message.
 * @returns {Object} - A new bad request error object.
 */
export function createConsoleError(title, error) {
  console.error(`${title} :${error}`);
}
