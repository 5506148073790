import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { DARK_GREY } from "../../../styles/theme";

const useStyles = makeStyles(_theme => ({
  list: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    color: DARK_GREY,
    listStyleType: "none",
    paddingLeft: 0,
  },
}));

function ListItemMultipleSubtitles({ mainSubtitle, extras = [] }) {
  const classes = useStyles();

  const subItem = (<li key={mainSubtitle}>{mainSubtitle}</li>);

  const extraItems = extras.map(extra =>
    <li key={extra}>{extra}</li>
  );

  return (
    <ul className={classes.list}>{subItem}{extraItems}</ul>
  );
}

export default ListItemMultipleSubtitles;
