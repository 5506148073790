import React, { useEffect } from "react";
import filter from "lodash/filter";
import find from "lodash/find";
import includes from "lodash/includes";
import { useContent } from "../../../../store/contentContext";

export default function useTableField(field, fields, value, onChange) {
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("id");
  const [rows, setRows] = React.useState(value || []);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [page, setPage] = React.useState(0);
  const [openEditRow, setOpenEditRow] = React.useState({
    open: false,
    isNewRow: true,
    row: {},
  });

  const shouldSaveContentOnRowChange = fields.length === 1;

  const { saveContent } = useContent();

  useEffect(() => {
    setRows(value);
  }, [value]);

  const itemsShape = find(
    field.validators,
    validator => validator.name === "Array"
  ).params.itemsShape;

  const columns = Object.keys(itemsShape).map(key => {
    return ({
      id: key,
      numeric: itemsShape[key] === "Number",
      label: key,
      type: itemsShape[key],
      // if presentation is not defined on the field, thiw will show all columns
      isInPresentation: field.presentation?.includes(key) ?? true,
    });});

  function createNewRow(newRow) {
    const newRows = [newRow, ...rows];
    onChange(newRows);
    setRows(newRows);
    shouldSaveContentOnRowChange && saveContent({[field.slug]: newRows});
    setSelectedRows([]);
    setOpenEditRow({
      open: false,
      isNewRow: true,
      row: {},
    });
  }

  function editRow(rowEdited, oldRow) {
    const restRows = filter(rows, row => !includes([oldRow], row));
    const newRows = [rowEdited, ...restRows];
    onChange(newRows);
    setRows(newRows);
    shouldSaveContentOnRowChange && saveContent({[field.slug]: newRows});
    setSelectedRows([]);
    setOpenEditRow({
      open: false,
      isNewRow: true,
      row: {},
    });
  }

  function deleteRows(rowsToDelete) {
    const newRows = filter(rows, row => !includes(rowsToDelete, row));
    onChange(newRows);
    setRows(newRows);
    shouldSaveContentOnRowChange && saveContent({[field.slug]: newRows});
    setSelectedRows([]);
  }

  return {
    columns,
    rows,
    rowsPerPage,
    setRowsPerPage,
    page,
    setPage,
    selectedRows,
    setSelectedRows,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    openEditRow,
    setOpenEditRow,
    createNewRow,
    editRow,
    deleteRows,
    itemsShape,
  };
}
