import React from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Search from "@material-ui/icons/Search";
import Close from "@material-ui/icons/Close";

function SearchField({
  id,
  filterByName,
  fullWidth,
  setFilterByName,
  placeholder
}) {
  const clearFilter = () => setFilterByName("");
  return (
    <TextField
      value={filterByName}
      id={id}
      fullWidth={fullWidth}
      onChange={event =>
        setFilterByName(event.target.value.trim() && event.target.value)
      }
      onPaste={event => {
        event.preventDefault();
        setFilterByName(event.clipboardData.getData("Text").trim());
      }}
      placeholder={placeholder}
      InputProps={{
        endAdornment: !!filterByName && (
          <InputAdornment position="end">
            <IconButton aria-label="Clear filter" onClick={clearFilter}>
              <Close />
            </IconButton>
          </InputAdornment>
        ),
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        )
      }}
    />
  );
}

SearchField.propTypes = {
  id: PropTypes.string.isRequired,
  filterByName: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  setFilterByName: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool
};

export default SearchField;
