import isString from "lodash/isString";
import isNumber from "lodash/isNumber";

const stringMaxError = "Maximum length is";
const stringMinError = "Minimum length is";

const numberMaxError = "Maximum value is";
const numberMinError = "Minimum value is";

export default function validateLength(value, min = Infinity, max = -Infinity) {
  if (!value) {
    return null;
  }

  const isStringValue = isString(value);

  if (isStringValue) {
    if (value.length < min) {
      return `${stringMinError} ${min}`;
    }
    if (value.length > max) {
      return `${stringMaxError} ${max}`;
    }
  }

  const isNumberValue = isNumber(value);

  if (isNumberValue) {
    if (value < min) {
      return `${numberMinError} ${min}`;
    }
    if (value > max) {
      return `${numberMaxError} ${max}`;
    }
  }

  return null;
}
