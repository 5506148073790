import validateLength from "./validateLength";

const emailError = "Invalid Email pattern";
export default function validateEmail(email, params = false) {

  //eslint-disable-next-line
  const emailRegex = (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

  if (!email) {
    return null;
  }

  const validEmailRegex = emailRegex.test(String(email));

  if (!validEmailRegex) {
    return emailError;
  }

  if (params) {
    const { min, max } = params;

    const errorLength = validateLength(email, min, max);

    if (errorLength) {
      return errorLength;
    }
  }

  return null;
}
