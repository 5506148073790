import React from "react";
import get from "lodash/get";
import Selector from "../selector/Selector";
import isArray from "lodash/isArray";
import find from "lodash/find";

export function getSingleRelationFieldOptions(relatedContents, validators) {
  const target = get(
    find(validators, validator => validator.name === "SingleRelation"),
    "params.target",
    false
  );
  const relatedContent = get(relatedContents, target, []);

  return relatedContent.map(item => ({
    label: isArray(item.draft.name) ? item.draft.hint : item.draft.name,
    value: item._id
  }));
}

export default ({
  input: { name, onChange, value, ...restInput },
  meta,
  validators,
  relatedContents,
  ...rest
}) => {
  return (
    <Selector
      {...rest}
      options={getSingleRelationFieldOptions(relatedContents, validators)}
      name={name}
      helperText={meta.touched ? meta.error : undefined}
      error={meta.error && meta.touched}
      inputProps={restInput}
      onChange={value => onChange(get(value, "value", undefined))}
      value={value}
    />
  );
};
