import React from 'react';
import HelpIcon from '../../../shared/components/help-icon/HelpIcon';

const LabelForm = (label, help, fontSize = 'large') => {
  return (
    <span>
      {label}
      {help && (
        <HelpIcon
          helpText={help}
          colorIcon={'rgba(0, 0, 0, 0.54)'}
          colorIconHover={'rgba(0, 0, 0, 0.32)'}
          fontSize={fontSize}
        />
      )}
    </span>
  )
};

export default LabelForm;
