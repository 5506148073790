const versionError = "Invalid Version pattern";

const versionRegex = /^\d{1,2}\.\d{1,2}\.\d{1,2}$/;

export default function validateVersion(version) {
  if (!version) {
    return null;
  }

  const validVersionRegex = versionRegex.test(String(version).toLowerCase());

  if (!validVersionRegex) {
    return versionError;
  }

  return null;
}
