import React from "react";
import ListItem from "@material-ui/core/ListItem";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Tooltip from "../../tooltip/Tooltip";

function SelectAllItems({
  disabled,
  areAllItemsSelected,
  selectAllItems: toggleAllItems
}) {
  return (
    <ListItem>
      <ListItemIcon>
        <Tooltip
          title={
            areAllItemsSelected
              ? "All assets already selected"
              : "Select all assets"
          }
        >
          <Checkbox
            color={"primary"}
            id={"select-all-items"}
            checked={areAllItemsSelected}
            disabled={disabled}
            tabIndex={-1}
            onClick={toggleAllItems}
            disableRipple
          />
        </Tooltip>
      </ListItemIcon>
      <ListItemText disableTypography primary="Select all assets" />
    </ListItem>
  );
}

export default SelectAllItems;
