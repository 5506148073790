import React from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  TablePagination as TablePaginationMUI,
} from "@material-ui/core";

function TablePagination({
  rows,
  rowsPerPage,
  setRowsPerPage,
  page,
  setPage,
}) {
  const isDesktop = useMediaQuery("(min-width:768px)");
  const FIVE_ROWS = 5;
  const TEN_ROWS = 10;
  const TWENTY_FIVE_ROWS = 25;
  const FIFTY_ROWS = 50;

  return (
    <TablePaginationMUI
      rowsPerPageOptions={[FIVE_ROWS, TEN_ROWS, TWENTY_FIVE_ROWS, FIFTY_ROWS]}
      component="div"
      count={rows.length}
      rowsPerPage={rowsPerPage}
      page={page}
      backIconButtonProps={{
        "aria-label": "Previous Page",
      }}
      nextIconButtonProps={{
        "aria-label": "Next Page",
      }}
      onChangePage={(_event, newPage) => setPage(newPage)}
      onChangeRowsPerPage={event => setRowsPerPage(event.target.value)}
      labelRowsPerPage={isDesktop ? "Rows per page:" : "rows"}
      labelDisplayedRows={({ _from, _to, count }) => isDesktop
        ? `Page: ${page + 1} (${count} items)`
        : `Page: ${page + 1}`}
    />
  );
}

export default TablePagination;
