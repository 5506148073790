import React from "react";
import Selector from "../../selector/Selector";
import get from "lodash/get";
import isObject from "lodash/isObject";

const getOptions = options =>
  options.map(option =>
    isObject(option)
      ? option
      : {
          label: option,
          value: option
        }
  );

export default ({
  input: { name, onChange, value, ...restInput },
  meta,
  required,
  field,
  ...rest
}) => {
  return (
    <Selector
      {...rest}
      options={getOptions(get(field, "options", []))}
      field={field}
      disabledLabel={field.disabledLabel}
      name={name}
      required={required}
      helperText={meta.touched ? meta.error : undefined}
      error={meta.error && meta.touched}
      inputProps={restInput}
      onChange={value => onChange(get(value, "value", undefined))}
      value={value}
    />
  );
};
