import SingleRelationField from "../../single-relation-field/SingleRelationField";
import LanguageField from "../../language-field/LanguageField";
import MultiRelationField from "../../multi-relation-field/MultiRelationField";
import TextField from "../../text-field/TextField";
import Switch from "../../switch/Switch";
import DateField from "../../date-field/DateField";
import RichTextField from "../../rich-text-field/RichTextField";
import TableField from "../../table-field/TableField";
import ObjectField from "../../object-field/ObjectField";
import NumberField from "../../number-field/NumberField";
import PasswordField from "../../password-field/PasswordField";
import JSONField from "../../json-field/JSONField";
import MultipleChoiceField from "../../multiple-choice-field/MultipleChoiceField";
import ImageField from "../../image-field/ImageField";
import SelectorFormAdapter from "../components/SelectorFormAdapter";
import I18nFieldAdapter from "../components/I18nFieldAdapter";
import LinkedField from "../../linked-field/LinkedField";
import UUIDField from "../../uuid-field/UUIDField";

export const TEXT_FIELD_TYPE = "Text";
export const AUTO_UUID_FIELD_TYPE = "AutoUuid";
export const RICH_TEXT_FIELD_TYPE = "RichText";
export const BOOLEAN_FIELD_TYPE = "Boolean";
export const EMAIL_FIELD_TYPE = "Email";
export const ARRAY_FIELD_TYPE = "Array";
export const OBJECT_FIELD_TYPE = "Object";
export const NUMBER_FIELD_TYPE = "Number";
export const PASSWORD_FIELD_TYPE = "Password";
export const DATE_FIELD_TYPE = "Date";
export const TIME_FIELD_TYPE = "Time";
export const DATETIME_FIELD_TYPE = "DateTime";
export const SINGLE_CHOICE_FIELD_TYPE = "SingleChoice";
export const MULTIPLE_CHOICE_FIELD_TYPE = "MultiChoice";
export const MULTI_RELATION_FIELD_TYPE = "MultiRelation";
export const SINGLE_RELATION_FIELD_TYPE = "SingleRelation";
export const LANGUAGE_FIELD_TYPE = "Language";
export const IMAGE_FIELD_TYPE = "Image";
export const JSON_FIELD_TYPE = "JSON";
export const TEXT_I18N_FIELD_TYPE = "TextI18n";
export const RICH_TEXT_I18N_FIELD_TYPE = "RichTextI18n";
export const IMAGE_I18N_FIELD_TYPE = "ImageI18n";
export const URL_FIELD_TYPE = "URL";
export const LINKED_FIELD_TYPE = "Linked";

export const TRANSLATABLE_FIELDS = [
  TEXT_I18N_FIELD_TYPE,
  RICH_TEXT_I18N_FIELD_TYPE,
  IMAGE_I18N_FIELD_TYPE,
];

export function isTranslatableFieldType(fieldType) {
  return TRANSLATABLE_FIELDS.includes(fieldType);
}

export function isLinkedListFieldType(fieldType) {
  return fieldType === LINKED_FIELD_TYPE;
}

export function isDateFiledType(fieldType) {
  return fieldType.includes("Date");
}

export const FIELD_COMPONENTS = {
  [TEXT_FIELD_TYPE]: TextField,
  [AUTO_UUID_FIELD_TYPE]: UUIDField,
  [EMAIL_FIELD_TYPE]: TextField,
  [BOOLEAN_FIELD_TYPE]: Switch,
  [NUMBER_FIELD_TYPE]: NumberField,
  [PASSWORD_FIELD_TYPE]: PasswordField,
  [SINGLE_CHOICE_FIELD_TYPE]: SelectorFormAdapter,
  [MULTIPLE_CHOICE_FIELD_TYPE]: MultipleChoiceField,
  [MULTI_RELATION_FIELD_TYPE]: MultiRelationField,
  [SINGLE_RELATION_FIELD_TYPE]: SingleRelationField,
  [LANGUAGE_FIELD_TYPE]: LanguageField,
  [DATE_FIELD_TYPE]: DateField,
  [TIME_FIELD_TYPE]: DateField,
  [DATETIME_FIELD_TYPE]: DateField,
  [RICH_TEXT_FIELD_TYPE]: RichTextField,
  [ARRAY_FIELD_TYPE]: TableField,
  [OBJECT_FIELD_TYPE]: ObjectField,
  [JSON_FIELD_TYPE]: JSONField,
  [IMAGE_FIELD_TYPE]: ImageField,
  [URL_FIELD_TYPE]: TextField,
  [LINKED_FIELD_TYPE]: LinkedField,
  [TEXT_I18N_FIELD_TYPE]: props => I18nFieldAdapter(props, TextField),
  [RICH_TEXT_I18N_FIELD_TYPE]: props => I18nFieldAdapter(props, RichTextField),
  [IMAGE_I18N_FIELD_TYPE]: props => I18nFieldAdapter(props, ImageField),
};
