import React from "react";
import SwitchMUI from "@material-ui/core/Switch";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { YELLOW } from "../../styles/theme";
import Tooltip from "../tooltip/Tooltip";

const useStyles = makeStyles(theme => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    cursor: props => (props.disabled ? "default" : "pointer"),
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: YELLOW,
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: YELLOW,
      border: `6px solid ${theme.palette.divider}`,
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
  formControlDisplay: {
    display: "flex",
  },
  top: {
    flexDirection: "column",
    alignItems: "flex-start",
  },
  right: {
    flexDirection: "row-reverse",
    alignItems: "center",
  },
  bottom: {
    flexDirection: "column-reverse",
    alignItems: "flex-start",
  },
  left: {
    flexDirection: "row",
    alignItems: "center",
  },
}));

function Switch({
  id,
  input: { onChange, value },
  label,
  meta,
  required,
  disabled,
}) {
  const classes = useStyles({ disabled });
  const helperText = meta.touched ? meta.error : undefined;
  const hasError = meta.error && meta.touched;
  const tooltipText = `Set to ${!value}`;
  const labelPosition = meta.labelPosition || "top";

  return (
    <FormControl
      component="fieldset"
      className={`${classes.formControlDisplay} ${classes[labelPosition]}`}
    >
      <FormLabel focused={false} required={required} error={hasError}>
        {label}
      </FormLabel>
      <Tooltip placement="bottom-start" title={!disabled && tooltipText}>
        <SwitchMUI
          id={id}
          classes={{
            root: classes.root,
            switchBase: classes.switchBase,
            thumb: classes.thumb,
            track: classes.track,
            checked: classes.checked,
          }}
          disableRipple
          checked={Boolean(value)}
          onChange={(_event, isInputChecked) =>
            !disabled && onChange(isInputChecked)
          }
          value={value}
        />
      </Tooltip>
      <FormHelperText error={hasError}>{helperText}</FormHelperText>
    </FormControl>
  );
}

export default Switch;
