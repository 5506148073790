import React, { useCallback } from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MUIDialog from "@material-ui/core/Dialog";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CloseIcon from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import Divider from "@material-ui/core/Divider";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "../button/Button";
import { useDialog } from "../../../store/dialogContext";
import { RED } from "../../styles/theme";

const useStyles = makeStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing(2),
    minWidth: "200px"
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  infoLabel: {
    color: RED,
    margin: "0 8px 8px auto"
  },
  content: {
    padding: "4px",
    height: "100%",
    [theme.breakpoints.up("sm")]: {
      padding: "24px"
    }
  },
  title: {
    marginRight: "45px"
  },
  mobile: {
    [theme.breakpoints.down("sm")]: {
      margin: "0 16px",
      width: "100%"
    }
  }
}));

function Dialog() {
  const classes = useStyles();

  const { dialog, closeDialog } = useDialog();

  const {
    title,
    bodyProps,
    BodyComponent,
    open,
    onClose,
    Icon,
    buttonLabel,
    buttonTooltip,
    dialogAction,
    showInfoLabel,
    showIcon,
    color,
    infoLabel,
    fullWidth,
    isButtonDisabled
  } = dialog;

  const onclickClose = useCallback(() => {
    if (onClose) {
      onClose();
    }
    closeDialog();
  }, [closeDialog, onClose]);

  const performAction = useCallback(() => {
    dialogAction();
    closeDialog();
  }, [dialogAction, closeDialog]);

  return (
    <MUIDialog
      open={open}
      onClose={closeDialog}
      classes={{
        paper: classes.mobile
      }}
      fullWidth={fullWidth}
      maxWidth={"lg"}
      disableEscapeKeyDown={true}
    >
      <DialogTitle disableTypography className={classes.root}>
        <Typography className={classes.title} variant="h6" noWrap>
          {title}
        </Typography>
        <IconButton
          id={"close-dialog-button"}
          aria-label="Close"
          className={classes.closeButton}
          onClick={onclickClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        {BodyComponent && <BodyComponent {...bodyProps} />}
      </DialogContent>
      <Divider />
      {buttonLabel && dialogAction && (
        <DialogActions>
          <Button
            id={"action-dialog-button"}
            onClick={performAction}
            showIcon={showIcon}
            disabled={isButtonDisabled}
            label={buttonLabel}
            Icon={Icon}
            color={color}
            tooltip={buttonTooltip}
          />
        </DialogActions>
      )}
      {showInfoLabel && (
        <Typography className={classes.infoLabel}>{infoLabel}</Typography>
      )}
    </MUIDialog>
  );
}

export default Dialog;
