import React, { useState, useCallback, useEffect } from "react";
import { ThemeProvider as ThemeProviderMUI } from "@material-ui/core/styles";
import getTheme, { LIGHT_THEME, DARK_THEME } from "../shared/styles/theme";

const ThemeContext = React.createContext();

function useTheme() {
  const context = React.useContext(ThemeContext);
  if (!context) {
    throw new Error(
      "[COCO STATE ERROR] useTheme must be used within a ThemeProvider"
    );
  }
  return context;
}

function ThemeProvider(props) {
  const [themeMode, setThemeMode] = useState(
    localStorage.getItem("themeMode") || LIGHT_THEME
  );

  const changeTheme = useCallback(() => {
    const isLightModeActivated = themeMode === LIGHT_THEME;
    const newThemeMode = isLightModeActivated ? DARK_THEME : LIGHT_THEME;
    setThemeMode(newThemeMode);
    localStorage.setItem("themeMode", newThemeMode);
  }, [themeMode]);

  const theme = getTheme(themeMode);

  const isDarkModeEnabled = themeMode === DARK_THEME;

  const state = {
    themeMode,
    isDarkModeEnabled,
    changeTheme,
    theme,
    ...props.store
  };

  useEffect(() => {
    if (themeMode === DARK_THEME) {
      document.body.classList.add("dark-theme");
    } else {
      document.body.classList.remove("dark-theme");
    }
  }, [themeMode]);

  return (
    <ThemeContext.Provider value={state} {...props}>
      <ThemeProviderMUI theme={theme}>{props.children}</ThemeProviderMUI>
    </ThemeContext.Provider>
  );
}

export { ThemeProvider, useTheme };
