export const BACKEND_CONFIG_URL = process.env.REACT_APP_BACKEND_CONFIG_URL;
export const BACKEND_AUTH_URL = process.env.REACT_APP_BACKEND_AUTH_URL;
export const BACKEND_MEDIA_URL = process.env.REACT_APP_BACKEND_MEDIA_URL;
export const BACKEND_AUDITS_URL = process.env.REACT_APP_BACKEND_AUDITS_URL;
export const BACKEND_PUBLIC_URL = process.env.REACT_APP_BACKEND_PUBLIC_URL;

// auth
export const REGISTER_URL = "/api/auth/users";
export const USERS_URL = "/api/auth/users";
export const LOGIN_URL = "/api/auth/account/login";
export const FORGOT_PASSWORD_URL = "/api/auth/account/reset-password";
export const RESET_PASSWORD_URL = "/api/auth/account/password";
export const CHANGE_PASSWORD_URL = "/api/auth/users/password";
export const ACTIVATE_ACCOUNT_URL = "/api/auth/account/activate";
export const LOGOUT_URL = "/api/auth/account/sessions/logout";
export const REFRESH_TOKEN_URL = "/api/auth/account/refresh";

// modules
export const MODULE_URL = "/api/modules";

// apps
export const APPS_URL = "/api/applications";

// content
export const CONTENT_URL = "/api/contents";

// languages
export const LANGUAGES_URL = "/api/countries/languages";

// countries
export const COUNTRIES_URL = "/countries";

// platforms
export const PLATFORMS_URL = '/platforms';

// clone version
export const CLONE_VERSION_URL = "/api/versions/clone";

// export version
export const EXPORT_VERSION_URL = "/api/versions/export";

// remove version
export const VERSIONS_URL = "/api/versions";

// import version
export const IMPORT_VERSION_URL = "/api/versions/import";

// import version
export const MODULES_AVAILABLE_URL = "api/applications/modules/available";

// force upgrade
export const FORCE_UPGRADE_URL = "/forceupgrade";

// api OS verions
export const API_OS_VERSIONS_URL = '/api-min-os-version';

// media categories
export const MEDIA_CATEGORIES_URL = "/api/media/categories";

// release trains
export const RELEASE_TRAINS_URL = "/api/release_trains";

//public
export const PUBLIC_CONFIG_URL = "/api/public";
