import React from "react";
import PropTypes from "prop-types";
import TabsMUI from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import makeStyles from "@material-ui/core/styles/makeStyles";

function Tabs({ tabs }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (_event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <TabsMUI
        value={value}
        className={classes.tabs}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="on"
        indicatorColor="primary"
      >
        {tabs.map(tab => (
          <Tab
            id={`nav-tab-${tab.label}`}
            key={`tab-${tab.label}`}
            label={tab.label}
            icon={tab.icon}
            {...a11yProps(0)}
          />
        ))}
      </TabsMUI>
      {tabs.map((tab, index) => (
        <div
          key={`panel-${tab.label}`}
          id={`panel-${tab.label}`}
          role="tabpanel"
          style={{ display: index === value ? "inline" : "none" }}
        >
          {tab.panel}
        </div>
      ))}
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: "100%",
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: "6px",
  },
  tabs: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

function a11yProps(index) {
  return {
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

Tabs.propTypes = {
  tabs: PropTypes.array.isRequired,
};

export default Tabs;
