import React, { useCallback } from "react";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import { arrayMove } from "react-sortable-hoc";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import FormLabel from "@material-ui/core/FormLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { RED } from "../../styles/theme";
import SearchField from "../search-field/SearchField";
import SecondaryButton from "../secondary-button/SecondaryButton";
import ItemsSearchBar from "./components/ItemsSearchBar";
import AvailableItemsList from "./components/AvailableItemsList";
import SelectedItemsList from "./components/SelectedItemsList";
import useSearch from "../search-field/hooks/useSearch";

function MultipleChoiceField({
  id,
  input: { onChange, value },
  label,
  meta,
  required,
  field,
  disabled,
  isSortableContent,
  onDeselectAll,
  onSelectAll
}) {
  const helperText = meta.touched ? meta.error : undefined;
  const hasError = meta.error && meta.touched;

  const options = field.options || [];
  const selectedItems = options.filter(i => value?.includes(i.value));

  function selectItem(item) {
    onChange([...value, item.value]);
  }

  function deselectItem(item) {
    onChange(value.filter(i => i !== item.value));
  }

  function deselectAllItems() {
    onDeselectAll && onDeselectAll();
    onChange([]);
  }

  function selectAllItems() {
    onSelectAll && onSelectAll();
    onChange(options.map(item => item.value));
  }

  const {
    itemsByName: availableItemsFilteredByName,
    setSearchByName: setSearchAvailableItemsByName,
    searchByName: searchAvailableItemsByName
  } = useSearch(options, ["label", "subtitle", "extraSubtitles"]);

  const {
    itemsByName: selectedItemsFiltered,
    setSearchByName: setSearchSelectedItemsByName,
    searchByName: searchSelectedItemsByName
  } = useSearch(selectedItems, ["label", "subtitle", "extraSubtitles"]);

  const isAllItemsSelected = options.length === selectedItems.length;

  const classes = useStyles({ hasError });

  const sortMultiChoiceSelectedItems = useCallback(
    ({ newIndex, oldIndex }) => {
      onChange(arrayMove(value, oldIndex, newIndex));
    },
    [value, onChange]
  );

  return (
    <Grid container className={classes.container}>
      <Grid id={id} className={classes.leftGridItem} item xs={12} sm={6}>
        <div className={classes.header}>
          <FormLabel focused={false} required={required} error={hasError}>
            {label}
          </FormLabel>
        </div>
        <Divider className={classes.divider} />
        <List disablePadding className={classes.list}>
          <ItemsSearchBar
            id={id}
            disabled={disabled}
            isAllItemsSelected={isAllItemsSelected}
            selectAllItems={
              isAllItemsSelected ? deselectAllItems : selectAllItems
            }
            setSearchItemsByName={setSearchAvailableItemsByName}
            searchItemsByName={searchAvailableItemsByName}
          />
          <Divider className={classes.divider} />
          <AvailableItemsList
            id={id}
            items={availableItemsFilteredByName}
            selectedItems={selectedItems}
            selectItem={selectItem}
            deselectItem={deselectItem}
            disabled={disabled}
          />
        </List>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.rightGridItem}>
        <div className={classes.selectedItemsHeader}>
          <FormLabel
            className={classes.header}
            id={`${id}-selected-counter`}
            focused={false}
            error={hasError}
          >
            {`${selectedItems.length} selected`}
          </FormLabel>
          {selectedItems.length > 0 && (
            <div className={classes.clearAllBtnWrapper}>
              <SecondaryButton
                variant="outlined"
                tooltip={"Deselect all selected items"}
                id={`${id}-deselect-all-items`}
                onClick={deselectAllItems}
                disabled={disabled}
                aria-label="Clear all selected items"
                color="default"
                size={"small"}
                label={"Clear all"}
              />
            </div>
          )}
        </div>
        <Divider className={classes.divider} />
        <List disablePadding className={classes.list}>
          <ListItem className={classes.searchSelectedItem}>
            <ListItemText
              disableTypography
              primary={
                <SearchField
                  id={`${id}-search-selected-options`}
                  setFilterByName={setSearchSelectedItemsByName}
                  filterByName={searchSelectedItemsByName}
                  placeholder={`Search Selected Items`}
                  fullWidth
                />
              }
            />
          </ListItem>
          <Divider className={classes.divider} />
          <SelectedItemsList
            id={id}
            value={value}
            selectedItems={selectedItemsFiltered}
            deselectItem={deselectItem}
            disabled={disabled}
            isSortableContent={isSortableContent}
            sortMultiChoiceSelectedItems={sortMultiChoiceSelectedItems}
          />
        </List>
      </Grid>
      <FormHelperText error={hasError}>{helperText}</FormHelperText>
    </Grid>
  );
}

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: theme.palette.background.paper
  },
  leftGridItem: props => ({
    border: `thin solid ${props.hasError ? RED : theme.palette.divider}`,
    borderTopLeftRadius: theme.shape.borderRadius,
    borderBottomLeftRadius: theme.shape.borderRadius
  }),
  rightGridItem: props => ({
    border: `thin solid ${props.hasError ? RED : theme.palette.divider}`,
    borderTopRightRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
    [theme.breakpoints.up("sm")]: {
      borderLeft: 0
    }
  }),
  divider: props => ({
    backgroundColor: props.hasError ? RED : theme.palette.divider
  }),
  header: {
    padding: "14px 8px 14px 12px"
  },

  selectedItemsHeader: {
    padding: "2px",
    display: "flex",
    position: "relative",
    alignItems: "center"
  },
  clearAllBtnWrapper: {
    margin: "0 0 0 auto"
  },
  list: {
    width: "100%"
  },
  searchSelectedItem: {
    height: 58
  }
}));

MultipleChoiceField.defaultProps = {
  isSortableContent: true,
  meta: {}
};

export default MultipleChoiceField;
