import React from "react";
import ListItem from "@material-ui/core/ListItem";
import { sortableContainer, sortableElement } from "react-sortable-hoc";
import MUITooltip from "@material-ui/core/Tooltip";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import makeStyles from "@material-ui/core/styles/makeStyles";
import InfiniteScrollContainer from "../../infinite-scroll-container/InfiniteScrollContainer";
import ListItemMultipleSubtitles from "./ListItemMultipleSubtitles";

function SelectedItemsList({
  id,
  selectedItems,
  deselectItem,
  disabled,
  isSortableContent,
  sortMultiChoiceSelectedItems,
  value,
}) {
  const classes = useStyles({ isSortableContent });

  const EXTRA_HEIGHT = 90;
  const NORMAL_HEIGHT = 70;

  const heightPerItem = selectedItems?.some(
    item => item.extraSubtitles && item.extraSubtitles.length > 0)
    ? EXTRA_HEIGHT
    : NORMAL_HEIGHT;

  const itemRenderFunction = (itemValue, index) => {
    const item = selectedItems.find((item) => item.value === itemValue);
    return (
      item && (
        <SortableItem
          key={`${id}-item-selected-${item.value}`}
          index={index}
          disabled={!isSortableContent}
        >
          <MUITooltip
            disableFocusListener
            disableTouchListener
            title={
              isSortableContent ? "Order item via drag and drop" : ""
            }
          >
            <ListItem
              button={!disabled}
              id={`${id}-item-selected-${item.value}`}
              className={classes.item}
            >
              <ListItemText
                primary={item.label}
                classes={{
                  root: classes.itemText,
                }}
                primaryTypographyProps={{
                  noWrap: true,
                  className: classes.itemText,
                }}
                secondary={<ListItemMultipleSubtitles mainSubtitle={item.subtitle} extras={item.extraSubtitles} />}
                disableTypography={true}
              />
              <ListItemSecondaryAction>
                <IconButton
                  id={`${id}-deselect-item-${item.value}`}
                  aria-label="Close"
                  color="inherit"
                  disabled={disabled}
                  onClick={() => deselectItem(item)}
                >
                  <CloseIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          </MUITooltip>
        </SortableItem>
      )
    );
  };

  return (
    <SortableContainer
      onSortEnd={sortMultiChoiceSelectedItems}
      distance={20}
      lockAxis={"y"}
      style={{ height: 288 }}
    >
      <div>
        <InfiniteScrollContainer
          items={value || []}
          initialItems={10}
          heightPerItem={heightPerItem}
          maxHeight={288}
          component={itemRenderFunction}
        />
      </div>
    </SortableContainer>
  );
}

const useStyles = makeStyles(_theme => ({
  item: props => ({
    cursor: props.isSortableContent ? "row-resize" : "inherit",
  }),
  itemText: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
}));

const SortableItem = sortableElement(({ children }) =>
  (<React.Fragment>{children}</React.Fragment>));

const SortableContainer = sortableContainer(({ children }) =>
  (<React.Fragment>{children}</React.Fragment>));

export default SelectedItemsList;
