import React from "react";
import SnackbarMUI from "@material-ui/core/Snackbar";
import green from "@material-ui/core/colors/green";
import amber from "@material-ui/core/colors/amber";
import MySnackbarContentWrapper from "./components/MySnackbarContentWrapper";
import { BLUE } from "../../styles/theme";
import { SNACKBAR_DURATION, ERROR_SNACKBAR_DURATION_MULTIPLIER } from "../../constants/config";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useCustomSnackbar from "./hooks/useCustomSnackbar";

const useStyles = makeStyles(theme => ({
  margin: {
    margin: "20px",
  },
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: BLUE,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    fontSize: 20,
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: "flex",
    alignItems: "center",
  },
}));

function Snackbar() {
  const classes = useStyles();

  const {
    open,
    variant,
    message,
    onClose,
    anchorOrigin,
    closeSnackbar,
    openShowMore,
    errorDetails,
  } = useCustomSnackbar();

  const autoHideDuration = errorDetails
    ? SNACKBAR_DURATION * ERROR_SNACKBAR_DURATION_MULTIPLIER
    : SNACKBAR_DURATION;

  return (
    <SnackbarMUI
      anchorOrigin={anchorOrigin}
      className={classes.margin}
      open={open}
      autoHideDuration={autoHideDuration}
      onExited={closeSnackbar}
      onClose={onClose}
    >
      <MySnackbarContentWrapper
        onClose={closeSnackbar}
        showMore={openShowMore}
        variant={variant}
        message={message}
        additionalDetails={errorDetails}
        classes={classes}
      />
    </SnackbarMUI>
  );
}

export default Snackbar;
