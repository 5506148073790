import isNumber from "lodash/isNumber";
import validateLength from "./validateLength";

const invalidValue = "Invalid Value";

export default function validateNumber(value, params) {
  if (!value) {
    return null;
  }

  const isNumberValue = isNumber(value);

  if (!isNumberValue) {
    return invalidValue;
  }

  if (params) {
    const { min, max } = params;

    const errorLength = validateLength(value, min, max);

    if (errorLength) {
      return errorLength;
    }
  }

  return null;
}
