import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import AddPhotoAlternateRoundedIcon from "@material-ui/icons/AddPhotoAlternateRounded";
import Tooltip from "../tooltip/Tooltip";

function MediaCard({ id, name, urls, onClickImage, fullWidth, raised }) {
  const classes = useStyles({ fullWidth });
  const hasValue = !!urls.thumbnail;
  const tooltip = hasValue ? name : "choose an image";
  return (
    <Tooltip title={tooltip}>
      <Card className={classes.card} raised={raised}>
        <CardActionArea onClick={onClickImage} id={`${id}-action-area`}>
          {hasValue ? (
            <CardMedia
              component="img"
              alt={name}
              height="240"
              src={urls.thumbnail}
              title={name}
            />
          ) : (
            <AddPhotoAlternateRoundedIcon className={classes.noImage} />
          )}
        </CardActionArea>
      </Card>
    </Tooltip>
  );
}

const useStyles = makeStyles(_theme => ({
  card: {
    textAlign: "center",
    width: props => (props.fullWidth ? "100%" : 240),
  },
  noImage: {
    height: 240,
    width: 150,
    opacity: 0.3,
  },
}));

export default MediaCard;
