import get from "lodash/get";
import Api from "../Api";
import { refreshToken } from "../auth";
import { HttpError, NETWORK_CLIENT_ERROR } from "../HttpError";
import history, { ERROR_PATHNAME, LOGIN_PATHNAME, UNAVAILABLE_PATHNAME } from "../../routes";
import { redirect } from "../../shared/utils/utils";
import { REFRESH_TOKEN_URL } from "../constants/endpoints";

const MAX_RETRY_COUNT = 1;

function retryCall(error) {
  const { config } = error;

  config.__retryCount = config.__retryCount || 0;
  if (config.__retryCount >= MAX_RETRY_COUNT) {
    return Promise.reject(error);
  }
  config.__retryCount += 1;

  return Api.axiosInstance({
    ...config,
    url: config.url
  });
}

function redirectToLogin() {
  Api.refreshTokenAlreadyCalled = false;
  Api.accessToken = null;
  Api.refreshToken = null;
  redirect(LOGIN_PATHNAME);
}

function responseInterceptorSuccess(response) {
  return get(response, "data", response);
}

async function blobToJSON(blob) {
  return JSON.parse(await blob.text());
}

async function responseInterceptorError(response) {
  const isNetworkError = !!NETWORK_CLIENT_ERROR[response.message];

  // Network error in the client side
  if (isNetworkError) {
    return Promise.reject(
      new HttpError(NETWORK_CLIENT_ERROR[response.message])
    );
  }

  const isResponseDataBlob = response.response.data instanceof Blob;
  const data = isResponseDataBlob
    ? {
        ...response.response,
        data: await blobToJSON(response.response.data)
      }
    : response.response;

  const error = new HttpError(data);

  //we check if is an error 500
  const isServerError = error.serverError();

  if (isServerError) {
    history.push({
      pathname: ERROR_PATHNAME,
      state: {
        title: data.data.error,
        subtitle: data.data.message,
        statusCode: data.data.statusCode
      }
    });
  }

  //we check if is an error 503
  const isUnavailableError = error.isUnavailable();
  if (isUnavailableError) {
    history.push({
      pathname: UNAVAILABLE_PATHNAME,
      state: {
        title: data.data.error,
        subtitle: data.data.message,
        statusCode: data.data.statusCode
      }
    });
  }

  // we check if current access token is expired
  const isAccessTokenExpired = error.isAccessTokenExpired();

  if (isAccessTokenExpired) {
    const isRefreshTokenDefined = !!Api.refreshToken;

    // if no refresh token is present, we redirect the user to login page
    if (!isRefreshTokenDefined) {
      redirectToLogin();
      return Promise.reject(error);
    }

    // To avoid multiple calls to refresh we check if it was already called
    const hasToPerformRefresh = !Api.refreshTokenAlreadyCalled;

    if (hasToPerformRefresh) {
      Api.refreshTokenAlreadyCalled = true;
      Api.refreshTokenRequest = refreshToken(Api.refreshToken);
    }

    // when refresh token is completed we retry the original request
    return Api.refreshTokenRequest
      .then(() => {
        Api.refreshTokenAlreadyCalled = false;
        return retryCall(response);
      })
      .catch(response => {
        // if the refresh token call fails, we redirect the user to login page
        const url = get(response, "config.url", "");
        const isRefreshTokenRequest = url.indexOf(REFRESH_TOKEN_URL) !== -1;
        if (isRefreshTokenRequest) {
          redirectToLogin();
        }
        return Promise.reject(response);
      });
  }

  // if backend send us a message we show a snackbar
  const showBackendError = error.showBackendError();

  if (showBackendError) {
    const additionalDetails = error.showBackendErrorAdditionalDetails();
    Api.showBackendError(showBackendError, additionalDetails);
  }

  return Promise.reject(error);
}

export { responseInterceptorSuccess, responseInterceptorError };
