import axios from "axios";
import {
  requestInterceptorSuccess,
  requestInterceptorError
} from "./interceptors/requestInterceptor";
import {
  responseInterceptorError,
  responseInterceptorSuccess
} from "./interceptors/responseInterceptor";
import { NETWORK_TIMEOUT } from "../shared/constants/config";

const RETRY_ATTEMPTS = 1;

class Api {
  constructor() {
    this.axiosInstance = axios.create({
      retry: RETRY_ATTEMPTS,
      timeout: NETWORK_TIMEOUT,
      headers: {
        common: {
          "Content-Type": "application/json"
        }
      }
    });

    this.axiosInstance.interceptors.request.use(
      requestInterceptorSuccess.bind(this),
      requestInterceptorError.bind(this),
    );

    this.axiosInstance.interceptors.response.use(
      responseInterceptorSuccess.bind(this),
      responseInterceptorError.bind(this),
    );

    this.refreshToken = sessionStorage.getItem("refreshToken");
    this.accessToken = sessionStorage.getItem("accessToken");

    this.refreshTokenCalled = false;
    this.retryCallsQueue = [];
    this.showBackendError = () => { };
    this.showBackendErrorDialog = () => { };
  }

  get(url, config) {
    return this.axiosInstance.get(url, config);
  }

  delete(url, config) {
    return this.axiosInstance.delete(url, config);
  }

  post(url, data, config) {
    return this.axiosInstance.post(url, data, config);
  }

  put(url, data, config) {
    return this.axiosInstance.put(url, data, config);
  }

  patch(url, data, config) {
    return this.axiosInstance.patch(url, data, config);
  }
}

export default new Api();
