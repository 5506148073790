import React, { useState, useEffect, useCallback } from "react";
import get from "lodash/get";
import { getCMSConfiguration } from "../http/public";
import { useConfiguration } from "./configurationContext";
import {
  CONFIG_PUBLIC_ENDPOINT,
  STATIC_CONFIG_PUBLIC_ENDPOINT,
  FAQ_PUBLIC_ENDPOINT,
  TCPP_PUBLIC_ENDPOINT,
} from "../shared/constants/config";
import { createConsoleError } from "../shared/utils/utils";

const CmsConfigurationContext = React.createContext();

function useCmsConfiguration() {
  const context = React.useContext(CmsConfigurationContext);
  if (!context) {
    throw new Error(
      "[COCO STATE ERROR] useCmsConfiguration must be used within a CmsConfigurationContext"
    );
  }
  return context;
}

function CmsConfigurationProvider(props) {
  const [cmsConfiguration, setCmsConfiguration] = useState({});
  const [cmsChangelog, setCmsChangelog] = useState([]);

  const { application } = useConfiguration();

  const performGetCMSConfiguration = useCallback(async () => {
    try {
      const response = await getCMSConfiguration();

      setCmsConfiguration(publicEndpointMapper(response.contents));
      const changelogItems = response.contents.filter(
        ({ module }) => module === "changelog"
      );
      const orderedChangelogItems = changelogItems.sort(
        (a, b) =>
          new Date(b.published.createdAt) - new Date(a.published.createdAt)
      );
      setCmsChangelog(orderedChangelogItems);
    } catch (error) {
      createConsoleError("Error", error);
    }
  }, []);

  useEffect(() => {
    performGetCMSConfiguration();
  }, [performGetCMSConfiguration]);

  const publicEndpoints = getPublicEndpoints(application, cmsConfiguration);

  const isConfigEndpointDefined = publicEndpoints.includes(
    CONFIG_PUBLIC_ENDPOINT
  );

  const isStaticConfigEndpointDefined = publicEndpoints.includes(
    STATIC_CONFIG_PUBLIC_ENDPOINT
  );

  const isFAQEndpointDefined = publicEndpoints.includes(FAQ_PUBLIC_ENDPOINT);

  const isTCPPEndpointDefined = publicEndpoints.includes(TCPP_PUBLIC_ENDPOINT);

  const attachCountryModules = getAttachCountryModules(
    application,
    cmsConfiguration
  );

  const addVersionDefaultModules = getAddVersionDefaultModules(
    application,
    cmsConfiguration
  );

  const state = {
    ...props.store,

    cmsConfiguration,
    performGetCMSConfiguration,

    isConfigEndpointDefined,
    isStaticConfigEndpointDefined,
    isFAQEndpointDefined,
    isTCPPEndpointDefined,

    attachCountryModules,
    addVersionDefaultModules,

    cmsChangelog,
  };

  return <CmsConfigurationContext.Provider value={state} {...props} />;
}

export { CmsConfigurationProvider, useCmsConfiguration };

function publicEndpointMapper(contents) {
  return contents.reduce((configuration, content) => {
    const module = content.module;

    return {
      [module]: content.published,
      ...configuration,
    };
  }, {});
}

function getPublicEndpoints(application, cmsConfiguration) {
  const moduleName = "public_endpoints";
  const publicEndpoints = get(cmsConfiguration, moduleName, {});
  return Object.keys(publicEndpoints).reduce(
    (definedPublicEndpoints, publicEndpoint) => {
      const applications = publicEndpoints[publicEndpoint];
      const isEndpointDefined = applications.includes(application);
      if (isEndpointDefined) {
        return [...definedPublicEndpoints, publicEndpoint];
      }
      return definedPublicEndpoints;
    },
    []
  );
}

function getAddVersionDefaultModules(application, cmsConfiguration) {
  const applicationConfiguration = `attach_preferences_${application}`;
  const moduleName = "add_version_default_modules";

  return get(cmsConfiguration, `${applicationConfiguration}.${moduleName}`, []);
}

function getAttachCountryModules(application, cmsConfiguration) {
  const applicationConfiguration = `attach_preferences_${application}`;
  const moduleName = "attach_country_default_modules";

  return get(cmsConfiguration, `${applicationConfiguration}.${moduleName}`, []);
}
