import React from "react";
import get from "lodash/get";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import makeStyles from "@material-ui/core/styles/makeStyles";
import MediaCard from "../media-card/MediaCard";
import { useDialog } from "../../../store/dialogContext";
import DialogBody from "./components/DialogBody";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import Tooltip from "../tooltip/Tooltip";
import { useSnackbar } from "../../../store/snackbarContext";
import { COPY_TO_CLIPBOARD_SUCCESS } from "../snackbar/snackbars";

function ImageField({
  id,
  input: { onChange, value },
  label,
  meta,
  scope,
  required,
  disabled,
}) {
  const classes = useStyles();

  const hasError = meta.error && meta.touched;
  const helperText = meta.touched ? meta.error : undefined;

  const { openDialog, closeDialog } = useDialog();

  const { openSnackbar } = useSnackbar();

  const { application } = scope;

  function selectImage(image, category) {
    const urls = get(image, "urls[0]", {});
    onChange({
      _id: image._id,
      slug: image.slug,
      category: category.slug,
      thumbnail: urls.thumbnail,
      lo: urls.lo,
      hi: urls.hi,
      raw: urls.raw,
    });
    closeDialog();
  }

  function handleCopyUrlToClipboard(id) {
    const copyText = document.getElementById(id);
    navigator.clipboard.writeText(copyText.value);
    openSnackbar(COPY_TO_CLIPBOARD_SUCCESS);
  }

  const mediaGalleryDialog = {
    title: "Select Image",
    fullWidth: true,
    BodyComponent: DialogBody,
    bodyProps: {
      application,
      selectImage,
      imageSelected: value,
    },
  };

  const noUrlProvided = "No url provided";

  const thumbnailInputId = `${id}-input-thumbnail-url`;
  const lowInputId = `${id}-input-low-url`;
  const highInputId = `${id}-input-high-url`;
  const rawInputId = `${id}-input-raw-url`;

  const hasValue = !!value.slug;

  return (
    <FormControl id={id} component="fieldset" className={classes.formControl}>
      <FormLabel
        focused={false}
        required={required}
        error={hasError}
        className={classes.label}
      >
        {label}
      </FormLabel>
      <div className={classes.cardContainer}>
        {hasValue && (
          <IconButton
            id={`${id}-deselect-image`}
            className={classes.deselectImageButton}
            aria-label="deselect image"
            color="inherit"
            onClick={() => onChange()}
          >
            <CloseRoundedIcon />
          </IconButton>
        )}
        <MediaCard
          slug={value.slug}
          name={value.name}
          onClickImage={() => !disabled && openDialog(mediaGalleryDialog)}
          categoryId={value.categoryId}
          urls={{
            thumbnail: value.thumbnail,
            lo: value.lo,
            hi: value.hi,
            raw: value.raw,
          }}
        />
        {hasValue && (
          <div className={classes.urlsTextContainer}>
            <TextField
              className={classes.urlTextField}
              id={thumbnailInputId}
              InputLabelProps={{
                shrink: true,
              }}
              value={value.thumbnail || noUrlProvided}
              fullWidth
              label={"Thumbnail resolution"}
              InputProps={{
                endAdornment: value.thumbnail && (
                  <InputAdornment position="end">
                    <Tooltip title={"copy thumbnail url to clipboard"}>
                      <IconButton
                        aria-label="copy thumbnail url to clipboard"
                        onClick={() =>
                          handleCopyUrlToClipboard(thumbnailInputId)
                        }
                      >
                        <FileCopyIcon />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              className={classes.urlTextField}
              id={lowInputId}
              InputLabelProps={{
                shrink: true,
              }}
              value={value.lo || noUrlProvided}
              fullWidth
              label={"Low resolution"}
              InputProps={{
                endAdornment: value.lo && (
                  <InputAdornment position="end">
                    <Tooltip title={"copy low url to clipboard"}>
                      <IconButton
                        aria-label="copy low url to clipboard"
                        onClick={() => handleCopyUrlToClipboard(lowInputId)}
                      >
                        <FileCopyIcon />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              className={classes.urlTextField}
              id={highInputId}
              InputLabelProps={{
                shrink: true,
              }}
              value={value.hi || noUrlProvided}
              fullWidth
              label={"High resolution"}
              InputProps={{
                endAdornment: value.hi && (
                  <InputAdornment position="end">
                    <Tooltip title={"copy high url to clipboard"}>
                      <IconButton
                        aria-label="copy high url to clipboard"
                        onClick={() => handleCopyUrlToClipboard(highInputId)}
                      >
                        <FileCopyIcon />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              className={classes.urlTextField}
              id={rawInputId}
              InputLabelProps={{
                shrink: true,
              }}
              value={value.raw || noUrlProvided}
              fullWidth
              label={"Raw resolution"}
              InputProps={{
                endAdornment: value.raw && (
                  <InputAdornment position="end">
                    <Tooltip title={"copy raw url to clipboard"}>
                      <IconButton
                        aria-label="copy raw url to clipboard"
                        onClick={() => handleCopyUrlToClipboard(rawInputId)}
                      >
                        <FileCopyIcon />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        )}
      </div>
      <FormHelperText error={hasError}>{helperText}</FormHelperText>
    </FormControl>
  );
}

const useStyles = makeStyles(theme => ({
  formControl: {
    width: "100%",
    textAlign: "start",
  },
  label: {
    textTransform: "capitalize",
    margin: "24px 0 8px 0",
  },
  cardContainer: {
    display: "flex",
  },
  urlsTextContainer: {
    width: "calc(100% - 240px)",
    paddingLeft: "12px",
  },
  urlTextField: {
    marginTop: "10px",
  },
  deselectImageButton: {
    position: "absolute",
    left: 190,
    top: 50,
    zIndex: theme.zIndex.mobileStepper,
  },
}));

export default ImageField;
