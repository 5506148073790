import React, { useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import DeleteIcon from "@material-ui/icons/Delete";
import Typography from "@material-ui/core/Typography";
import includes from "lodash/includes";
import Divider from "@material-ui/core/Divider";
import Checkbox from "@material-ui/core/Checkbox";
import InfiniteScrollContainer from "../infinite-scroll-container/InfiniteScrollContainer";
import useDimensions from "../../hooks/useDimensions";
import MediaCard from "../media-card/MediaCard";
import SearchField from "../search-field/SearchField";
import { stringToId } from "../../utils/utils";
import SecondaryButton from "../secondary-button/SecondaryButton";
import { useAuthorization } from "../../../store/authorizationContext";
import { DELETE_ASSET_ACTION } from "../../../roles/roles";
import SelectAllItems from "./components/SelectAllItems";

function MediaGallery({
  assets,
  maxHeight,
  onClickImage,
  selectedImages,
  onSelectImage,
  openMultipleAssetDialog,
  deselectAllItems,
  selectAllItems,
}) {
  const classes = useStyles({ maxHeight });

  const [filterByName, setFilterByName] = useState("");

  const [ref, dimensions] = useDimensions();

  const assetsFiltered = assets.filter(asset =>
    includes(asset.name.toLowerCase(), filterByName.toLowerCase())
  );

  const hasAssets = assetsFiltered.length > 0;
  const hasAssetsSelected = selectedImages.length > 0;

  const areAllImagesSelected = assetsFiltered.length === selectedImages.length;

  const showSelectImageButton = !!onSelectImage;
  const showSelectedLabel = !!onSelectImage && hasAssetsSelected;

  const { isUserAllowedTo } = useAuthorization();
  const canUserDeleteAsset = isUserAllowedTo(DELETE_ASSET_ACTION);

  const itemRenderComponent = asset => {
    const assetId = asset._id || asset.id;
    const isAssetSelected = !!selectedImages.find(
      a => a.id === assetId
    );

    return (
      <div key={assetId} className={classes.assetContainer}>
        {canUserDeleteAsset && showSelectImageButton && (
          <Checkbox
            id={stringToId(`${assetId}-select-image`)}
            checked={isAssetSelected}
            color="primary"
            className={classes.selectImageButton}
            onChange={() => onSelectImage(asset)}
          />
        )}
        <MediaCard
          id={stringToId(`${assetId}-media-card`)}
          onClickImage={() => onClickImage(asset)}
          slug={asset.slug}
          name={asset.name}
          raised={isAssetSelected}
          fullWidth
          markets={asset.markets}
          urls={asset.urls[0]}
        />
      </div>
    );
  };

  return (
    <React.Fragment>
      <div className={classes.filterBar}>
        <div className={classes.searchContainer}>
          <SearchField
            id={"search-category"}
            placeholder={"Search image..."}
            filterByName={filterByName}
            setFilterByName={setFilterByName}
          />
        </div>
        {showSelectedLabel && (
          <div className={classes.selectedActionsContainer}>
            <Typography
              color="inherit"
              variant="subtitle1"
              className={classes.selectedLabel}
            >{`${selectedImages.length} images selected`}</Typography>
            <SecondaryButton
              variant="outlined"
              tooltip={"Delete selected assets"}
              id={"delete-multiple-assets-button"}
              size={"small"}
              onClick={openMultipleAssetDialog}
              aria-label="Delete selected assets"
              Icon={DeleteIcon}
              color="default"
              showIcon
              label={"Delete"}
            />
          </div>
        )}
      </div>
      <Divider />
      <div ref={ref} className={classes.content}>
        {hasAssets && (
          <>
            <SelectAllItems
              disabled={false}
              areAllItemsSelected={areAllImagesSelected}
              selectAllItems={
                areAllImagesSelected ? deselectAllItems : selectAllItems
              }
            />
            <InfiniteScrollContainer
              items={assetsFiltered}
              initialItems={getInitialItems(dimensions.width, maxHeight)}
              heightPerItem={272}
              maxHeight={maxHeight - 58}
              isFlexContainer
              component={itemRenderComponent}
            />
          </>
        )}
        {!hasAssets && (
          <Typography className={classes.noItemsLabel}>
            {"No assets to display..."}
          </Typography>
        )}
      </div>
    </React.Fragment>
  );
}

const useStyles = makeStyles(theme => ({
  filterBar: {
    padding: "8px 16px",
    height: "56px",
    display: "flex",
    justifyContent: "space-between",
  },
  selectedActionsContainer: {
    display: "flex",
  },
  searchContainer: {
    maxWidth: "300px",
    paddingTop: "2px",
  },
  selectedLabel: {
    paddingTop: "6px",
    paddingRight: "6px",
  },
  content: props => ({
    height: props.maxHeight,
  }),
  noItemsLabel: {
    padding: "8px 16px",
  },
  assetContainer: {
    position: "relative",
  },
  selectImageButton: {
    position: "absolute",
    zIndex: theme.zIndex.mobileStepper,
  },
}));

function getInitialItems(width, height) {
  const columns = Math.floor(width / 240);
  const rows = Math.ceil(height / 240);

  return (rows + 1) * columns;
}

export default MediaGallery;
