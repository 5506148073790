import React, { useCallback } from "react";
import get from "lodash/get";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import { TEXT_FIELD_TYPE } from "../form-builder/constants/fields";

function NumberField({
  input: { name, onChange, value },
  id,
  meta,
  label,
  required,
  disabled,
  fullWidth,
}) {
  const onChangeNumber = useCallback(
    event => {
      const MAX_LENGTH = 16;
      const DECIMAL_NUMBER_PARTS = 2;
      const value = get(event, "target.value", "");
      const hasValue = !!value;
      const isValidLength = value.length < MAX_LENGTH;
      const isValidNegativeNumber = value === "-";
      const isScientificNotationValue = value.includes("e");
      const isValidNumber = !isNaN(Number(value)) && isValidLength;
      const isValidDecimalNumber =
        value[value.length - 1] === "." &&
        value.split(".").length === DECIMAL_NUMBER_PARTS &&
        isValidLength;

      if (isScientificNotationValue) {
        return;
      }

      if (!hasValue) {
        onChange(undefined);
        return;
      }

      if (isValidDecimalNumber || isValidNegativeNumber) {
        onChange(value);
        return;
      }

      if (isValidNumber) {
        onChange(Number(value));
      }
    },
    [onChange]
  );
  return (
    <TextField
      name={name}
      required={required}
      id={id}
      label={label}
      type={TEXT_FIELD_TYPE}
      value={value}
      onChange={onChangeNumber}
      disabled={disabled}
      helperText={meta.touched ? meta.error : undefined}
      error={meta.error && meta.touched}
      fullWidth={fullWidth}
    />
  );
}

NumberField.propTypes = {
  input: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  meta: PropTypes.object,
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  required: PropTypes.bool,
  fullWidth: PropTypes.bool,
};

export default NumberField;
