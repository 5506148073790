import React, { useState, useEffect, useCallback, useMemo } from "react";
import { ERROR_VARIANT_TYPE } from "../shared/components/snackbar/snackbars";
import Api from "../http/Api";

const SnackbarContext = React.createContext();

function useSnackbar() {
  const context = React.useContext(SnackbarContext);
  if (!context) {
    throw new Error(
      "[COCO STATE ERROR] useSnackbar must be used within a SnackbarProvider"
    );
  }
  return context;
}

function SnackbarProvider(props) {
  const [open, setOpen] = useState(false);

  const [snackbar, setSnackbar] = useState({});

  const [isBackendSnackbar, setIsBackendSnackbar] = useState(false);

  const [errorDetails, setDetails] = useState(null);

  const openSnackbar = useCallback(snackbar => {
    if (isBackendSnackbar) {
      setIsBackendSnackbar(false);
    }
    setSnackbar(snackbar);
    setOpen(true);
  }, [isBackendSnackbar]);

  const closeSnackbar = useCallback(() => {
    setOpen(false);
    setIsBackendSnackbar(false);
    setDetails(null);
  }, []);

  useEffect(() => {
    Api.showBackendError = (error, additionalDetails = null) => {
      setDetails(additionalDetails);
      openSnackbar(
        {
          message: error,
          variant: ERROR_VARIANT_TYPE,
          additionalDetails,
        },
        additionalDetails
      );
      setIsBackendSnackbar(true);
    };
  }, [openSnackbar]);

  const openShowMore = useCallback(() => {
    closeSnackbar();
    Api.showBackendErrorDialog(errorDetails);
  }, [errorDetails, closeSnackbar]);

  const initState = {
    snackbar,
    open,
    isBackendSnackbar,
    openSnackbar,
    closeSnackbar,
    openShowMore,
    errorDetails,

    ...props.store,
  };

  const state = useMemo(() => ({...initState }), [initState]);

  return <SnackbarContext.Provider value={state} {...props} />;
}

export { SnackbarProvider, useSnackbar };
