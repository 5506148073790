import React, { useEffect } from "react";
import get from "lodash/get";
import find from "lodash/find";
import uniqWith from "lodash/uniqWith";
import isEqual from "lodash/isEqual";
import includes from "lodash/includes";
import { getMultiRelationFieldOptions } from "../multi-relation-field/MultiRelationField";
import Selector from "../selector/Selector";
import {
  LINKED_FIELD_TYPE,
  MULTI_RELATION_FIELD_TYPE,
  SINGLE_RELATION_FIELD_TYPE
} from "../form-builder/constants/fields";
import isString from "lodash/isString";
import { getSingleRelationFieldOptions } from "../single-relation-field/SingleRelationField";

function LinkedField({
  input: { name, onChange, value, ...restInput },
  meta,
  validators,
  relatedContents,
  form,
  fields,
  ...rest
}) {
  useEffect(() => {
    const options = getOptions(validators, fields, form, relatedContents);
    const initialValues = form.getState().initialValues;
    const targetField = getTargetLinkedField(validators, fields);
    const isFormInitialized = !!initialValues[targetField.slug];
    if (isFormInitialized && !!value) {
      const isValidValue = !!options.find(option => option.value === value);
      if (!isValidValue) {
        onChange("");
      }
    }
  }, [value, onChange, form, validators, fields, relatedContents]);

  return (
    <Selector
      {...rest}
      options={getOptions(validators, fields, form, relatedContents)}
      name={name}
      helperText={meta.touched ? meta.error : undefined}
      error={meta.error && meta.touched}
      inputProps={restInput}
      onChange={value => onChange(get(value, "value", undefined))}
      value={value}
    />
  );
}

function getOptions(validators, fields, form, relatedContents) {
  const targetField = getTargetLinkedField(validators, fields);
  const formValues = form.getState().values;
  const targetFieldValues = get(formValues, targetField.slug, []);

  const targetOptions = getTargetLinkedFieldOptions(
    targetField,
    relatedContents
  );

  const selectedTargetOptions = targetOptions
    .filter(option =>
      includes(targetFieldValues, isString(option) ? option : option.value)
    )
    .map(option =>
      isString(option)
        ? { label: option, value: option }
        : { label: option.label, value: option.value }
    );

  const defaultOptions = getDefaultOptions(validators);

  return uniqWith([...defaultOptions, ...selectedTargetOptions], isEqual);
}

export function getTargetLinkedField(validators, fields) {
  const validator = getValidatorLinkedField(validators);
  const targetFieldSlug = get(
    validator,
    "params.target",
    "origin_module.origin_field"
  ).split(".")[1];

  return fields.find(field => field.slug === targetFieldSlug);
}

function getDefaultOptions(validators) {
  const validator = getValidatorLinkedField(validators);
  return get(validator, "params.defaultOptions", []);
}

function getTargetLinkedFieldOptions(targetField, relatedContents) {
  const targetFieldType = targetField.type;

  const isMultiRelationField = targetFieldType === MULTI_RELATION_FIELD_TYPE;

  const isSingleRelationField = targetFieldType === SINGLE_RELATION_FIELD_TYPE;

  if (isMultiRelationField) {
    return getMultiRelationFieldOptions(
      relatedContents,
      targetField.validators,
      targetField.presentation
    );
  }

  if (isSingleRelationField) {
    return getSingleRelationFieldOptions(
      relatedContents,
      targetField.validators
    );
  }

  return targetField.options;
}

function getValidatorLinkedField(validators) {
  return find(validators, validator => validator.name === LINKED_FIELD_TYPE);
}

export default LinkedField;
