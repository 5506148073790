import { responsiveFontSizes, createMuiTheme } from "@material-ui/core";
import get from "lodash/get";

export const LIGHT_YELLOW = "#ffe980";
export const YELLOW = "#face0a";
export const VERY_DARK_YELLOW = "#b89c12";

export const LIGHT_RED = "#fbcbd1";
export const RED = "#dd1d21";
export const DARK_RED = "#a51619";

export const LIGHT_WHITE = "#fafafa";
export const WHITE = "#fff";
export const DARK_WHITE = "#eaeaea";

export const LIGHT_GREY = "#dbdbdb";
export const GREY = "#b7b7b7";
export const DARK_GREY = "#707070";

export const LIGHT_GREEN = "#b9e2b9";
export const GREEN = "#44a047";
export const DARK_GREEN = "#2f7031";

export const ORANGE = "#efae40";
export const DARK_ORANGE = "#c19243";

export const BLUE = "#1976d2";

export const BLACK = "#000000de";

export const LIGHT_THEME = "light";
export const DARK_THEME = "dark";

export const PRIMARY_COLOR = YELLOW;
export const SECONDARY_COLOR = RED;

export default function getTheme(themeMode = LIGHT_THEME) {
  return responsiveFontSizes(
    createMuiTheme({
      breakpoints: {
        keys: ["xs", "sm", "md", "lg", "xl"],
        values: {
          xs: 0,
          sm: 768,
          md: 992,
          lg: 1200,
          xl: 1920,
        },
      },
      palette: {
        type: themeMode,
        primary: {
          main: PRIMARY_COLOR,
        },
        secondary: {
          main: SECONDARY_COLOR,
        },
      },
      overrides: {
        MuiFormLabel: {
          root: {
            textTransform: "capitalize",
            "&$focused": {
              color: VERY_DARK_YELLOW,
            },
          },
        },
      },
    })
  );
}

const colors = {
  [GREEN]: {
    dark: DARK_GREEN,
  },
  [YELLOW]: {
    dark: VERY_DARK_YELLOW,
  },
  [ORANGE]: {
    dark: DARK_ORANGE,
  },
  [RED]: {
    dark: DARK_RED,
  },
};

export function getDarkColor(color, _isDarkModeEnabled) {
  return get(colors, `[${color}].dark`);
}

export function getHoverColor(color, isDarkModeEnabled) {
  return isDarkModeEnabled ? getButtonColor(color) : getDarkColor(color);
}

export function getButtonColor(color, isDarkModeEnabled) {
  const isPrimaryColor = color === "primary";
  const isSecondaryColor = color === "secondary";

  if (isPrimaryColor) {
    return isDarkModeEnabled ? getDarkColor(PRIMARY_COLOR) : PRIMARY_COLOR;
  }

  if (isSecondaryColor) {
    return isDarkModeEnabled ? getDarkColor(SECONDARY_COLOR) : SECONDARY_COLOR;
  }

  return isDarkModeEnabled ? getDarkColor(color) : color;
}

export function getButtonLabelColor(color, isDisabled) {
  const isGreenColor = color === GREEN;
  const isOrangeColor = color === ORANGE;
  const isSecondaryColor = color === "secondary";

  if (isDisabled) {
    return GREY;
  }

  if (isGreenColor) {
    return WHITE;
  }

  if (isOrangeColor) {
    return WHITE;
  }

  if (isSecondaryColor) {
    return WHITE;
  }

  return BLACK;
}
