import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import times from "lodash/times";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormatIndentIncrease from "@material-ui/icons/FormatIndentIncrease";
import IconButton from "@material-ui/core/IconButton";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { YELLOW, GREY, BLACK, WHITE, RED } from "../../styles/theme";
import Tooltip from "../tooltip/Tooltip";
import { JSON_INDENTATION } from "../../constants/config";

function JSONField({
  input: { name, onChange, value },
  id,
  meta,
  label,
  required,
  disabled,
}) {
  const [lines, setLines] = useState(0);
  const [errorLine, setErrorLine] = useState(0);
  const classes = useStyles({ lines });

  const handleJsonOnChange = useCallback(
    event => {
      onChange(event.target.value);
    },
    [onChange]
  );

  const prettifyJSON = useCallback(() => {
    try {
      onChange(JSON.stringify(JSON.parse(value), null, JSON_INDENTATION));
    } catch (error) {
      onChange(value);
    }
  }, [onChange, value]);

  useEffect(() => {
    const lines = value.split(/\r\n|\r|\n/).length;
    setLines(lines);
  }, [id, value]);

  const hasError = !!meta.error;
  const helperText = hasError ? meta.error : undefined;

  useEffect(() => {
    const errorLine = hasError && Number(meta.error.split("at line ").pop());
    if (!isNaN(errorLine)) {
      setErrorLine(errorLine);
    }
  }, [meta, hasError]);

  return (
    <FormControl component="fieldset" className={classes.formControl}>
      <div className={classes.toolbar}>
        <FormLabel
          className={classes.label}
          focused={false}
          required={required}
          error={hasError}
        >
          {label}
        </FormLabel>
        <div className={classes.buttonContainer}>
          <Tooltip title={"Prettify JSON"}>
            <IconButton
              id={"prettify-json"}
              onClick={prettifyJSON}
              color="inherit"
            >
              <FormatIndentIncrease />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <div className={classes.container}>
        <div className={classes.lines}>
          {times(lines, line => {
            const hasErrorInThisLine = line === errorLine;
            const style = {
              color: hasErrorInThisLine ? RED : WHITE,
              border: hasErrorInThisLine ? "1px solid" : "initial",
            };
            return (
              <div
                key={`${name}-line-${line}`}
                className={classes.line}
                style={style}
              >
                {line}
              </div>
            );
          })}
        </div>
        <textarea
          id={id}
          spellCheck={false}
          onChange={handleJsonOnChange}
          className={classes.textarea}
          value={value}
          disabled={disabled}
        />
      </div>
      <FormHelperText error={hasError}>{helperText}</FormHelperText>
    </FormControl>
  );
}

JSONField.propTypes = {
  input: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  meta: PropTypes.object,
  label: PropTypes.object.isRequired,
  required: PropTypes.bool.isRequired,
};

const useStyles = makeStyles(theme => ({
  formControl: {
    width: "100%",
    minHeight: "400px",
  },
  label: {
    marginTop: "12px",
  },
  container: {
    display: "flex",
  },
  lines: {
    padding: "8px 0",
    width: "31px",
    borderRadius: `${theme.shape.borderRadius}px 0 0 ${theme.shape.borderRadius}px`,
    height: props => `${props.lines * 20 + 32}px`,
    borderRight: `1px Solid ${GREY}`,
    backgroundColor: BLACK,
    textAlign: "center",
    minHeight: "300px",
  },
  line: {
    width: "31px",
    height: "20px",
    fontFamily: "Roboto Mono, Monaco, monospace",
    color: WHITE,
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
  },
  textarea: {
    margin: 0,
    resize: "none",
    borderRadius: `0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0`,
    border: 0,
    fontFamily: "Roboto Mono, Monaco, monospace",
    color: YELLOW,
    backgroundColor: BLACK,
    padding: "8px",
    width: "calc(100% - 32px)",
    fontSize: "14px",
    minHeight: "300px",
    fontWeight: 400,
    lineHeight: "20px",
    height: props => `${props.lines * 20 + 32}px`,
    letterSpacing: "0.01071em",
    overflowX: "auto",
    overflowY: "hidden",
    whiteSpace: "pre",
    textOverflow: "ellipsis",
    "&:focus": {
      outline: 0,
    },
  },
  toolbar: {
    display: "flex",
    position: "relative",
    alignItems: "center",
  },
  buttonContainer: {
    margin: "0 0 0 auto",
  },
}));

export default JSONField;
