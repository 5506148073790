import React, { useCallback, useState } from "react";
import get from "lodash/get";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import TextField from "@material-ui/core/TextField";
import {
  PASSWORD_FIELD_TYPE,
  TEXT_FIELD_TYPE,
} from "../form-builder/constants/fields";
import { hasFieldError } from "../../utils/utils";
import { RED, GREEN } from "../../styles/theme";

const useStyles = makeStyles(_theme => ({
  text: {
    letterSpacing: props => (props.showPassword ? "normal" : "6px"),
    fontWeight: props => (props.showPassword ? "normal" : 600),
  },
}));

function PasswordField({
  input: { name, onChange, value },
  id,
  meta,
  field,
  label,
  required,
  disabled,
  fullWidth,
}) {
  const [showPassword, setShowPassword] = useState(false);
  const classes = useStyles({ showPassword });
  const onChangePassword = useCallback(
    event => {
      const value = get(event, "target.value", "");
      const trimmedValue = value.trim() && value;
      onChange(trimmedValue);
    },
    [onChange]
  );
  const handleClickOnShowPassword = useCallback(showPassword => {
    setShowPassword(!showPassword);
  }, []);

  const hasError = hasFieldError(meta);
  const helperText = getHelperLabel(meta, id);

  return (
    <TextField
      id={id}
      name={name}
      label={label}
      required={required}
      type={showPassword ? TEXT_FIELD_TYPE : PASSWORD_FIELD_TYPE}
      value={value}
      disabled={disabled}
      InputProps={{
        classes: {
          input: classes.text,
        },
        startAdornment: field && field.icon && (
          <InputAdornment position="start" id={`${id}-field-icon`}>
            {field.icon}
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              tabIndex={-1}
              id={`${id}-show-password`}
              aria-label="Toggle password visibility"
              onClick={() => handleClickOnShowPassword(showPassword)}
            >
              {showPassword ? (
                <Visibility id={`${id}-show-password-on`} />
              ) : (
                <VisibilityOff id={`${id}-show-password-off`} />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
      onChange={onChangePassword}
      helperText={helperText}
      error={hasError}
      fullWidth={fullWidth}
    />
  );
}

PasswordField.propTypes = {
  input: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  field: PropTypes.object.isRequired,
  meta: PropTypes.object,
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  required: PropTypes.bool.isRequired,
  fullWidth: PropTypes.bool,
};

function getHelperLabel(meta, id) {
  const { touched = true, error = "" } = meta;

  const hasErrors = hasFieldError(meta);
  const isStringValue = typeof error === "string";

  if (!hasErrors && isStringValue) {
    return <span></span>;
  }

  if (isStringValue) {
    return <span>{error}</span>;
  }

  if (!error) {
    return <span></span>;
  }

  return (
    <span>
      {Object.keys(error).map(CurrentError => {
        const { label, failed } = error[CurrentError];
        return (
          <span
            key={label}
            style={{
              color: getHelperLabelColor(touched, failed),
              padding: "0 4px",
            }}
            id={`${id}-helper-${label}`}
          >
            {label}
          </span>
        );
      })}
    </span>
  );
}

function getHelperLabelColor(touched, failed) {
  if (!failed) {
    return GREEN;
  }

  if (touched) {
    return RED;
  }

  return "inherit";
}

export default PasswordField;
