import React from "react";
import PropTypes from "prop-types";
import includes from "lodash/includes";
import reduce from "lodash/reduce";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Form, Field, FormSpy } from "react-final-form";
import validateField from "./validations/validateField";
import Button from "../button/Button";
import {
  isTranslatableFieldType,
  isLinkedListFieldType,
  FIELD_COMPONENTS,
  BOOLEAN_FIELD_TYPE,
} from "./constants/fields";
import Tabs from "../tabs/Tabs";
import LabelForm from "../../../shared/components/label-form/LabelForm";

function FormBuilder({
  id,
  fields,
  buttonLabel,
  onSubmit,
  initialValues,
  disabledFields,
  validate,
  disableSubmitButton,
  IconButton,
  disabledButtonTooltip,
  buttonTooltip,
  formSpy,
  relatedContents,
  showSubmitButton,
  languages,
  disableAllFields,
  scope,
}) {
  const classes = useStyles();
  const translatableFields = fields.filter(({ type }) =>
    isTranslatableFieldType(type)
  );
  const linkedFields = fields.filter(({ type }) => isLinkedListFieldType(type));

  const hasTranslatableFields = translatableFields.length > 0;
  const hasLinkedFields = linkedFields.length > 0;

  return (
    <Form
      onSubmit={onSubmit}
      subscription={{
        submitting: true,
        values: hasLinkedFields,
      }}
      validate={validate}
      initialValues={setInitialValues(initialValues, fields)}
      render={({ handleSubmit, form }) => (
        <form
          id={id}
          onSubmit={handleSubmit}
          noValidate
          className={classes.formWrapper}
          autoComplete="off"
        >
          {fields
            .filter(({ type }) => !isTranslatableFieldType(type))
            .map((field, index) => (
              <div
                key={`${field.slug}-${index}`}
                className={classes.fieldWrapper}
              >
                <Field
                  id={`field-${field.slug}`}
                  name={field.slug}
                  form={form}
                  component={FIELD_COMPONENTS[field.type]}
                  relatedContents={relatedContents}
                  scope={scope}
                  field={field}
                  fields={fields}
                  type={field.type}
                  label={LabelForm(field.label, field.help, "large")}
                  disabled={
                    disableAllFields ||
                    includes(disabledFields, field.slug) ||
                    field.disabled
                  }
                  required={field.required}
                  validate={validateField(scope, field)}
                  validators={field.validators}
                  margin={field.margin}
                  isSortableContent={field.isSortableContent}
                  fullWidth
                />
              </div>
            ))}
          {hasTranslatableFields && (
            <Tabs
              tabs={languages.map(language => ({
                label: language.name,
                panel: translatableFields.map((field, index) => (
                  <div
                    key={`${field.slug}-${index}`}
                    className={classes.fieldWrapper}
                  >
                    <Field
                      id={`field-${field.slug}-${language.name}`}
                      name={field.slug}
                      form={form}
                      component={FIELD_COMPONENTS[field.type]}
                      relatedContents={relatedContents}
                      scope={scope}
                      field={field}
                      fields={fields}
                      type={field.type}
                      label={LabelForm(field.label, field.help, "large")}
                      language={language.abbreviation}
                      disabled={
                        disableAllFields ||
                        includes(disabledFields, field.slug) ||
                        field.disabled
                      }
                      required={field.required}
                      validate={validateField(scope, field)}
                      validators={field.validators}
                      margin={field.margin}
                      isSortableContent={field.isSortableContent}
                      fullWidth
                    />
                  </div>
                )),
              }))}
            />
          )}
          {buttonLabel && showSubmitButton && (
            <div className={classes.buttonWrapper}>
              <Button
                id={`${id}-submit-button`}
                type="submit"
                disabled={disableSubmitButton}
                disabledTooltip={disabledButtonTooltip}
                tooltip={buttonTooltip}
                Icon={IconButton}
                color={"primary"}
                showIcon={!!IconButton}
                label={buttonLabel}
              />
            </div>
          )}
          <FormSpy onChange={formSpy} subscription={{ values: true }}>
            {_values => <div />}
          </FormSpy>
        </form>
      )}
    />
  );
}

const useStyles = makeStyles(theme => ({
  formWrapper: {
    padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
  },
  fieldWrapper: {
    minHeight: "85px",
    padding: `${theme.spacing(1)}px 0px`,
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(2),
    },
  },
  buttonWrapper: {
    width: "100%",
    textAlign: "end",
  },
}));

const setInitialValues = (initialValues, fields) =>
  reduce(
    fields,
    // we set Boolean values to false by default
    (result, field) =>
      field.type === BOOLEAN_FIELD_TYPE && !initialValues[field.slug]
        ? {
          ...result,
          [field.slug]: false,
        }
        : result,
    initialValues
  );

FormBuilder.propTypes = {
  id: PropTypes.string.isRequired,
  fields: PropTypes.array.isRequired,
  buttonLabel: PropTypes.string,
  onSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  disabledFields: PropTypes.array,
  validate: PropTypes.func,
  disableSubmitButton: PropTypes.bool,
  IconButton: PropTypes.elementType,
  disabledButtonTooltip: PropTypes.string,
  buttonTooltip: PropTypes.string,
  formSpy: PropTypes.func,
  relatedContents: PropTypes.object,
  showSubmitButton: PropTypes.bool,
  languages: PropTypes.array,
  disableAllFields: PropTypes.bool,
  scope: PropTypes.object,
};

export default FormBuilder;
