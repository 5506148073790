import React from "react";
import PropTypes from "prop-types";
import TooltipMUI from "@material-ui/core/Tooltip";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({
  tooltip: {
    textAlign: "center",
    fontSize: "0.9rem"
  }
});

function Tooltip({ children, title, placement }) {
  const classes = useStyles({});

  return title ? (
    <TooltipMUI
      disableFocusListener
      disableTouchListener
      placement={placement || "bottom"}
      title={title}
      classes={{
        tooltip: classes.tooltip
      }}
    >
      <span>{children}</span>
    </TooltipMUI>
  ) : (
    children
  );
}

Tooltip.propTypes = {
  title: PropTypes.string,
  placement: PropTypes.string
};

export default Tooltip;
