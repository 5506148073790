function getHeaders(jwtToken) {
  const headers = {};

  if (jwtToken) {
    headers.Authorization = `Bearer ${jwtToken}`;
  }

  return headers;
}

function requestInterceptorSuccess(config) {
  return {
    ...config,
    headers: {
      ...config.headers,
      ...getHeaders(this.accessToken)
    }
  };
}

function requestInterceptorError(error) {
  return Promise.reject(error);
}

export { requestInterceptorSuccess, requestInterceptorError };
