import { useState, useCallback } from "react";
import includes from "lodash/includes";
import reduce from "lodash/reduce";
import { getContentValue } from "../../../utils/utils";

export default function useSearch(items, keys) {
  const [searchByName, setSearchByName] = useState("");

  const itemsByName = items.filter(content =>
    reduce(
      keys,
      (result, key) => {
        const value = `${getContentValue(content, key)}`;
        return (
          result || includes(value.toLowerCase(), searchByName.toLowerCase())
        );
      },
      false
    )
  );

  const clearSearch = useCallback(() => setSearchByName(""), []);

  return {
    itemsByName,
    setSearchByName,
    searchByName,
    clearSearch
  };
}
