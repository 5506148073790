import React, { useMemo, useCallback } from "react";
import get from "lodash/get";
import isArray from "lodash/isArray";

function I18nFieldAdapter(
  { input: { name, onChange, value, ...restInput }, language, id, ...rest },
  Component
) {
  const valueInThisLanguage = useMemo(() => getValue(value, language), [
    value,
    language
  ]);

  const setValue = useCallback(
    newValue => {
      const valuesFiltered = (value || []).filter(
        ({ lang }) => lang !== language
      );
      onChange([
        ...valuesFiltered,
        {
          lang: language,
          value: newValue
        }
      ]);
    },
    [value, language, onChange]
  );

  return (
    <Component
      id={id}
      {...rest}
      input={{
        name,
        onChange: newValue => setValue(newValue, value, language, onChange),
        value: valueInThisLanguage,
        ...restInput
      }}
    />
  );
}

function getValue(value, language) {
  if (!isArray(value)) {
    return "";
  }

  return get(
    (value || []).find(({ lang }) => lang === language),
    "value",
    ""
  );
}

export default I18nFieldAdapter;
