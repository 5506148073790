import React, { useState, useEffect, useCallback } from "react";
import get from "lodash/get";
import uniq from "lodash/uniq";
import sortBy from "lodash/sortBy";
import DeleteIcon from "@material-ui/icons/Delete";
import CloudUpload from "@material-ui/icons/CloudUpload";
import CloudDownload from "@material-ui/icons/CloudDownload";
import isEmpty from "lodash/isEmpty";
import {
  getContents,
  deleteContent as performDeleteContent,
  unDoDeleteContent as performUnDoDeleteContent,
  updateContent,
  createContent,
  unPublishMultiContent,
  unPublishContent,
  publishMultiContent,
  publishContent,
  orderContents,
  publishVersion,
  getVersionContent,
} from "../http/config";
import {
  CONTENT_DELETED_SUCCESS,
  CONTENT_DELETED_ERROR,
  CONTENT_MARKED_AS_DELETED_SUCCESS,
  CONTENT_MARKED_AS_DELETED_ERROR,
  CONTENT_SAVED_SUCCESS,
  CONTENT_SAVED_ERROR,
  UNPUBLISH_CONTENT_SUCCESS,
  UNPUBLISH_CONTENT_ERROR,
  PUBLISH_CONTENT_SUCCESS,
  PUBLISH_CONTENT_ERROR,
  UNDO_DELETE_CONTENT_SUCCESS,
  UNDO_DELETE_CONTENT_ERROR,
} from "../shared/components/snackbar/snackbars";
import {
  populateRelatedContent,
  getPublishedContent,
  getDraftContent,
  showInfoLabel,
  getRelatedContents,
  getSelectedContent,
  checkIfIsMultiContent,
  checkIfIsSingleContent,
  getPublishedVersionContent,
  getDraftVersionContent,
  getRelatedVersionContents,
  populateRelatedVersionContent,
  updateUrlParams,
  getUrlParam,
  createConsoleError,
} from "../shared/utils/utils";
import { useSnackbar } from "./snackbarContext";
import { useConfiguration } from "./configurationContext";
import { useDialog } from "./dialogContext";
import DialogBodyDiff from "../shared/components/dialog/components/DialogBodyDiff";
import { GREEN } from "../shared/styles/theme";
import { useModule } from "./moduleContext";
import { COCO_APPLICATION_SLUG } from "../shared/constants/config";
import { useCmsConfiguration } from "./cmsConfigurationContext";
import DialogBody from "../pages/Config/components/dialog-body/DialogBody";

const ContentContext = React.createContext();

function useContent() {
  const context = React.useContext(ContentContext);
  if (!context) {
    throw new Error(
      "[COCO STATE ERROR] useContent must be used within ContentProvider"
    );
  }
  return context;
}

function ContentProvider(props) {
  const { application, country, version, languages } = useConfiguration();
  const { module, modules } = useModule();
  const { openSnackbar } = useSnackbar();
  const { openDialog } = useDialog();

  const { performGetCMSConfiguration } = useCmsConfiguration();

  const [content, setContent] = useState(false);
  const [contents, setContents] = useState([]);

  const [relatedContents, setRelatedContents] = useState({});
  const [populatedContents, setPopulatedContents] = useState([]);

  const [populatedVersionContents, setPopulatedVersionContents] = useState([]);

  const [isContentLoading, setIsContentLoading] = useState(false);

  useEffect(() => {
    if (content) {
      updateUrlParams({ content_id: content._id });
    }
  }, [content]);

  useEffect(() => {
    const contentId = getUrlParam("content_id");
    const content = contents.find(content => contentId === content._id);
    if (content) {
      setContent(content);
    }
  }, [contents]);

  const getVersionContents = useCallback(async () => {
    const versionContent = await getVersionContent(
      application,
      country,
      version
    );

    const scope = {
      app: application,
      country,
      version,
    };

    const relatedVersionContents = await getRelatedVersionContents(
      modules,
      scope
    );

    const populatedVersionContents = populateRelatedVersionContent(
      modules,
      versionContent.contents,
      relatedVersionContents
    );

    setPopulatedVersionContents(populatedVersionContents);
  }, [application, country, version, modules]);

  const getModuleContent = useCallback(
    async function () {
      setIsContentLoading(true);

      try {
        setContent(false);
        setContents([]);
        setPopulatedContents([]);

        const contents = await getContents(
          module,
          application,
          country,
          version
        );

        const scope = {
          app: application,
          country,
          version,
        };

        setContents(
          module.isOrdered
            ? contents.sort((a, b) => a.draft.sequence - b.draft.sequence)
            : [...contents]
        );

        const relatedContents = await getRelatedContents(
          modules,
          module,
          scope
        );

        setRelatedContents(relatedContents);

        const populatedContents = populateRelatedContent(
          module,
          contents,
          relatedContents
        );

        setPopulatedContents(populatedContents);
        const hasContent = !isEmpty(contents);

        if (module.isSingle && hasContent) {
          setContent(contents[0]);
        }
        if (version) {
          await getVersionContents();
        }
      } catch (error) {
        createConsoleError("Error fetching Contents", error);
      }

      setIsContentLoading(false);
    },
    [modules, application, country, version, module, getVersionContents]
  );

  useEffect(() => {
    if (module) {
      getModuleContent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [module]);

  const deleteContent = useCallback(
    async content => {
      const isContentPublished = !isEmpty(content.published);
      try {
        setIsContentLoading(true);
        await performDeleteContent(get(content, "_id", content.id));
        setContent(false);
        updateUrlParams({ content_id: null });
        const snackbar = isContentPublished
          ? CONTENT_MARKED_AS_DELETED_SUCCESS
          : CONTENT_DELETED_SUCCESS;
        openSnackbar(snackbar);
        await getModuleContent();
      } catch (error) {
        const snackbar = isContentPublished
          ? CONTENT_MARKED_AS_DELETED_ERROR
          : CONTENT_DELETED_ERROR;
        openSnackbar(snackbar);
      }
      setIsContentLoading(false);
    },
    [getModuleContent, openSnackbar]
  );

  const saveContent = useCallback(
    async values => {
      setIsContentLoading(true);
      const isNewContent = !get(content, "_id", false);
      const data = { draft: values };
      try {
        if (isNewContent) {
          await createContent(module, application, country, version, data);
        } else {
          const id = get(content, "_id");
          await updateContent(id, data);
        }
        await getModuleContent();
        openSnackbar(CONTENT_SAVED_SUCCESS);
      } catch (error) {
        openSnackbar(CONTENT_SAVED_ERROR);
      }
      setIsContentLoading(false);
    },
    [
      application,
      country,
      version,
      module,
      content,
      getModuleContent,
      openSnackbar,
    ]
  );

  const sortMultiContent = useCallback(
    async ({ newIndex, oldIndex }) => {
      setIsContentLoading(true);
      document.body.style.cursor = "default";

      try {
        const sortedContents = [...contents];
        const tmp = sortedContents[oldIndex];
        sortedContents.splice(oldIndex, 1);
        sortedContents.splice(newIndex, 0, tmp);
        await orderContents(
          module,
          application,
          country,
          version,
          sortedContents.map(({ _id }) => _id)
        );
        await getModuleContent();
        openSnackbar(CONTENT_SAVED_SUCCESS);
      } catch (error) {
        openSnackbar(CONTENT_SAVED_ERROR);
      }
      setIsContentLoading(false);
    },
    [
      application,
      country,
      version,
      module,
      openSnackbar,
      contents,
      getModuleContent,
    ]
  );

  const sortGroupableMultiContent = useCallback(
    async ({ newIndex, oldIndex, collection }) => {
      setIsContentLoading(true);
      document.body.style.cursor = "default";
      const sections = sortBy(
        uniq(populatedContents.map(({ draft }) => draft.section))
      );
      const section = sections[collection];

      const sectionFAQs = populatedContents.filter(
        ({ draft }) => draft.section === section
      );

      try {
        const sortedContents = [...sectionFAQs];
        const tmp = sortedContents[oldIndex];
        sortedContents.splice(oldIndex, 1);
        sortedContents.splice(newIndex, 0, tmp);
        await orderContents(module, application, country, version, [
          ...sortedContents.map(({ _id }) => _id),
          ...populatedContents
            .filter(({ draft }) => draft.section !== section)
            .map(({ _id }) => _id),
        ]);
        await getModuleContent();
        openSnackbar(CONTENT_SAVED_SUCCESS);
      } catch (error) {
        openSnackbar(CONTENT_SAVED_ERROR);
      }
      setIsContentLoading(false);
    },
    [
      application,
      country,
      populatedContents,
      version,
      module,
      openSnackbar,
      getModuleContent,
    ]
  );

  const openPublishDialog = useCallback(() => {
    const publish = async () => {
      try {
        const isMultiContent = checkIfIsMultiContent(module, content);
        const isSingleContent = checkIfIsSingleContent(module, content);

        if (isMultiContent) {
          await publishMultiContent(module, application, country, version);
        }
        if (isSingleContent) {
          const contentUpdated = await publishContent(get(content, "_id"));
          setContent(contentUpdated);
        }
        openSnackbar(PUBLISH_CONTENT_SUCCESS);
      } catch (error) {
        openSnackbar(PUBLISH_CONTENT_ERROR);
        createConsoleError("Error during publish content", error);
      }

      await getModuleContent();

      const isCMSContent = application === COCO_APPLICATION_SLUG;

      if (isCMSContent) {
        await performGetCMSConfiguration();
      }
    };

    const currentContent = getSelectedContent(content, populatedContents);

    const past = getPublishedContent(module, currentContent);
    const current = getDraftContent(module, currentContent);
    const publishDialog = {
      title: "Publish Content",
      dialogAction: () => publish(),
      fullWidth: true,
      infoLabel: "*All related content will be published as well",
      buttonLabel: "Publish",
      showInfoLabel: showInfoLabel(module),
      color: GREEN,
      Icon: CloudUpload,
      BodyComponent: DialogBodyDiff,
      bodyProps: {
        past,
        current,
        buttonLabel: "changes",
      },
    };
    openDialog(publishDialog);
  }, [
    application,
    country,
    version,
    module,
    content,
    populatedContents,
    getModuleContent,
    openSnackbar,
    openDialog,
    performGetCMSConfiguration,
  ]);

  const openPublishVersionDialog = useCallback(async () => {
    await getVersionContents();
    const publish = async () => {
      try {
        await publishVersion(application, country, version);
        openSnackbar(PUBLISH_CONTENT_SUCCESS);
      } catch (error) {
        openSnackbar(PUBLISH_CONTENT_ERROR);
        createConsoleError("Error during publish version content", error);
      }
      await getModuleContent();
    };

    const past = getPublishedVersionContent(modules, populatedVersionContents);
    const current = getDraftVersionContent(modules, populatedVersionContents);

    const publishDialog = {
      title: "Publish Version Content",
      dialogAction: () => publish(),
      fullWidth: true,
      infoLabel: "*All related content will be published as well",
      buttonLabel: "Publish Version",
      showInfoLabel: true,
      color: GREEN,
      Icon: CloudUpload,
      BodyComponent: DialogBodyDiff,
      bodyProps: {
        past,
        current,
        buttonLabel: "changes",
      },
    };
    openDialog(publishDialog);
  }, [
    application,
    country,
    version,
    modules,
    getModuleContent,
    openSnackbar,
    openDialog,
    populatedVersionContents,
    getVersionContents,
  ]);

  const openUnPublishDialog = useCallback(() => {
    const unPublish = async () => {
      try {
        const isMultiContent = !module.isSingle && !content;
        if (isMultiContent) {
          await unPublishMultiContent(module, application, country, version);
        } else {
          const contentUpdated = await unPublishContent(get(content, "_id"));
          setContent(contentUpdated);
        }
        openSnackbar(UNPUBLISH_CONTENT_SUCCESS);
      } catch (error) {
        openSnackbar(UNPUBLISH_CONTENT_ERROR);
        createConsoleError("Error during Unpublish content", error);
      }
      await getModuleContent();
    };

    const currentContent = !!content
      ? [populatedContents.find(({ _id }) => _id === content._id)]
      : populatedContents;
    const past = getPublishedContent(module, currentContent);
    const unPublishDialog = {
      title: "Unpublish Content",
      open: false,
      dialogAction: () => unPublish(),
      isLoading: false,
      fullWidth: true,
      buttonLabel: "Unpublish",
      color: "secondary",
      Icon: CloudDownload,
      BodyComponent: DialogBodyDiff,
      bodyProps: {
        past,
        buttonLabel: "changes",
      },
    };
    openDialog(unPublishDialog);
  }, [
    application,
    country,
    version,
    module,
    content,
    populatedContents,
    getModuleContent,
    openSnackbar,
    openDialog,
  ]);

  const openDeleteDialog = useCallback(
    (content, textTitle, textBtnLabel, textBtnTooltip) => {
      const deleteVersionDialog = {
        title: textTitle,
        Icon: DeleteIcon,
        showIcon: true,
        buttonLabel: textBtnLabel,
        buttonTooltip: textBtnTooltip,
        showInfoLabel: false,
        color: "primary",
        infoLabel: "",
        BodyComponent: DialogBody,
        bodyProps: { content, application, country, version },
        dialogAction: () => deleteContent(content),
      };

      openDialog(deleteVersionDialog);
    },
    [application, country, version, openDialog, deleteContent]
  );

  const unDoDeleteContent = useCallback(
    async id => {
      try {
        setIsContentLoading(true);
        await performUnDoDeleteContent(id);
        openSnackbar(UNDO_DELETE_CONTENT_SUCCESS);
        await getModuleContent();
      } catch (error) {
        openSnackbar(UNDO_DELETE_CONTENT_ERROR);
      }
      setIsContentLoading(false);
    },
    [openSnackbar, getModuleContent]
  );

  const selectContent = useCallback(content => {
    setContent(content);
    updateUrlParams({ content_id: content ? content : null });
  }, []);

  const state = {
    content,
    contents,

    // needed for multi-choice fields options
    relatedContents,

    populatedContents,
    populatedVersionContents,

    isContentLoading,

    saveContent,
    selectContent,

    openDeleteDialog,
    unDoDeleteContent,

    openPublishDialog,
    openPublishVersionDialog,
    openUnPublishDialog,

    languages,

    sortMultiContent,
    sortGroupableMultiContent,

    ...props.store,
  };

  return <ContentContext.Provider value={state} {...props} />;
}

export { ContentProvider, useContent };
