import React from "react";
import includes from "lodash/includes";
import orderBy from "lodash/orderBy";
import {
  TableBody as TableBodyMUI,
  TableCell,
  Checkbox,
  TableRow,
  IconButton,
  withStyles,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "../../tooltip/Tooltip";

function TableBody({
  order,
  orderBy: orderedBy,
  setOpenEditRow,
  deleteRows,
  setSelectedRows,
  selectedRows,
  rows,
  columns,
  rowsPerPage,
  page,
  classes,
}) {
  return (
    <TableBodyMUI>
      {orderBy(rows, [orderedBy], [`${order}`])
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row, index) => {
          const isItemSelected = includes(selectedRows, row);
          const selectRow = () => setSelectedRows(isItemSelected
            ? selectedRows.filter(id => id !== row)
            : selectedRows.concat(row));
          return (
            <TableRow
              hover
              key={`value-${index}`}
              role="checkbox"
              aria-checked={isItemSelected}
              tabIndex={-1}
              selected={isItemSelected}
            >
              <TableCell className={classes.actionTableCell} padding="checkbox" onClick={selectRow}>
                <Tooltip title={isItemSelected ? "Deselect row" : "Select this row"}>
                  <Checkbox
                    checked={isItemSelected}
                  />
                </Tooltip>
              </TableCell>
              {columns.filter(column => column.isInPresentation).map((column, index) => {
                return (
                  <TableCell
                    key={`value-${column.label}-${index}`}
                    className={classes.tableCell}
                    align="center"
                    onClick={selectRow}
                    padding="none">
                    <Tooltip title={row[column.id] || null} placement={"bottom-start"}>
                      {row[column.id] || null}
                    </Tooltip>
                  </ TableCell >
                );})}
              <TableCell className={classes.actionTableCell} align="center" padding="checkbox">
                <Tooltip title={"Edit Row"}>
                  <IconButton
                    onClick={() => setOpenEditRow({
                      open: true,
                      isNewRow: false,
                      row: row,
                    })}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
              <TableCell className={classes.actionTableCell} align="center" padding="checkbox">
                <Tooltip title={"Delete Row"}>
                  <IconButton onClick={() => deleteRows([row])}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          );
        })}
    </TableBodyMUI >
  );
}

const styles = {
  actionTableCell: {
    width: "52px",
  },
  tableCell: {
    maxWidth: "40px",
    minWidth: "30px",
    padding: "0 6px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    wordBreak: "break-all",
  },
};

export default withStyles(styles)(TableBody);
