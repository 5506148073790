const invalidDate = "Invalid Date";
const invalidMinDate = "Select a Date after";
const invalidMaxDate = "Select a Date before";

export default function validateDate(value, params) {
  if (!value) {
    return null;
  }

  if (isNaN(Date.parse(value))) {
    return invalidDate;
  }

  if (params) {
    const { min, max } = params;

    if (min) {
      const minDate = new Date(min);
      return minDate.getTime() > new Date(value).getTime()
        ? `${invalidMinDate} ${min}`
        : null;
    }

    if (max) {
      const maxDate = new Date(max);
      return maxDate.getTime() < new Date(value).getTime()
        ? `${invalidMaxDate} ${max}`
        : null;
    }
  }
  return null;
}
