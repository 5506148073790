import React from "react";
import find from "lodash/find";
import get from "lodash/get";
import MultipleChoiceField from "../multiple-choice-field/MultipleChoiceField";
import isNil from "lodash/isNil";

function getMultipleSubtitles(item, keys) {
  const subtitles = []

  if (keys) {
    keys.forEach(key => {
      if (key &&
        item.draft &&
        !isNil(item.draft[key])) { subtitles.push(`${key}: ${item.draft[key]}`) }
    })
  }

  return subtitles
}

export function getMultiRelationFieldOptions(relatedContents, validators, presentationKey, extraKeys) {
  const validator = find(
    validators,
    validator => validator.name === "MultiRelation"
  );
  const target = get(validator, "params.target", false);

  const relatedContent = get(relatedContents, target, []);

  return relatedContent.map(item => ({
    value: item._id,
    label: item.draft.name,
    subtitle: presentationKey &&
      item.draft &&
      !isNil(item.draft[presentationKey]) &&
      `${presentationKey}: ${item.draft[presentationKey]}`,
    extraSubtitles: getMultipleSubtitles(item, extraKeys)
  }));
}

function MultiRelationField({
  id,
  input: { onChange, value },
  label,
  meta,
  required,
  validators,
  relatedContents,
  field,
  disabled,
  isSortableContent
}) {
  return (
    <MultipleChoiceField
      id={id}
      input={{ onChange, value }}
      label={label}
      meta={meta}
      required={required}
      validators={validators}
      field={{
        ...field,
        options: getMultiRelationFieldOptions(relatedContents, validators, field.presentation, field.extraPresentation)
      }}
      disabled={disabled}
      relatedContents={relatedContents}
      isSortableContent={isSortableContent}
    />
  );
}

export default MultiRelationField;
