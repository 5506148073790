import React from "react";
import {
  TableRow,
  withStyles,
  TableCell,
  Checkbox,
  TableHead,
  TableSortLabel,
} from "@material-ui/core";
import { LIGHT_GREY } from "../../../styles/theme";
import Tooltip from "../../tooltip/Tooltip";

function TableHeader({
  classes,
  order,
  setOrder,
  orderBy,
  setOrderBy,
  setSelectedRows,
  selectedRows,
  rows,
  columns,
}) {
  const isChecked = selectedRows.length > 0 && selectedRows.length === rows.length;
  return (
    <TableHead className={classes.background}>
      <TableRow>
        <TableCell className={classes.actionTableCell} padding="checkbox">
          <Tooltip title={isChecked ? "Deselect all Rows" : "Select all Rows"}>
            <Checkbox
              checked={isChecked}
              padding="none"
              align="center"
              onChange={event => {
                if (event.target.checked) {
                  setSelectedRows(rows);
                  return;
                }
                setSelectedRows([]);
              }}
            />
          </Tooltip>
        </TableCell>
        {columns.filter(column => column.isInPresentation).map((column, index) => (
          <TableCell
            key={`header-${column.label}-${index}`}
            className={classes.tableCell}
            align="center"
            padding="none"
            sortDirection={orderBy === column.id ? order : false}
          >
            <Tooltip title={`Sort by ${column.label}`}>
              <TableSortLabel
                align="center"
                active={orderBy === column.id}
                className={classes.tableCellText}
                direction={order}
                onClick={_event => {
                  const isDesc = orderBy === column.id && order === "desc";
                  setOrder(isDesc ? "asc" : "desc");
                  setOrderBy(column.id);
                }}
              >
                {column.label}
              </TableSortLabel>
            </Tooltip>
          </TableCell>
        ))}
        <TableCell
          className={classes.actionTableCell}
          align="center"
          padding="none"
        >
          {"Edit"}
        </TableCell>
        <TableCell
          className={classes.actionTableCell}
          align="center"
          padding="none"
        >
          {"Delete"}
        </TableCell>
      </TableRow>
    </TableHead>
  );
}
const styles = {
  background: {
    backgroundColor: LIGHT_GREY,
  },
  tableCell: {
    maxWidth: "40px",
    minWidth: "30px",
    padding: "0 6px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    wordBreak: "break-all",
  },
  tableCellText: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    wordBreak: "break-all",
  },
  actionTableCell: {
    width: "52px",
  },
};

export default withStyles(styles)(TableHeader);
