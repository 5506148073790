import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { objectToText } from "../../../utils/utils";

const useStyles = makeStyles(_theme => ({
  container: {
    minWidth: "1000px",
  },
}));

function DialogBody({ content }) {
  const classes = useStyles({});
  return (
    <div className={classes.container}>
      <pre>{objectToText(content.conflicts)}</pre>
    </div>
  );
}

export default DialogBody;
