import { lazy } from "react";
import { createBrowserHistory } from "history";
import {
  ALL_USER_ROLES,
  ADMIN_ROLE,
  SUPER_ADMIN_ROLE,
  AUDITOR_ROLE,
  MODERATOR_ROLE,
} from "./roles/roles";

export default createBrowserHistory();

export const BASE_PATHNAME = "/";

export const CONFIG_PATHNAME = `${BASE_PATHNAME}config`;
export const MEDIA_PATHNAME = `${BASE_PATHNAME}media`;
export const RELEASE_TRAINS_PATHNAME = `${BASE_PATHNAME}release-trains`;
export const ADMIN_PATHNAME = `${BASE_PATHNAME}admin`;

// auth routes
export const LOGIN_PATHNAME = `${BASE_PATHNAME}login`;
export const ACTIVATE_ACCOUNT_PATHNAME = `${BASE_PATHNAME}activate-account`;
export const FORGOT_PASSWORD_PATHNAME = `${BASE_PATHNAME}forgot-password`;
export const RESET_PASSWORD_PATHNAME = `${BASE_PATHNAME}reset-password`;
export const EXPIRED_RESET_PASSWORD_PATHNAME = `${BASE_PATHNAME}expired-reset-password`;

// user routes
export const PROFILE_PATHNAME = `${BASE_PATHNAME}profile`;
export const CHANGE_PASSWORD_PATHNAME = `${BASE_PATHNAME}change-password`;

// admin routes
export const USERS_LIST_PATHNAME = `${ADMIN_PATHNAME}/users-list`;
export const CLONE_VERSION_PATHNAME = `${ADMIN_PATHNAME}/clone-version`;
export const CORE_MODULES_PATHNAME = `${ADMIN_PATHNAME}/core-modules`;
export const EXPORT_VERSION_PATHNAME = `${ADMIN_PATHNAME}/export-version`;
export const IMPORT_VERSION_PATHNAME = `${ADMIN_PATHNAME}/import-version`;
export const REMOVE_VERSION_PATHNAME = `${ADMIN_PATHNAME}/remove-version`;
export const ATTACH_COUNTRY_PATHNAME = `${ADMIN_PATHNAME}/attach-country`;
export const ADD_VERSION_PATHNAME = `${ADMIN_PATHNAME}/add-version`;
export const FORCE_UPGRADE_PATHNAME = `${ADMIN_PATHNAME}/force-upgrade`;
export const STATIC_RULES_PATHNAME = `${ADMIN_PATHNAME}/static-rules`;
export const AUDIT_PATHNAME = `${ADMIN_PATHNAME}/audit`;
export const TASK_PATHNAME = `${ADMIN_PATHNAME}/task`;
export const DIFF_BETWEEN_ENVS_PATHNAME = `${ADMIN_PATHNAME}/diff-between-envs`;
export const CREATE_TAG_PATHNAME = `${ADMIN_PATHNAME}/create-tag`;
export const REPORT_FIELD_VALUE_PATHNAME = `${ADMIN_PATHNAME}/report-field-value`;

// media routes
export const MEDIA_CATEGORIES_PATHNAME = `${MEDIA_PATHNAME}/categories`;
export const MEDIA_CATEGORY_DETAILS_PATHNAME = `${MEDIA_PATHNAME}/category-details`;

// release trains routes
export const RELEASE_TRAIN_DETAILS_PATHNAME = `${RELEASE_TRAINS_PATHNAME}/details`;

export const OLD_DASHBOARD_URL = `${BASE_PATHNAME}dashboard`;

export const ERROR_PATHNAME = `${BASE_PATHNAME}error`;
export const UNAVAILABLE_PATHNAME = `${BASE_PATHNAME}unavailable`;

export const routes = [
  {
    path: LOGIN_PATHNAME,
    exact: true,
    isAuthRequired: false,
    component: lazy(() => import("./pages/Login/Login")),
  },
  {
    path: ACTIVATE_ACCOUNT_PATHNAME,
    exact: true,
    isAuthRequired: false,
    component: lazy(() => import("./pages/ActivateAccount/ActivateAccount")),
  },
  {
    path: FORGOT_PASSWORD_PATHNAME,
    exact: true,
    isAuthRequired: false,
    component: lazy(() => import("./pages/ForgotPassword/ForgotPassword")),
  },
  {
    path: RESET_PASSWORD_PATHNAME,
    exact: true,
    isAuthRequired: false,
    component: lazy(() => import("./pages/ResetPassword/ResetPassword")),
  },
  {
    path: EXPIRED_RESET_PASSWORD_PATHNAME,
    exact: true,
    isAuthRequired: false,
    component: lazy(() =>
      import("./pages/ExpiredResetPassword/ExpiredResetPassword")
    ),
  },
  {
    path: UNAVAILABLE_PATHNAME,
    exact: true,
    isAuthRequired: false,
    component: lazy(() => import("./pages/Errors/ErrorScreen")),
  },
  {
    path: BASE_PATHNAME,
    isAuthRequired: true,
    rolesAllowed: ALL_USER_ROLES,
    component: lazy(() => import("./pages/Dashboard/Dashboard")),
    routes: [
      {
        path: ERROR_PATHNAME,
        exact: true,
        isAuthRequired: false,
        component: lazy(() => import("./pages/Errors/ErrorScreen")),
      },
      {
        path: CONFIG_PATHNAME,
        isAuthRequired: true,
        rolesAllowed: ALL_USER_ROLES.filter(role => role !== AUDITOR_ROLE),
        component: lazy(() => import("./pages/Config/Config")),
      },
      {
        path: PROFILE_PATHNAME,
        isAuthRequired: true,
        rolesAllowed: ALL_USER_ROLES,
        component: lazy(() => import("./pages/Profile/Profile")),
      },
      {
        path: CHANGE_PASSWORD_PATHNAME,
        isAuthRequired: true,
        rolesAllowed: ALL_USER_ROLES,
        component: lazy(() => import("./pages/ChangePassword/ChangePassword")),
      },
      {
        path: RELEASE_TRAINS_PATHNAME,
        isAuthRequired: true,
        rolesAllowed: ALL_USER_ROLES.filter(role => role !== AUDITOR_ROLE),
        component: lazy(() => import("./pages/ReleaseTrains/ReleaseTrains")),
        routes: [
          {
            path: RELEASE_TRAIN_DETAILS_PATHNAME,
            isAuthRequired: true,
            rolesAllowed: ALL_USER_ROLES.filter(role => role !== AUDITOR_ROLE),
            component: lazy(() =>
              import("./pages/ReleaseTrains/ReleaseDetails")
            ),
          },
        ],
      },
      {
        path: MEDIA_PATHNAME,
        isAuthRequired: true,
        rolesAllowed: ALL_USER_ROLES.filter(role => role !== AUDITOR_ROLE),
        component: lazy(() => import("./pages/Media/Media")),
        routes: [
          {
            path: MEDIA_CATEGORIES_PATHNAME,
            isAuthRequired: true,
            rolesAllowed: ALL_USER_ROLES.filter(role => role !== AUDITOR_ROLE),
            component: lazy(() =>
              import("./pages/CategoriesGallery/CategoriesGallery")
            ),
          },
          {
            path: MEDIA_CATEGORY_DETAILS_PATHNAME,
            isAuthRequired: true,
            rolesAllowed: ALL_USER_ROLES.filter(role => role !== AUDITOR_ROLE),
            component: lazy(() =>
              import("./pages/CategoryDetails/CategoryDetails")
            ),
          },
        ],
      },
      {
        path: ADMIN_PATHNAME,
        isAuthRequired: true,
        rolesAllowed: [
          ADMIN_ROLE,
          SUPER_ADMIN_ROLE,
          AUDITOR_ROLE,
          MODERATOR_ROLE,
        ],
        component: lazy(() => import("./pages/Admin/Admin")),
        routes: [
          {
            path: USERS_LIST_PATHNAME,
            isAuthRequired: true,
            rolesAllowed: [ADMIN_ROLE, SUPER_ADMIN_ROLE, MODERATOR_ROLE],
            component: lazy(() => import("./pages/UsersList/UsersList")),
          },
          {
            path: STATIC_RULES_PATHNAME,
            isAuthRequired: true,
            rolesAllowed: [ADMIN_ROLE, SUPER_ADMIN_ROLE],
            component: lazy(() => import("./pages/StaticRules/StaticRules")),
          },
          {
            path: CLONE_VERSION_PATHNAME,
            isAuthRequired: true,
            rolesAllowed: [ADMIN_ROLE, SUPER_ADMIN_ROLE],
            component: lazy(() => import("./pages/CloneVersion/CloneVersion")),
          },
          {
            path: EXPORT_VERSION_PATHNAME,
            isAuthRequired: true,
            rolesAllowed: [ADMIN_ROLE, SUPER_ADMIN_ROLE],
            component: lazy(() =>
              import("./pages/ExportVersion/ExportVersion")
            ),
          },
          {
            path: IMPORT_VERSION_PATHNAME,
            isAuthRequired: true,
            rolesAllowed: [ADMIN_ROLE, SUPER_ADMIN_ROLE],
            component: lazy(() =>
              import("./pages/ImportVersion/ImportVersion")
            ),
          },
          {
            path: CREATE_TAG_PATHNAME,
            isAuthRequired: true,
            rolesAllowed: [ADMIN_ROLE, SUPER_ADMIN_ROLE],
            component: lazy(() => import("./pages/CreateTag/CreateTag")),
          },
          {
            path: REMOVE_VERSION_PATHNAME,
            isAuthRequired: true,
            rolesAllowed: [ADMIN_ROLE, SUPER_ADMIN_ROLE],
            component: lazy(() =>
              import("./pages/RemoveVersion/RemoveVersion")
            ),
          },
          {
            path: ATTACH_COUNTRY_PATHNAME,
            isAuthRequired: true,
            rolesAllowed: [ADMIN_ROLE, SUPER_ADMIN_ROLE],
            component: lazy(() =>
              import("./pages/AttachCountry/AttachCountry")
            ),
          },
          {
            path: ADD_VERSION_PATHNAME,
            isAuthRequired: true,
            rolesAllowed: [ADMIN_ROLE, SUPER_ADMIN_ROLE],
            component: lazy(() => import("./pages/AddVersion/AddVersion")),
          },
          {
            path: FORCE_UPGRADE_PATHNAME,
            isAuthRequired: true,
            rolesAllowed: [ADMIN_ROLE, SUPER_ADMIN_ROLE],
            component: lazy(() => import("./pages/ForceUpgrade/ForceUpgrade")),
          },
          {
            path: CORE_MODULES_PATHNAME,
            isAuthRequired: true,
            rolesAllowed: [ADMIN_ROLE, SUPER_ADMIN_ROLE],
            component: lazy(() => import("./pages/CoreModules/CoreModules")),
          },
          {
            path: AUDIT_PATHNAME,
            isAuthRequired: true,
            rolesAllowed: [SUPER_ADMIN_ROLE, AUDITOR_ROLE],
            component: lazy(() => import("./pages/Audit/Audit")),
          },
          {
            path: TASK_PATHNAME,
            isAuthRequired: true,
            rolesAllowed: [SUPER_ADMIN_ROLE, ADMIN_ROLE],
            component: lazy(() => import("./pages/Task/Task")),
          },
          {
            path: DIFF_BETWEEN_ENVS_PATHNAME,
            isAuthRequired: true,
            rolesAllowed: [ADMIN_ROLE, SUPER_ADMIN_ROLE],
            component: lazy(() =>
              import("./pages/DiffBetweenEnvs/DiffBetweenEnvs")
            ),
          },
          {
            path: REPORT_FIELD_VALUE_PATHNAME,
            isAuthRequired: true,
            rolesAllowed: [SUPER_ADMIN_ROLE, ADMIN_ROLE],
            component: lazy(() => import("./pages/ReportFieldValue")),
          },
        ],
      },
    ],
  },
];

function flattenRoutes(routes = []) {
  return routes.reduce((result, route) => [...result, route, ...flattenRoutes(route.routes)], []);
}

const flattenedRoutes = flattenRoutes(routes);

export function getRoute(path) {
  return flattenedRoutes.find(route => route.path === path);
}
