export const NETWORK_TIMEOUT = 15000;

export const DRAWER_FULL_OPEN_WIDTH = 310;
export const CHANGE_DRAWER_TYPE = "md";

export const HEADER_HEIGHT = "60px";

export const SNACKBAR_DURATION = 4600;
export const ERROR_SNACKBAR_DURATION_MULTIPLIER = 2;

export const COCO_APPLICATION_SLUG = "coco_cms";

export const RECAPTCHA_CLIENT = process.env.REACT_APP_RECAPTCHA_CLIENT;
export const ENABLE_CAPTCHA = process.env.REACT_APP_ENABLE_CAPTCHA === "true";

export const ENVIRONMENT = process.env.REACT_APP_ENV;

export const uuidV4Regex = /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i;
export const mongoObjectIdRegex = /^[0-9a-fA-F]{24}$/;

export const CONFIG_PUBLIC_ENDPOINT = "config_public_endpoint";
export const STATIC_CONFIG_PUBLIC_ENDPOINT = "static_config_public_endpoint";
export const FAQ_PUBLIC_ENDPOINT = "faq_public_endpoint";
export const TCPP_PUBLIC_ENDPOINT = "tcpp_public_endpoint";

export const TCPP_MODULE_SLUG = "tcpp";
export const FAQ_MODULE_SLUG = "faq";
export const CREATIVE_PACKAGE_MEDIA_SLUG = "creative_package_media";

export const SAP_ERROR_CODES_MODULE_SLUG = "sap_error_codes";

export const COCO_WEB_PLATFORM_SLUG = "web";

export const APP_TITLE = `Shell B2C COCO - ${ENVIRONMENT.toUpperCase()}`;

export const PUBLISH_DRAFT_BUTTON_TEXTS = {
  textDraftTooltip: "Delete drafted content",
  textDeleteDraft: "Delete this drafted content?",
  textPublishedTooltip: "Mark content to delete when Publishing",
  textDeletePublished: "Mark this content to be deleted when Publishing?",
};

export const JSON_INDENTATION = 2;
