import React from "react";
import { Dialog, DialogContent, withStyles, Typography, IconButton } from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import FormBuilder from "../../form-builder/FormBuilder";
import CloseIcon from "@material-ui/icons/Close";

const DialogTitle = withStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing(2),
    minWidth: "250px",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}))(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6" noWrap>{children}</Typography>
      {onClose ? (
        <IconButton
          id={"close-dialog-button"}
          aria-label="Close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

function EditRowDialog({
  classes, openEditRow, itemsShape, setOpenEditRow, editRow, createNewRow,
}) {
  const { open } = openEditRow;
  const isNewRow = openEditRow.isNewRow;
  const title = isNewRow ? "Add a new Row" : "Edit Row";
  const initialValues = openEditRow.row;
  const buttonLabel = isNewRow ? "Add Row" : "Save Row";

  const handleCloseDialog = () => setOpenEditRow({
    open: false,
    isNewRow: true,
    row: {},
  });
  const rowFields = Object.keys(itemsShape).map(key => ({
    slug: key,
    label: key,
    type: itemsShape[key].type,
    validators: itemsShape[key].validators || [],
    required: itemsShape[key].required,
    fullWidth: true,
    options: itemsShape[key].options,
  }));

  return (
    <Dialog
      open={open}
      onClose={handleCloseDialog}
      aria-labelledby="form-dialog-title"
      fullWidth
      classes={{
        paper: classes.mobile,
      }}
    >
      <DialogTitle onClose={handleCloseDialog} id="dialog-row-close" >
        {title}
      </DialogTitle>
      <DialogContent >
        <FormBuilder
          id={"table-form"}
          key={"table-field"}
          initialValues={initialValues}
          formTitle={title}
          showSubmitButton
          onSubmit={async values =>
            isNewRow ? createNewRow(values) : editRow(values, openEditRow.row)}
          fields={rowFields}
          buttonLabel={buttonLabel}
        />
      </DialogContent>
    </Dialog>
  );
}

const styles = theme => ({
  content: {
    padding: "24px",
  },
  mobile: {
    [theme.breakpoints.down("sm")]: {
      margin: 0,
    },
  },
});

export default withStyles(styles)(EditRowDialog);

