import isString from "lodash/isString";
import validateLength from "./validateLength";

const invalidValue = "Invalid Value";

export default function validateText(value, params) {
  if (!value) {
    return null;
  }

  const isStringValue = isString(value);

  if (!isStringValue) {
    return invalidValue;
  }

  if (params) {
    const { min, max } = params;

    const errorLength = validateLength(value, min, max);

    if (errorLength) {
      return errorLength;
    }
  }

  return null;
}
