import React from "react";
import ListItem from "@material-ui/core/ListItem";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Tooltip from "../../tooltip/Tooltip";
import SearchField from "../../search-field/SearchField";

function ItemsSearchBar({
  id,
  isAllItemsSelected,
  disabled,
  selectAllItems: toggleAllItems,
  setSearchItemsByName,
  searchItemsByName
}) {
  return (
    <ListItem>
      <ListItemIcon>
        <Tooltip
          title={
            isAllItemsSelected
              ? "All items already selected"
              : "Select all items"
          }
        >
          <Checkbox
            color={"primary"}
            id={`${id}-select-all-items`}
            checked={isAllItemsSelected}
            disabled={disabled}
            tabIndex={-1}
            onClick={toggleAllItems}
            disableRipple
          />
        </Tooltip>
      </ListItemIcon>
      <ListItemText
        disableTypography
        primary={
          <SearchField
            id={`${id}-search-options`}
            setFilterByName={setSearchItemsByName}
            filterByName={searchItemsByName}
            placeholder={"Search..."}
            fullWidth
          />
        }
      />
    </ListItem>
  );
}

export default ItemsSearchBar;
