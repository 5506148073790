import React, { useState, useEffect, useCallback } from "react";
import history, {
  CONFIG_PATHNAME,
  LOGIN_PATHNAME,
  BASE_PATHNAME,
  getRoute,
} from "../routes";
import get from "lodash/get";
import {
  redirect,
  getUserRoleFromJWT,
  getUserIdFromJWT,
  createConsoleError,
} from "../shared/utils/utils";
import Api from "../http/Api";
import { logout, login, getUserProfile } from "../http/auth";
import {
  LOGIN_REQUEST_SUCCESS,
  LOGIN_REQUEST_ERROR,
  LOGOUT_REQUEST_ERROR,
  LOGOUT_REQUEST_SUCCESS,
} from "../shared/components/snackbar/snackbars";
import { useSnackbar } from "./snackbarContext";
import isUserRoleAllowedTo from "../roles/roles";

const AuthorizationContext = React.createContext();

function useAuthorization() {
  const context = React.useContext(AuthorizationContext);
  if (!context) {
    throw new Error(
      "[COCO STATE ERROR] useAuthorization must be used within a AuthorizationProvider"
    );
  }
  return context;
}

function AuthorizationProvider(props) {
  const [accessToken, setAccessToken] = useState();
  const [refreshToken, setRefreshToken] = useState();

  const [isUserAuthenticated, setIsUserAuthenticated] = useState(
    !!Api.accessToken && !!Api.refreshToken
  );

  const [isLoginLoading, setIsLoginLoading] = useState(false);

  const [userId, setUserId] = useState(getUserIdFromJWT());
  const [userRoles, setUserRoles] = useState(getUserRoleFromJWT() || []);

  const [userProfile, setUserProfile] = useState({});

  const { openSnackbar } = useSnackbar();

  const performGetUserProfile = useCallback(async () => {
    try {
      const { data: userProfile } = await getUserProfile(userId);
      setUserProfile(userProfile);
    } catch (error) {
      createConsoleError("Error", error);
    }
  }, [userId]);

  useEffect(() => {
    if (userId) {
      performGetUserProfile();
    }
  }, [performGetUserProfile, userId]);

  useEffect(() => {
    if (Api.accessToken) {
      setAccessToken(Api.accessToken);
      setIsUserAuthenticated(true);
      sessionStorage.setItem("accessToken", Api.accessToken);
      setUserId(getUserIdFromJWT());
      setUserRoles(getUserRoleFromJWT());
    } else {
      setAccessToken(null);
      setIsUserAuthenticated(false);
      setUserRoles([]);
      setUserId(null);
      setUserProfile({});
      sessionStorage.removeItem("accessToken");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Api.accessToken]);

  useEffect(() => {
    if (Api.refreshToken) {
      setRefreshToken(Api.refreshToken);
      sessionStorage.setItem("refreshToken", Api.refreshToken);
    } else {
      setRefreshToken(null);
      setIsUserAuthenticated(false);
      sessionStorage.removeItem("refreshToken");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Api.refreshToken]);

  const performLogin = useCallback(
    async (values, location) => {
      try {
        setIsLoginLoading(true);
        const { accessToken, refreshToken, user } = await login(values);
        setUserRoles(user.roles);
        setUserId(user._id);
        setAccessToken(accessToken);
        setRefreshToken(refreshToken);
        redirectAfterLogin(location, user);
        setIsLoginLoading(false);
        openSnackbar(LOGIN_REQUEST_SUCCESS);
      } catch (error) {
        createConsoleError("Error", error);
        setIsUserAuthenticated(false);
        setIsLoginLoading(false);
        setUserRoles([]);
        setUserId(null);
        setUserProfile({});
        openSnackbar(LOGIN_REQUEST_ERROR);
      }
    },
    [openSnackbar]
  );

  const performLogOut = useCallback(async () => {
    try {
      setIsLoginLoading(true);
      await logout();
      setAccessToken(null);
      setRefreshToken(null);
      setUserRoles([]);
      setUserProfile({});
      setUserId(null);
      redirect(LOGIN_PATHNAME);
      setIsLoginLoading(false);
      openSnackbar(LOGOUT_REQUEST_SUCCESS);
    } catch (error) {
      setIsLoginLoading(false);
      openSnackbar(LOGOUT_REQUEST_ERROR);
    }
  }, [openSnackbar]);

  const isUserAllowedTo = useCallback(
    action => isUserRoleAllowedTo(userRoles, action), [userRoles]
  );

  const isUserAllowedToNavigate = useCallback(
    path => {
      const { isAuthRequired, rolesAllowed } = getRoute(path);

      return !isAuthRequired || userRoles.some(role => rolesAllowed.includes(role));
    },
    [userRoles]
  );

  const state = {
    userId,
    userProfile,
    userRoles,
    setUserProfile,
    performGetUserProfile,
    isUserAuthenticated,
    isUserAllowedTo,
    isUserAllowedToNavigate,
    setIsUserAuthenticated,
    performLogin,
    isLoginLoading,
    performLogOut,
    accessToken,
    refreshToken,
    ...props.store,
  };

  return <AuthorizationContext.Provider value={state} {...props} />;
}

function redirectAfterLogin(location, _user) {
  const { pathname, search } = get(location, "state.from", false);
  const customRedirect = pathname && pathname !== BASE_PATHNAME;

  if (customRedirect) {
    history.push(`${pathname}${search}`);
    return;
  }

  history.push(CONFIG_PATHNAME);
}

export { AuthorizationProvider, useAuthorization };
