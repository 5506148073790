import React, { useState, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({
  flexContainer: {
    padding: "16px",
    display: "grid",
    gridGap: '10px',
    gridTemplateColumns: props => `repeat(auto-fill, minmax(${props.heightPerItem}px, 1fr))`,
    flex: 1
  }
});

function InfiniteScrollContainer({
  component,
  items,
  isFlexContainer,
  initialItems: limit,
  heightPerItem,
  maxHeight
}) {
  const classes = useStyles({ heightPerItem });

  const [initialItems, setInitItems] = useState(limit);
  const [showedItems, setShowedItems] = useState(items.slice(0, initialItems));

  useEffect(() => {
    setShowedItems(items.slice(0, initialItems));
  }, [items, initialItems]);

  const hasMore = showedItems.length < items.length;
  const height =
    heightPerItem * items.length > maxHeight
      ? maxHeight
      : heightPerItem * items.length;

  function showMoreItems() {
    const initPos = showedItems.length;
    const finalPos = initPos + initialItems;
    const newItems = showedItems.concat(items.slice(initPos, finalPos));
    setShowedItems(newItems);
    setInitItems(newItems.length);
  }

  const hasItems = items.length > 0;
  return (
    hasItems && (
      <InfiniteScroll
        dataLength={showedItems.length}
        next={showMoreItems}
        hasMore={hasMore}
        height={height}
      >
        {isFlexContainer ? (
          <div className={classes.flexContainer}>
            {showedItems.map(component)}
          </div>
        ) : (
          showedItems.map(component)
        )}
      </InfiniteScroll>
    )
  );
}

InfiniteScrollContainer.propTypes = {
  component: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  initialItems: PropTypes.number.isRequired,
  heightPerItem: PropTypes.number.isRequired,
  maxHeight: PropTypes.number.isRequired,
  isFlexContainer: PropTypes.bool
};

export default InfiniteScrollContainer;
