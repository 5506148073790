import Api from "../Api";
import {
  BACKEND_PUBLIC_URL,
  PUBLIC_CONFIG_URL,
  BACKEND_CONFIG_URL,
  CONTENT_URL
} from "../constants/endpoints";
import { COCO_APPLICATION_SLUG } from "../../shared/constants/config";

export async function getPublicConfigurationContent(
  application,
  country,
  version,
  platform
) {
  const url = `${BACKEND_PUBLIC_URL}${PUBLIC_CONFIG_URL}/${application}/${platform}/config/dynamic/${country}/${version}`;
  return Api.get(url);
}

export async function getPublicStaticConfigurationContent(
  application,
  country,
  version
) {
  const url = `${BACKEND_PUBLIC_URL}/api/spaces/static/${application}/${country}/${version}`;
  return Api.get(url);
}

export async function getPublicFAQsContent(
  application,
  market,
  version,
  platform
) {
  const url = `${BACKEND_PUBLIC_URL}${PUBLIC_CONFIG_URL}/${application}/${platform}/content/faq/${market}/${version}`;
  return Api.get(url);
}

export async function getPublicTCPPContent(
  application,
  market,
  version,
  platform
) {
  const url = `${BACKEND_PUBLIC_URL}${PUBLIC_CONFIG_URL}/${application}/${platform}/content/tcpp/${market}/${version}`;
  return Api.get(url);
}

export function getCMSConfiguration() {
  const url = `${BACKEND_CONFIG_URL}${CONTENT_URL}?application=${COCO_APPLICATION_SLUG}`;
  return Api.get(url);
}
