import React from "react";
import PropTypes from "prop-types";
import TextFieldMUI from "@material-ui/core/TextField";

function UUIDField({
  id,
  input: { name, value },
  label,
  placeholder,
  fullWidth,
}) {
  return (
    <TextFieldMUI
      id={id}
      name={name}
      label={label}
      fullWidth={fullWidth}
      disabled
      placeholder={placeholder}
      type="text"
      value={value}
    />
  );
}

UUIDField.propTypes = {
  id: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  fullWidth: PropTypes.bool,
};

export default UUIDField;
