import compact from "lodash/compact";
import filter from "lodash/filter";
import find from "lodash/find";
import { stringify } from "query-string";
import { addUrlParams, generateFile } from "../../shared/utils/utils";
import Api from "../Api";
import {
  APPS_URL,
  BACKEND_CONFIG_URL,
  CLONE_VERSION_URL,
  CONTENT_URL,
  COUNTRIES_URL,
  PLATFORMS_URL,
  EXPORT_VERSION_URL,
  FORCE_UPGRADE_URL,
  API_OS_VERSIONS_URL,
  IMPORT_VERSION_URL,
  LANGUAGES_URL,
  MODULES_AVAILABLE_URL,
  MODULE_URL,
  VERSIONS_URL,
  RELEASE_TRAINS_URL,
} from "../constants/endpoints";

const blobOptions = { type: "application/json" };

const getActualScope = (scope, country, version) => ({
  countryScope: scope !== "application" && country ? country : null,
  versionScope: scope === "version" && version ? version : null,
});

const getContentParams = (module, app, country, version) => {
  const { countryScope, versionScope } = getActualScope(
    module.scope,
    country,
    version
  );
  return [
    { param: "module", value: module.slug },
    { param: "application", value: app },
    { param: "country", value: countryScope },
    { param: "version", value: versionScope },
  ];
};

const filterContentsByScope = (contents, module, _app, country, version) => {
  const { isSingle, scope } = module;
  const { countryScope, versionScope } = getActualScope(
    scope,
    country,
    version
  );

  const filterContent = content =>
    content.country === countryScope && content.version === versionScope;

  return isSingle
    ? compact([find(contents, filterContent)])
    : filter(contents, filterContent);
};

export function getApps(app) {
  const baseUrl = `${BACKEND_CONFIG_URL}${APPS_URL}`;
  const url = app ? `${baseUrl}/${app}` : baseUrl;
  return Api.get(url);
}

export function getModules(app, country, version) {
  const params = [
    { param: "country", value: country },
    { param: "version", value: version },
  ];
  const url = addUrlParams(`${BACKEND_CONFIG_URL}${MODULE_URL}/${app}`, params);
  return Api.get(url);
}

export async function getContents(module, app, country, version) {
  const baseUrl = `${BACKEND_CONFIG_URL}${CONTENT_URL}`;

  const params = [...getContentParams(module, app, country, version)];
  const url = addUrlParams(baseUrl, params);

  const response = await Api.get(url);

  return filterContentsByScope(
    response.contents,
    module,
    app,
    country,
    version
  );
}

/**
 * Get contents by module (not using country and version)
 * @param {string} module the module slug to get the data from
 * @returns
 */
export async function getContentsByModule(module) {
  const url = `${BACKEND_CONFIG_URL}${CONTENT_URL}/module/${module}`;
  return Api.get(url);
}

export function getVersionContent(app, country, version) {
  const url = `${BACKEND_CONFIG_URL}${CONTENT_URL}?application=${app}&country=${country}&version=${version}`;
  return Api.get(url);
}

export function createContent(module, app, country, version, data) {
  const url = addUrlParams(
    `${BACKEND_CONFIG_URL}${CONTENT_URL}`,
    getContentParams(module, app, country, version)
  );
  return Api.post(url, data);
}

export function orderContents(module, app, country, version, sortedContents) {
  const { countryScope, versionScope } = getActualScope(
    module.scope,
    country,
    version
  );

  const versionLabel = versionScope ? `/versions/${versionScope}` : "";
  const url = `${BACKEND_CONFIG_URL}/api/applications/${app}/countries/${countryScope}${versionLabel}/modules/${module.slug}/contents/sequence`;
  return Api.put(url, {
    data: {
      sequence: sortedContents,
    },
  });
}

export function updateContent(id, data) {
  const url = `${BACKEND_CONFIG_URL}${CONTENT_URL}/${id}`;
  return Api.put(url, data);
}

export function deleteContent(id) {
  const url = `${BACKEND_CONFIG_URL}${CONTENT_URL}/${id}`;
  return Api.delete(url);
}

export function unDoDeleteContent(id) {
  const url = `${BACKEND_CONFIG_URL}${CONTENT_URL}/${id}/trash`;
  const body = {
    data: {
      status: "restore",
    },
  };
  return Api.put(url, body);
}

export function publishContent(id) {
  const url = `${BACKEND_CONFIG_URL}${CONTENT_URL}/${id}/publish`;
  return Api.put(url);
}

export function publishMultiContent(module, app, country, version) {
  const url = addUrlParams(
    `${BACKEND_CONFIG_URL}${CONTENT_URL}/publish`,
    getContentParams(module, app, country, version)
  );
  return Api.put(url);
}

export function publishVersion(app, country, version) {
  const url = `${BACKEND_CONFIG_URL}/api/applications/${app}/countries/${country}/versions/${version}/contents/publish`;
  return Api.post(url);
}

export function unPublishContent(id) {
  const url = `${BACKEND_CONFIG_URL}${CONTENT_URL}/${id}/unpublish`;
  return Api.put(url);
}

export function unPublishMultiContent(module, app, country, version) {
  const url = addUrlParams(
    `${BACKEND_CONFIG_URL}${CONTENT_URL}/unpublish`,
    getContentParams(module, app, country, version)
  );
  return Api.put(url);
}

export function getLanguages(country) {
  const params = [{ param: "country", value: country }];
  const url = addUrlParams(`${BACKEND_CONFIG_URL}${LANGUAGES_URL}`, params);
  return Api.get(url);
}

export function setDownloadPath(app, country, version, showDownloadStaticConfigTest){
  if(showDownloadStaticConfigTest === true){
    const url = `${BACKEND_CONFIG_URL}/api/spaces/static_test/${app}/${country}/${version}`;
    const fileNameType = "static_config_test";
    getJSONContent(app, country, version, url, fileNameType);
  } else {
    const url = `${BACKEND_CONFIG_URL}/api/spaces/static/${app}/${country}/${version}`;
    const fileNameType = "static_config";
    getJSONContent(app, country, version, url, fileNameType);
  }
}

export function getJSONContent(app, country, version, url, fileNameType) {
  return Api.get(url, { headers: { responseType: "blob" } }).then(data => {
    const url = window.URL.createObjectURL(
      new Blob([JSON.stringify(data)], blobOptions)
    );
    const link = document.createElement("a");
    link.href = url;
    const fileName = `${fileNameType}_${app}_${country}_${version}.json`;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
  });
}

export function cloneVersion(data) {
  const url = `${BACKEND_CONFIG_URL}${CLONE_VERSION_URL}`;
  return Api.post(url, data);
}

export function exportVersion(data) {
  const url = `${BACKEND_CONFIG_URL}${EXPORT_VERSION_URL}`;
  return Api.post(url, data).then(data => {
    const fileName = data.meta.filename;
    const url = window.URL.createObjectURL(
      new Blob([JSON.stringify(data)], blobOptions)
    );
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
  });
}

export function removeVersion(application, version) {
  const url = `${BACKEND_CONFIG_URL}${VERSIONS_URL}/${application}/${version}`;
  return Api.delete(url);
}

export function importVersion({ file, environmentValues }) {
  const blob = new Blob([file], blobOptions);
  const formData = new FormData();
  formData.append("file", blob);
  formData.append("patch", JSON.stringify(environmentValues));
  const url = `${BACKEND_CONFIG_URL}${IMPORT_VERSION_URL}`;

  return Api.post(url, formData, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}

export function attachCountries(applicationId, countries, modules) {
  const url = `${BACKEND_CONFIG_URL}${APPS_URL}/${applicationId}${COUNTRIES_URL}`;
  return Api.post(url, {
    data: countries.map(country => ({
      applicationId,
      countryId: country,
      modules,
    })),
  });
}

export function addVersions(applicationId, countryId, moduleIds, version) {
  const url = `${BACKEND_CONFIG_URL}${APPS_URL}/${applicationId}${COUNTRIES_URL}/${countryId}/versions`;
  return Api.post(url, {
    data: {
      applicationId,
      countryId,
      modules: moduleIds,
      version,
    },
  });
}

export function getCountries() {
  const url = `${BACKEND_CONFIG_URL}/api${COUNTRIES_URL}`;
  return Api.get(url);
}

export function getAvailableModules(appSlug) {
  const url = `${BACKEND_CONFIG_URL}/${MODULES_AVAILABLE_URL}/${appSlug}`;
  return Api.get(url);
}

/**
 * @name forceUpgrade
 * @description Endpoint to perform upgrade versions by country
 * @param {*} data
 */
export function forceUpgrade(data) {
  const url = (data => {
    const { applicationId, country } = data;

    const APPLICATION_PATHNAME = `${APPS_URL}/${applicationId}`;
    const COUNTRY_PATHNAME = `${COUNTRIES_URL}/${country}`;
    const FORCE_UPGRADE_PATHNAME = `${FORCE_UPGRADE_URL}`;

    return [
      BACKEND_CONFIG_URL,
      APPLICATION_PATHNAME,
      COUNTRY_PATHNAME,
      FORCE_UPGRADE_PATHNAME,
    ].join("");
  })(data);

  return Api.post(url, { data });
}

export function updateStaticSchema(data) {
  const url = `${BACKEND_CONFIG_URL}/api/modules/${data.moduleId}/fields/${data.fieldSlug}/validators/${data.validatorName}/versions/${data.version}`;
  return Api.put(url, { data });
}

export function getEnvDiffConfigFile({
  application,
  country,
  platform,

  srcEnv,
  srcVersion,

  dstEnv,
  dstVersion,
}) {
  const srcUrl = `/src-env/${srcEnv}/src-version/${srcVersion}`;
  const dstUrl = `/dst-env/${dstEnv}/dst-version/${dstVersion}`;
  const baseUrl = `${BACKEND_CONFIG_URL}/api/comparators/applications/${application}/countries/${country}/platforms/${platform}`;

  const url = `${baseUrl}${srcUrl}${dstUrl}`;

  return Api.get(url);
}

export function getEnvDiffTermsAndFaqsFile({
  application,
  language,
  country,
  platform,
  content,

  srcEnv,
  srcVersion,

  dstEnv,
  dstVersion,
}) {
  const market = `${language}-${country}`;

  const srcUrl = `/src-env/${srcEnv}/src-version/${srcVersion}`;
  const dstUrl = `/dst-env/${dstEnv}/dst-version/${dstVersion}`;
  const baseUrl = `${BACKEND_CONFIG_URL}/api/comparators/applications/${application}/markets/${market}/platforms/${platform}/contents/${content}`;

  const url = `${baseUrl}${srcUrl}${dstUrl}`;

  return Api.get(url);
}

export function getTasks(page, filters) {
  const queryParams = filters.map(filter => ({
    param: filter.filter,
    value: filter.value,
  }));
  queryParams.push({
    param: "page",
    value: page,
  });
  const tasksUrl = `${BACKEND_CONFIG_URL}/api/tasks`;
  const url = addUrlParams(tasksUrl, queryParams);
  return Api.get(url);
}

export function retryTask(taskId) {
  const url = `${BACKEND_CONFIG_URL}/api/tasks/${taskId}/retry`;
  return Api.post(url);
}

export function createTag({
  application,
  version,
  tag,
  description,
  platforms,
}) {
  const url = `${BACKEND_CONFIG_URL}/api/applications/${application}/versions/${version}/tags`;
  const data = {
    tag,
    description,
    platforms,
  };

  return Api.post(url, data);
}

export function getTask(taskId) {
  const tasksUrl = `${BACKEND_CONFIG_URL}/api/tasks/${taskId}`;
  return Api.get(tasksUrl);
}

export function docToHtml(file) {
  const blob = new Blob([file], blobOptions);
  const bodyFormData = new FormData();
  bodyFormData.append("file", blob);

  const url = `${BACKEND_CONFIG_URL}/api/config/tools/doc-to-html`;

  return Api.post(url, bodyFormData, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}

export function htmlToDoc(html) {
  const url = `${BACKEND_CONFIG_URL}/api/config/tools/html-to-doc`;

  return Api.post(
    url,
    {
      data: {
        html,
      },
    },
    { responseType: "blob" }
  );
}

export function createFieldValueReport({
  application,
  module,
  fields,
  countries,
  versions,
}) {
  const url = `${BACKEND_CONFIG_URL}/api/reports/applications/${application}/modules/${module}?${stringify(
    {
      countries,
      fields,
      versions,
    },
    { arrayFormat: "bracket" }
  )}`;

  return Api.get(url, { responseType: "blob" }).then(data => {
    const timestamp = new Date().getTime();
    const fileName = `report-${application}-${module}-${timestamp}.xlsx`;
    generateFile(data, fileName);
  });
}

/**
 * @name getMinOSVersion
 * @description Endpoint to perform the call to the Android/iOS APIs and retrieve the minOSVersion
 * @param {*} data
 */
export function getMinOSVersion(data) {
  const url = (data => {
    const { application, country, platform } = data;

    const APPLICATION_PATHNAME = `${APPS_URL}/${application}`;
    const COUNTRY_PATHNAME = `${COUNTRIES_URL}/${country}`;
    const PLATFORM_PATHNAME = `${PLATFORMS_URL}/${platform}`;
    const MIN_OS_VERSIONS_PATHNAME = `${API_OS_VERSIONS_URL}`;

    return [
      BACKEND_CONFIG_URL,
      APPLICATION_PATHNAME,
      COUNTRY_PATHNAME,
      PLATFORM_PATHNAME,
      MIN_OS_VERSIONS_PATHNAME,
    ].join("");
  })(data);

  return Api.get(url);
}

export function getReleaseTrainsDetails(releaseNumber) {
  return Api.get(
    `${BACKEND_CONFIG_URL}${RELEASE_TRAINS_URL}?releaseNumber=${releaseNumber}`
  );
}
