import get from "lodash/get";
import { NETWORK_TIMEOUT } from "../shared/constants/config";

class HttpError {
  constructor(axiosError = { data: {}, config: {} }) {
    const {
      data = {
        message: "",
        errors: []
      },
      config = {
        url: ""
      },
      status
    } = axiosError;

    this.config = config;
    this.errors = data.errors;
    this.message = data.message;
    this.status = status;
    this.url = config.url;
  }

  getError(index) {
    return this.errors && this.errors[index];
  }

  getErrorAdditionalDetail(index) {
    return this.errors && this.errors[index].additionalDetails;
  }

  getErrorDetail(index = 0) {
    return get(this.getError(index), "detail", false);
  }

  isAccessTokenExpired() {
    return (
      this.status === HTTP_STATUS.UNAUTHORIZED &&
      this.message === ERROR_MESSAGES.ACCESS_TOKEN_EXPIRED
    );
  }

  showBackendError() {
    return this.getErrorDetail(0);
  }

  showBackendErrorAdditionalDetails() {
    return this.getErrorAdditionalDetail(0);
  }
  invalidAccessToken() {
    return this.status === HTTP_STATUS.UNAUTHORIZED;
  }

  invalidRefreshToken() {
    return this.status === HTTP_STATUS.UNAUTHORIZED;
  }

  unauthorized() {
    return this.status === HTTP_STATUS.UNAUTHORIZED;
  }

  resourceNotFound() {
    return this.status === HTTP_STATUS.NOT_FOUND;
  }

  badRequest() {
    return this.status === HTTP_STATUS.BAD_REQUEST;
  }

  serverError() {
    return this.status === HTTP_STATUS.SERVER_ERROR;
  }

  isForceUpgradeError() {
    return this.status === HTTP_STATUS.FORCE_UPGRADE;
  }

  isTimeoutError() {
    return this.message === NETWORK_ERROR;
  }

  isNetworkError() {
    return this.message === TIMEOUT_ERROR;
  }

  isUnavailable() {
    return this.status === HTTP_STATUS.UNAVAILABLE;
  }

}

const ERROR_MESSAGES = {
  EMAIL_NOT_VERIFIED: "email_not_verified",
  ACCESS_TOKEN_EXPIRED: "jwt expired",
  ACCOUNT_LOCKED: "account_locked"
};

const HTTP_STATUS = {
  OK: 200,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  CONFLICT: 409,
  FORCE_UPGRADE: 426,
  SERVER_ERROR: 500,
  UNAVAILABLE: 503
};

export const NETWORK_ERROR = "Network Error";

export const TIMEOUT_ERROR = `timeout of ${NETWORK_TIMEOUT}ms exceeded`;

export const NETWORK_ERROR_OBJECT = {
  data: {
    message: NETWORK_ERROR,
    errors: [{ detail: NETWORK_ERROR }]
  }
};

export const TIMEOUT_ERROR_OBJECT = {
  data: {
    message: TIMEOUT_ERROR,
    errors: [{ detail: TIMEOUT_ERROR }]
  }
};

export const NETWORK_CLIENT_ERROR = {
  [NETWORK_ERROR]: NETWORK_ERROR_OBJECT,
  [TIMEOUT_ERROR]: TIMEOUT_ERROR_OBJECT
};

export { HTTP_STATUS, HttpError };
