import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import makeStyles from "@material-ui/core/styles/makeStyles";
import shellLogo from "../../assets/images/icon_shell.svg";

const useStyles = makeStyles(_theme => {
  const getSize = props =>
    !props.isFullScreen && props.minHeight && props.isLoading
      ? `${props.minHeight}px`
      : "auto";
  return {
    "@keyframes beatHeart": {
      from: {
        transform: "scale(1)",
      },
      to: {
        transform: "scale(1.3)",
      },
    },
    container: {
      overflow: props => (props.isLoading ? "hidden" : "visible"),
      minHeight: getSize,
      height: getSize,
    },
    circularProgress: {
      position: props => (props.isFullScreen ? "absolute" : "static"),
      top: props => (props.isFullScreen ? "50%" : "auto"),
      left: 0,
      height: props =>
        !props.isFullScreen && props.minHeight && props.isLoading
          ? `${props.minHeight}px`
          : 0,
      paddingTop: props =>
        !props.isFullScreen && props.minHeight && props.isLoading
          ? (props.minHeight - 85) / 2
          : 0,
      opacity: props => (props.isLoading ? 1 : 0),
      width: "100%",
      textAlign: "center",
    },
    logo: {
      animation: "$beatHeart .75s linear infinite alternate",
      width: "36px",
      height: "34px",
      margin: "0px 0 25px -58px",
    },
    children: {
      opacity: props => (props.isLoading ? 0 : 1),
    },
  };
});

function Loader({ isLoading, children, minHeight = 0, isFullScreen }) {
  const classes = useStyles({ isLoading, isFullScreen, minHeight });
  return (
    <div className={classes.container}>
      {isLoading && (
        <div className={classes.circularProgress} id={"loader"}>
          <CircularProgress size={80} thickness={2.5} />
          <img className={classes.logo} src={shellLogo} alt={"shell-logo"} />
        </div>
      )}
      <div className={classes.children}>{children}</div>
    </div>
  );
}

export default Loader;
