import React from "react";
import { Route, Redirect } from "react-router-dom";
import { LOGIN_PATHNAME } from "../../../routes";
import { useAuthorization } from "../../../store/authorizationContext";

function RouteWithSubRoutes({ ...route }) {
  const { isUserAllowedToNavigate } = useAuthorization();
  return (
    <Route
      path={route.path}
      exact={route.exact}
      render={props =>
        isUserAllowedToNavigate(route.path) ? (
          <route.component {...props} {...route} />
        ) : (
          <Redirect
            to={{
              pathname: LOGIN_PATHNAME,
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
}

export default RouteWithSubRoutes;
