import React, { useState, useEffect, useCallback, useMemo } from "react";
import DialogBody from "../shared/components/snackbar/components/DialogBody";
import Api from "../http/Api";

const DialogContext = React.createContext();

function useDialog() {
  const context = React.useContext(DialogContext);
  if (!context) {
    throw new Error(
      "[COCO STATE ERROR] useDialog must be used within a DialogProvider"
    );
  }
  return context;
}

function DialogProvider(props) {
  const [dialog, setDialog] = useState({
    title: "dialog Title",
    open: false,
    body: "test",
    onClose: () => { },
    isLoading: false,
    Icon: undefined,
    buttonLabel: "dialog button",
    dialogAction: () => { },
    showInfoLabel: false,
    showIcon: false,
    color: "green",
    infoLabel: "",
  });

  const openDialog = useCallback(currentDialog => {
    setDialog({
      ...currentDialog,
      open: true,
    });
  }, []);

  const closeDialog = () => setDialog({
    ...dialog,
    open: false,
  });

  const initState = {
    dialog,
    openDialog,
    closeDialog,
    ...props.store,
  };

  const state = useMemo(() => ({...initState }), [initState]);

  useEffect(() => {
    Api.showBackendErrorDialog = additionalDetails => {
      const additionalDetailsDialog = {
        title: "Conflicts",
        open: false,
        onClose: () => {},
        isLoading: false,
        showIcon: true,
        showInfoLabel: false,
        color: "primary",
        infoLabel: "",
        BodyComponent: DialogBody,
        bodyProps: {
          content: additionalDetails,
        },
      };
      openDialog(additionalDetailsDialog);
    };
  }, [openDialog]);

  return <DialogContext.Provider value={state} {...props} />;
}

export { DialogProvider, useDialog };
