const urlError = "Invalid URL pattern";
//eslint-disable-next-line
const urlRegex = /^([a-zA-Z\d][a-zA-Z\d+\-.]{0,2000}):\/\/[/:?=#&%@[\]\w\-.~!$'()*+,;]{1,65535}$/;

export default function validateURL(url, _params = false) {
  if (!url) {
    return null;
  }

  const validURLRegex = urlRegex.test(String(url).toLowerCase());

  if (!validURLRegex) {
    return urlError;
  }

  return null;
}
