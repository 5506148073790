import React from "react";
import { withStyles, Toolbar, Typography, IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import Tooltip from "../../tooltip/Tooltip";

function SelectedRowsToolbar({
  classes,
  selectedRows,
  name,
  deleteRows,
  setOpenEditRow
}) {
  const title = `${selectedRows.length} selected`;
  return (
    <Toolbar className={classes.root}>
      <div className={classes.title}>
        {selectedRows.length > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {title}
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
            {name}
          </Typography>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {selectedRows.length > 0 ? (
          <Tooltip title="Delete">
            <IconButton
              aria-label="Delete"
              onClick={() => deleteRows(selectedRows)}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <div>
            <Tooltip title="Add Item">
              <IconButton
                className={classes.editIcon}
                aria-label="Add Item"
                onClick={() =>
                  setOpenEditRow({
                    open: true,
                    isNewRow: true,
                    row: {}
                  })
                }
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
          </div>
        )}
      </div>
    </Toolbar>
  );
}

const styles = theme => ({
  root: {
    paddingRight: theme.spacing(1)
  },
  editIcon: {
    marginRight: "6px"
  },
  spacer: {
    flex: "1 1 100%"
  },
  actions: {
    flexShrink: 0,
    color: theme.palette.text.secondary
  },
  title: {
    flex: "0 0 auto"
  }
});

export default withStyles(styles)(SelectedRowsToolbar);
