import React, { useState, useEffect } from "react";
import get from "lodash/get";
import makeStyles from "@material-ui/core/styles/makeStyles";
import MediaGallery from "../../media-gallery/MediaGallery";
import Selector from "../../selector/Selector";
import { getMediaCategories, getCategoryDetails } from "../../../../http/media";
import Loader from "../../loader/Loader";
import {createConsoleError, getUrlParam} from "../../../utils/utils";

function DialogBody({ application, selectImage, imageSelected }) {
  const [category, setCategory] = useState({});
  const [assets, setAssets] = useState([]);
  const [categoriesOptions, setCategoriesOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isAssetsLoading, setIsAssetsLoading] = useState(false);

  const selectedImages = [imageSelected];
  const selectedCountry = getUrlParam("country");

  useEffect(() => {
    async function getCategories() {
      setIsLoading(true);
      try {
        const { data: categories } = await getMediaCategories(application);
        setCategoriesOptions(
          categories.map(category => ({
            label: category.name,
            value: category._id,
            slug: category.slug,
          }))
        );
        const catIndex = categories.findIndex(cat => cat.name === selectedCountry);
        setCategory({
          id: get(categories[catIndex], "_id"),
          slug: get(categories[catIndex], "slug"),
        });
      } catch (error) {
        createConsoleError("Error", error);
      }
      setIsLoading(false);
    }
    getCategories();
  }, [application, selectedCountry]);

  useEffect(() => {
    async function getAssets() {
      setIsAssetsLoading(true);
      try {
        const { data } = await getCategoryDetails(category.id);
        setAssets(data);
      } catch (error) {
        createConsoleError("Error", error);
      }
      setIsAssetsLoading(false);
    }

    if (category.id) {
      getAssets();
    } else {
      setAssets([]);
    }
  }, [category]);

  const hasCategories = categoriesOptions.length > 0;

  const maxHeight = window.innerHeight - 400;

  const classes = useStyles({ maxHeight });

  return (
    <Loader isLoading={isLoading} minHeight={300}>
      <div className={classes.categorySelectorContainer}>
        <Selector
          id={"category-selector"}
          options={categoriesOptions}
          label={"Category"}
          disabled={!hasCategories}
          name={"category-selector"}
          onChange={value => {
            const categoryId = get(value, "value", undefined);
            const categorySlug = get(
              categoriesOptions.find(category => category.value === categoryId),
              "slug"
            );
            setCategory({
              id: categoryId,
              slug: categorySlug,
            });
          }}
          value={category.id}
        />
      </div>
      <Loader isLoading={isAssetsLoading} minHeight={300}>
        <MediaGallery
          assets={assets}
          maxHeight={maxHeight}
          selectedImages={selectedImages}
          onClickImage={asset => selectImage(asset, category)}
        />
      </Loader>
    </Loader>
  );
}

const useStyles = makeStyles(_theme => ({
  categorySelectorContainer: {
    padding: "0px 16px",
  },
}));

export default DialogBody;
