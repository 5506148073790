import React from "react";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(_theme => ({
  container: {
    minWidth: "500px",
  },
}));

function DialogBody({ application, country, version, _content }) {
  const classes = useStyles({});
  return (
    <div className={classes.container}>
      <Typography>{`Application: ${application}`}</Typography>
      {country && <Typography>{`Country: ${country}`}</Typography>}
      {version && <Typography>{`Version: ${version}`}</Typography>}
    </div>
  );
}

export default DialogBody;
