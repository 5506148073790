import React from "react";
import { makeStyles } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import Paper from "@material-ui/core/Paper";
import TablePagination from "./components/TablePagination";
import SelectedRowsToolbar from "./components/SelectedRowsToolbar";
import TableHeader from "./components/TableHeader";
import TableBody from "./components/TableBody";
import EditRowDialog from "./components/EditRowDialog";
import useTableField from "./hooks/useTableField";

function TableField({
  id,
  field,
  fields,
  input: { onChange, value, ...restInput },
  ...props
}) {
  const {
    columns,
    rows,
    rowsPerPage,
    setRowsPerPage,
    page,
    setPage,
    selectedRows,
    setSelectedRows,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    openEditRow,
    setOpenEditRow,
    createNewRow,
    editRow,
    deleteRows,
    itemsShape,
  } = useTableField(field, fields, value, onChange);

  const classes = useStyles();
  return (
    <React.Fragment>
      <Paper className={classes.root}>
        <SelectedRowsToolbar
          deleteRows={deleteRows}
          name={field.label}
          selectedRows={selectedRows}
          setOpenEditRow={setOpenEditRow}
        />
        <div className={classes.table}>
          <Table id={id}>
            <TableHeader
              setSelectedRows={setSelectedRows}
              selectedRows={selectedRows}
              order={order}
              orderBy={orderBy}
              setOrder={setOrder}
              setOrderBy={setOrderBy}
              columns={columns}
              rows={rows}
            />
            <TableBody
              setSelectedRows={setSelectedRows}
              selectedRows={selectedRows}
              order={order}
              orderBy={orderBy}
              setOrder={setOrder}
              setOrderBy={setOrderBy}
              columns={columns}
              rows={rows}
              deleteRows={deleteRows}
              rowsPerPage={rowsPerPage}
              page={page}
              openEditRow={openEditRow}
              setOpenEditRow={setOpenEditRow}
            />
          </Table>
        </div>
        <TablePagination
          rows={rows}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          page={page}
          setPage={setPage}
        />
      </Paper>
      <EditRowDialog
        openEditRow={openEditRow}
        setOpenEditRow={setOpenEditRow}
        itemsShape={itemsShape}
        editRow={editRow}
        createNewRow={createNewRow}
      />
    </React.Fragment>
  );
}
const useStyles = makeStyles(_theme => ({
  root: {
    width: "100%",
  },
  table: {
    width: "100%",
    overflowX: "auto",
  },
}));

export default TableField;
