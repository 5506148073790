// Content Actions
export const SAVE_DRAFT_CONTENT_ACTION = "SAVE_DRAFT_CONTENT_ACTION";
export const DELETE_DRAFT_CONTENT_ACTION = "DELETE_DRAFT_CONTENT_ACTION";
export const CLEAR_DRAFT_CONTENT_ACTION = "CLEAR_DRAFT_CONTENT_ACTION";
export const ADD_CONTENT_ACTION = "ADD_CONTENT_ACTION";
export const PUBLISH_CONTENT_ACTION = "PUBLISH_CONTENT_ACTION";
export const VIEW_CONTENT_SECRET_VARIABLE_ACTION = "VIEW_CONTENT_SECRET_VARIABLE_ACTION";

// Core Modules Content Actions
export const CORE_MODULES_SAVE_DRAFT_CONTENT_ACTION =
  "CORE_MODULES_SAVE_DRAFT_CONTENT_ACTION";
export const CORE_MODULES_DELETE_DRAFT_CONTENT_ACTION =
  "CORE_MODULES_DELETE_DRAFT_CONTENT_ACTION";
export const CORE_MODULES_CLEAR_DRAFT_CONTENT_ACTION =
  "CORE_MODULES_CLEAR_DRAFT_CONTENT_ACTION";
export const CORE_MODULES_ADD_CONTENT_ACTION =
  "CORE_MODULES_ADD_CONTENT_ACTION";
export const CORE_MODULES_PUBLISH_CONTENT_ACTION =
  "CORE_MODULES_PUBLISH_CONTENT_ACTION";

// Configuration Actions
export const ATTACH_COUNTRY_ACTION = "ATTACH_COUNTRY_ACTION";
export const ADD_VERSION_ACTION = "ADD_VERSION_ACTION";
export const FORCE_UPGRADE_ACTION = "FORCE_UPGRADE_ACTION";
export const CLONE_VERSION_ACTION = "CLONE_VERSION_ACTION";
export const EXPORT_VERSION_ACTION = "EXPORT_VERSION_ACTION";
export const IMPORT_VERSION_ACTION = "IMPORT_VERSION_ACTION";
export const REMOVE_VERSION_ACTION = "REMOVE_VERSION_ACTION";

// tasks Actions
export const SHOW_PUBLICATION_WIDGET_ACTION = "SHOW_PUBLICATION_WIDGET_ACTION";

// User Actions
export const CREATE_USERS_ACTION = "CREATE_USERS_ACTION";
export const MANAGE_USERS_ACTION = "MANAGE_USERS_ACTION";
export const GET_USERS_ACTION = "GET_USERS_ACTION";

// Media Actions
export const ADD_CATEGORY_ACTION = "ADD_CATEGORY_ACTION";
export const ADD_ASSET_ACTION = "ADD_ASSET_ACTION";
export const DELETE_CATEGORY_ACTION = "DELETE_CATEGORY_ACTION";
export const DELETE_ASSET_ACTION = "DELETE_ASSET_ACTION";

export const READER_ROLE = "reader";
export const EDITOR_ROLE = "editor";
export const PUBLISHER_ROLE = "publisher";
export const ADMIN_ROLE = "admin";
export const AUDITOR_ROLE = "auditor";
export const SUPER_ADMIN_ROLE = "superadmin";
export const MODERATOR_ROLE = "moderator";

export const ALL_USER_ROLES = [
  READER_ROLE,
  EDITOR_ROLE,
  PUBLISHER_ROLE,
  ADMIN_ROLE,
  AUDITOR_ROLE,
  SUPER_ADMIN_ROLE,
  MODERATOR_ROLE,
];

const READER_ALLOWED_ACTIONS = {};

const EDITOR_ALLOWED_ACTIONS = {
  ...READER_ALLOWED_ACTIONS,
  SAVE_DRAFT_CONTENT_ACTION,
  DELETE_DRAFT_CONTENT_ACTION,
  CLEAR_DRAFT_CONTENT_ACTION,
  ADD_CONTENT_ACTION,
  VIEW_CONTENT_SECRET_VARIABLE_ACTION,
  ADD_CATEGORY_ACTION,
  ADD_ASSET_ACTION,
};

const PUBLISHER_ACTIONS = {
  ...EDITOR_ALLOWED_ACTIONS,
  PUBLISH_CONTENT_ACTION,
  SHOW_PUBLICATION_WIDGET_ACTION,
  DELETE_CATEGORY_ACTION,
  DELETE_ASSET_ACTION,
};

const ADMIN_ALLOWED_ACTIONS = {
  ...PUBLISHER_ACTIONS,
  CREATE_USERS_ACTION,
  MANAGE_USERS_ACTION,
  GET_USERS_ACTION,
  ATTACH_COUNTRY_ACTION,
  ADD_VERSION_ACTION,
  FORCE_UPGRADE_ACTION,
  CLONE_VERSION_ACTION,
  EXPORT_VERSION_ACTION,
  IMPORT_VERSION_ACTION,
  REMOVE_VERSION_ACTION
};

const AUDITOR_ALLOWED_ACTIONS = {};

const MODERATOR_ALLOWED_ACTIONS = {
  CREATE_USERS_ACTION,
  MANAGE_USERS_ACTION,
  GET_USERS_ACTION,
};

const ACTIONS_BY_ROLE = {
  [READER_ROLE]: READER_ALLOWED_ACTIONS,
  [EDITOR_ROLE]: EDITOR_ALLOWED_ACTIONS,
  [PUBLISHER_ROLE]: PUBLISHER_ACTIONS,
  [ADMIN_ROLE]: ADMIN_ALLOWED_ACTIONS,
  [AUDITOR_ROLE]: AUDITOR_ALLOWED_ACTIONS,
  [MODERATOR_ROLE]: MODERATOR_ALLOWED_ACTIONS,
};

export default function isUserRoleAllowedTo(roles, action) {
  if (!roles) {
    return false;
  }

  if (roles.some(role => role === SUPER_ADMIN_ROLE)) {
    return true;
  }
  return roles.some(role => !!ACTIONS_BY_ROLE[role][action]);
}
