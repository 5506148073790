import React from "react";
import every from "lodash/every";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

function Selector({
  id,
  options,
  value,
  helperText,
  error,
  label,
  disabledLabel,
  onChange,
  dependencies,
  required,
  disabled,
  autoComplete = "off",
}) {
  const isDisabled = checkDependencies(dependencies) || disabled;

  const classes = useStyles({});

  return (
    <Autocomplete
      id={id}
      value={getOption(value, options)}
      onChange={(_event, newOption) => {
        onChange(newOption);
      }}
      options={options}
      disabled={isDisabled}
      blurOnSelect
      required={required}
      openOnFocus
      clearOnEscape
      autoHighlight
      getOptionLabel={option => option.label || ""}
      renderOption={option => (
        <React.Fragment>
          {option.icon && (
            <span className={classes.iconLabel}>
              {React.isValidElement(option.icon) ? (
                option.icon
              ) : (
                <option.icon className={classes.icon} />
              )}
            </span>
          )}
          {option.customLabel || option.label}
        </React.Fragment>
      )}
      renderInput={params => (
        <TextField
          {...params}
          autoComplete="off"
          fullWidth
          required={required}
          helperText={helperText}
          error={error}
          label={label}
          placeholder={getPlaceholder(isDisabled, disabledLabel)}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            ...params.InputProps,
            required,
            autoComplete,
          }}
        />
      )}
    />
  );
}

export default Selector;

function getPlaceholder(isDisabled, disabledLabel) {
  if (isDisabled) {
    return disabledLabel;
  }
  return "Choose an option...";
}

function checkDependencies(dependencies) {
  return dependencies && !every(dependencies, null);
}

function getOption(value, options = []) {
  return options.find(option => option.value === value) || null;
}

const useStyles = makeStyles(_theme => ({
  iconLabel: {
    marginRight: 8,
    marginBottom: -3,
  },
  icon: {
    width: "15px !important",
    height: "15px !important",
  },
}));
