import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import Tooltip from "../tooltip/Tooltip";

function HelpIcon({
  helpText,
  colorIcon,
  colorIconHover,
  _fontSize,
}) {
  const classes = useStyles({ colorIcon, colorIconHover });

  return (
    <Tooltip title={helpText} placement='top'>
      <HelpOutlineIcon
        className={classes.icon}
      />
    </Tooltip>
  );
}

const useStyles = makeStyles(_theme => ({
  icon: {
    color: props => props.colorIcon,
    overflow: "visible",
    textAlign: "center",
    verticalAlign: "bottom",
    margin: "0 6px",
    borderRadius: "50%",
    boxShadow: "none",
    fontSize: props => props.fontSize,
    "&:hover": {
      color: props => props.colorIconHover,
    },
  },
  tooltip: {
    fontSize: "1rem",
  },
}));

export default HelpIcon;
