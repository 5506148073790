import React from "react";
import PropTypes from "prop-types";
import CssBaseline from "@material-ui/core/CssBaseline";
import { SnackbarProvider } from "./snackbarContext";
import { DialogProvider } from "./dialogContext";
import { AuthorizationProvider } from "./authorizationContext";
import Snackbar from "../shared/components/snackbar/Snackbar";
import Dialog from "../shared/components/dialog/Dialog";
import { ThemeProvider } from "./themeContext";

function Providers({ children, store = {} }) {
  return (
    <ThemeProvider store={store.theme}>
      <CssBaseline />
      <SnackbarProvider store={store.snackbar}>
        <DialogProvider store={store.dialog}>
          <AuthorizationProvider store={store.authorization}>
            {children}
            <Snackbar />
            <Dialog />
          </AuthorizationProvider>
        </DialogProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
}
Providers.propTypes = {
  children: PropTypes.node.isRequired,
  store: PropTypes.object
};

export default Providers;
