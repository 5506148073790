import React, { useState, useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { RED } from "../../styles/theme";
import Loader from "../loader/Loader";
import { useTheme } from "../../../store/themeContext";
import { docToHtml, htmlToDoc } from "../../../http/config";
import { useSnackbar } from "../../../store/snackbarContext";
import {
  UPLOAD_DOCUMENT_ERROR,
  UPLOAD_DOCUMENT_SUCCESS,
  DOWNLOAD_DOCUMENT_ERROR,
  DOWNLOAD_DOCUMENT_SUCCESS,
} from "../snackbar/snackbars";
import { generateFile } from "../../utils/utils";

const RICH_TEXT_KEY = process.env.REACT_APP_RICH_TEXT_KEY;

function RichTextField({
  input: { onChange, value },
  label,
  meta,
  required,
  id,
  disabled,
}) {
  const [isLoading, setIsLoading] = useState(true);

  const inputFileRef = useRef(null);

  const helperText = meta.touched ? meta.error : undefined;
  const hasError = meta.error && meta.touched;

  const classes = useStyles({ hasError });

  const { isDarkModeEnabled } = useTheme();

  const { openSnackbar } = useSnackbar();

  function uploadDoc(event) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = async () => {
      try {
        const response = await docToHtml(reader.result);
        const content = response.data.content;
        onChange(content);
        openSnackbar(UPLOAD_DOCUMENT_SUCCESS);
      } catch (error) {
        openSnackbar(UPLOAD_DOCUMENT_ERROR);
      }
    };
    reader.readAsArrayBuffer(file);
  }

  async function saveToDoc(html) {
    if (!html) {
      return;
    }

    const searchParams = new URLSearchParams(window.location.search);

    try {
      const response = await htmlToDoc(html);
      generateFile(
        response,
        `${searchParams.get("application")}-${searchParams.get(
          "country"
        )}-${searchParams.get("version")}-${searchParams.get(
          "module"
        )}.docx`.toLowerCase(),
        "text/vnd.openxmlformats-officedocument.wordprocessingml.document"
      );
      openSnackbar(DOWNLOAD_DOCUMENT_SUCCESS);
    } catch {
      openSnackbar(DOWNLOAD_DOCUMENT_ERROR);
    }
  }

  return (
    <FormControl component="fieldset" className={classes.root}>
      <FormLabel
        filled
        focused={false}
        required={required}
        error={hasError}
        className={classes.label}
      >
        {label}
      </FormLabel>
      <Loader isLoading={isLoading} minHeight={300}>
        <input
          type="file"
          id={`${id}-upload-document`}
          onChange={uploadDoc}
          ref={inputFileRef}
          className={classes.hiddenUploadInput}
        />
        <div className={classes.container}>
          <Editor
            apiKey={RICH_TEXT_KEY}
            id={id}
            disabled={disabled}
            value={value}
            init={{
              content_css: isDarkModeEnabled ? "dark" : "",
              skin: isDarkModeEnabled ? "oxide-dark" : "",
              setup: function (editor) {
                editor.on("ScriptsLoaded", function (_event) {
                  setIsLoading(false);
                });
                editor.ui.registry.addButton("import", {
                  icon: "plus",
                  tooltip: "Import Doc File",
                  onAction: () => {
                    inputFileRef.current.click();
                  },
                });
                editor.ui.registry.addButton("export", {
                  icon: "save",
                  tooltip: "Export to doc File",
                  onAction: _event => {
                    saveToDoc(editor.getContent());
                  },
                });
              },
              min_height: 500,
              menu: RICH_TEXT_MENU,
              menubar: true,
              plugins: RICH_TEXT_PLUGINS,
              toolbar: RICH_TEXT_TOOLBAR,
            }}
            onEditorChange={onChange}
          />
        </div>
      </Loader>
      <FormHelperText error={hasError}>{helperText}</FormHelperText>
    </FormControl>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  },
  label: {
    transform: "translate(0, 1.5px) scale(0.75)",
    transformOrigin: "top left",
    marginBottom: "6px",
  },
  container: props => ({
    border: `1px solid ${props.hasError ? RED : theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    transition: "border .24s ease-in-out",
  }),
  hiddenUploadInput: {
    display: "none",
  },
}));

const RICH_TEXT_MENU = {
  file: {
    title: "File",
    items: "newdocument restoredraft | preview | print ",
  },
  edit: {
    title: "Edit",
    items: "undo redo | cut copy paste | selectall | searchreplace",
  },
  view: {
    title: "View",
    items: "code | visualaid visualchars visualblocks | spellchecker | preview",
  },
  insert: {
    title: "Insert",
    items:
      "image link media template codesample inserttable | charmap emoticons hr | pagebreak nonbreaking anchor toc | insertdatetime",
  },
  format: {
    title: "Format",
    items:
      "bold italic underline strikethrough superscript subscript codeformat | formats blockformats fontformats fontsizes align | forecolor backcolor | removeformat",
  },
  tools: {
    title: "Tools",
    items: "spellchecker spellcheckerlanguage | code wordcount",
  },
  table: {
    title: "Table",
    items: "inserttable | cell row column | tableprops deletetable",
  },
  help: { title: "Help", items: "help" },
};

const RICH_TEXT_PLUGINS = [
  "advlist autolink lists link image charmap print preview anchor",
  "searchreplace visualblocks code fullscreen autoresize",
  "insertdatetime media table paste code help wordcount",
];
const RICH_TEXT_TOOLBAR =
  "import export | undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help";

export default RichTextField;
