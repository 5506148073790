import React from "react";
import { ReactGhLikeDiff } from "react-gh-like-diff";
import "react-gh-like-diff/dist/css/diff2html.min.css";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { objectToText } from "../../../utils/utils";

function DialogBodyDiff({ past, current, buttonLabel }) {
  const isDesktop = useMediaQuery("(min-width:768px)");
  return (
    <ReactGhLikeDiff
      options={{
        originalFileName: buttonLabel,
        updatedFileName: buttonLabel,
        drawFileList: false,
        showFiles: false,
        outputFormat: !isDesktop ? "line-by-line" : "side-by-side"
      }}
      past={objectToText(past)}
      current={objectToText(current)}
    />
  );
}

export default DialogBodyDiff;
