import validateField from "./validateField";
import get from "lodash/get";

export default function validateObject(values, params, _type, scope) {
  const errors = {};
  const fields = params.shape;

  Object.keys(fields).forEach(fieldSlug => {
    const field = {
      validators: [],
      ...fields[fieldSlug],
    };
    const value = get(values, fieldSlug);
    const error = validateField(scope, field)(value);

    if (error) {
      errors[fieldSlug] = error;
    }
  });

  return errors;
}
