export const SUCCESS_VARIANT_TYPE = "success";
export const WARNING_VARIANT_TYPE = "warning";
export const ERROR_VARIANT_TYPE = "error";
export const INFO_VARIANT_TYPE = "info";

// get users request fails
export const GET_USERS_ERROR = {
  message: "Error on getting users from backend",
  variant: ERROR_VARIANT_TYPE
};

// Copy to clipboard
export const COPY_TO_CLIPBOARD_SUCCESS = {
  message: "Copied to clipboard!",
  variant: SUCCESS_VARIANT_TYPE
};

// Error on load table data
export const GET_TABLE_DATA_ERROR = {
  message: "Failed to fetch table data!",
  variant: ERROR_VARIANT_TYPE
};

// Error on load audits data
export const GET_AUDITS_DATA_ERROR = {
  message: "Failed to fetch audit data!",
  variant: ERROR_VARIANT_TYPE
};

// Error on load audits data
export const GET_TASKS_DATA_ERROR = {
  message: "Failed to fetch Tasks data!",
  variant: ERROR_VARIANT_TYPE
};

// Upload new user avatar
export const UPLOAD_USER_AVATAR_SUCCESS = {
  message: "User Avatar updated!",
  variant: SUCCESS_VARIANT_TYPE
};

export const UPLOAD_USER_AVATAR_ERROR = {
  message: "Failed to upload user avatar",
  variant: ERROR_VARIANT_TYPE
};

// Update user profile
export const UPDATE_USER_PROFILE_SUCCESS = {
  message: "User profile updated!",
  variant: SUCCESS_VARIANT_TYPE
};

export const UPDATE_USER_PROFILE_ERROR = {
  message: "Error on user profile update",
  variant: ERROR_VARIANT_TYPE
};

// Edit User
export const CHANGE_USER_ROLE_SUCCESS = {
  message: "User role changed!",
  variant: SUCCESS_VARIANT_TYPE
};

export const CHANGE_USER_ROLE_ERROR = {
  message: "Failed to change user role!",
  variant: ERROR_VARIANT_TYPE
};

export const CHANGE_USER_PERMISSIONS_SUCCESS = {
  message: "User permissions changed!",
  variant: SUCCESS_VARIANT_TYPE
};

export const CHANGE_USER_PERMISSIONS_ERROR = {
  message: "Failed to change user permissions!",
  variant: ERROR_VARIANT_TYPE
};

export const DISABLED_USER_SUCCESS = {
  message: "User disabled!",
  variant: SUCCESS_VARIANT_TYPE
};

export const DISABLED_USER_ERROR = {
  message: "Failed to disable user!",
  variant: ERROR_VARIANT_TYPE
};

export const RESTORE_USER_SUCCESS = {
  message: "User restored!",
  variant: SUCCESS_VARIANT_TYPE
};

export const RESTORE_USER_ERROR = {
  message: "Failed to restore user!",
  variant: ERROR_VARIANT_TYPE
};

// delete asset
export const DELETE_ASSET_SUCCESS = {
  message: "Asset deleted!",
  variant: SUCCESS_VARIANT_TYPE
};

export const DELETE_ASSET_ERROR = {
  message: "Failed to delete asset!",
  variant: ERROR_VARIANT_TYPE
};

// Login
export const LOGIN_REQUEST_ERROR = {
  message: "Failed to login!",
  variant: ERROR_VARIANT_TYPE
};

export const LOGIN_REQUEST_SUCCESS = {
  message: "Successfully logged in!",
  variant: SUCCESS_VARIANT_TYPE
};

// Clone Version
export const CLONE_VERSION_REQUEST_ERROR = {
  message: "Failed to clone version!",
  variant: ERROR_VARIANT_TYPE
};

// upload asset validation error (max limit)
export const UPLOAD_ASSET_LIMIT_ERROR = {
  message: "Upload error! Only a max of 30 assets allowed",
  variant: ERROR_VARIANT_TYPE
};

// upload asset validation error (format error)
export const UPLOAD_ASSET_FORMAT_ERROR = {
  message: "File type error on asset upload!",
  variant: ERROR_VARIANT_TYPE
};

export const CLONE_VERSION_REQUEST_SUCCESS = {
  message: "Version cloning Task created!",
  variant: SUCCESS_VARIANT_TYPE
};

// upload document Error
export const UPLOAD_DOCUMENT_ERROR = {
  message: "File type error! Only Word format allowed",
  variant: ERROR_VARIANT_TYPE
};

export const UPLOAD_DOCUMENT_SUCCESS = {
  message: "Document uploaded!",
  variant: SUCCESS_VARIANT_TYPE
};

// upload document Error
export const DOWNLOAD_DOCUMENT_ERROR = {
  message: "Error on document download",
  variant: ERROR_VARIANT_TYPE
};

export const DOWNLOAD_DOCUMENT_SUCCESS = {
  message: "Document downloaded! Check your downloads folder to find it",
  variant: SUCCESS_VARIANT_TYPE
};

// Import Version
export const IMPORT_VERSION_REQUEST_ERROR = {
  message: "Failed to Import Version!",
  variant: ERROR_VARIANT_TYPE
};

export const IMPORT_VERSION_REQUEST_SUCCESS = {
  message: "Version successfully imported!",
  variant: SUCCESS_VARIANT_TYPE
};

// Remove Version
export const REMOVE_VERSION_REQUEST_ERROR = {
  message: "Failed to Remove Version!",
  variant: ERROR_VARIANT_TYPE
};

export const REMOVE_VERSION_REQUEST_SUCCESS = {
  message: "Version removal task created",
  variant: SUCCESS_VARIANT_TYPE
};

// Attach Country
export const ATTACH_COUNTRIES_REQUEST_ERROR = {
  message: "Failed to Attach countries!",
  variant: ERROR_VARIANT_TYPE
};

export const ATTACH_COUNTRIES_REQUEST_SUCCESS = {
  message: "Countries successfully attached!",
  variant: SUCCESS_VARIANT_TYPE
};

// Add Version
export const ADD_VERSION_REQUEST_ERROR = {
  message: "Failed to Add Version!",
  variant: ERROR_VARIANT_TYPE
};

export const ADD_VERSIONS_REQUEST_SUCCESS = {
  message: "Version successfully added!",
  variant: SUCCESS_VARIANT_TYPE
};

// Force Upgrade
export const FORCE_UPGRADE_REQUEST_ERROR = {
  message: "Force upgrade error! Please retry failed countries",
  variant: ERROR_VARIANT_TYPE
};

export const FORCE_UPGRADE_REQUEST_SUCCESS = {
  message: "Force upgrade in progress!",
  variant: SUCCESS_VARIANT_TYPE
};

// Forgot Password
export const FORGOT_PASSWORD_REQUEST_ERROR = {
  message: "Forgot password Error!",
  variant: ERROR_VARIANT_TYPE
};

export const FORGOT_PASSWORD_REQUEST_SUCCESS = {
  message: "Check your Email!",
  variant: SUCCESS_VARIANT_TYPE
};

// Reset Password
export const RESET_PASSWORD_REQUEST_ERROR = {
  message: "Failed to reset password!",
  variant: ERROR_VARIANT_TYPE
};

export const RESET_PASSWORD_REQUEST_SUCCESS = {
  message: "Success on reset password!",
  variant: SUCCESS_VARIANT_TYPE
};

// Activation Account
export const ACTIVATION_ACCOUNT_REQUEST_ERROR = {
  message: "Failed to activate account!",
  variant: ERROR_VARIANT_TYPE
};

export const ACTIVATION_ACCOUNT_REQUEST_SUCCESS = {
  message: "Successful account activation!",
  variant: SUCCESS_VARIANT_TYPE
};

// Logout
export const LOGOUT_REQUEST_ERROR = {
  message: "Failed to logout!",
  variant: ERROR_VARIANT_TYPE
};

export const LOGOUT_REQUEST_SUCCESS = {
  message: "Successfully logged out!",
  variant: SUCCESS_VARIANT_TYPE
};

// Register
export const REGISTER_REQUEST_ERROR = {
  message: "Failed to register a new user",
  variant: ERROR_VARIANT_TYPE
};

export const REGISTER_REQUEST_SUCCESS = {
  message: "User successfully registered!",
  variant: SUCCESS_VARIANT_TYPE
};

// Media Categories
export const CREATE_MEDIA_CATEGORY_ERROR = {
  message: "Failed to create media category!",
  variant: ERROR_VARIANT_TYPE
};

export const CREATE_MEDIA_CATEGORY_SUCCESS = {
  message: "Media category successfully created!",
  variant: SUCCESS_VARIANT_TYPE
};

export const DELETE_MEDIA_CATEGORY_ERROR = {
  message: "Failed to delete media category!",
  variant: ERROR_VARIANT_TYPE
};

export const DELETE_MEDIA_CATEGORY_SUCCESS = {
  message: "Media category successfully deleted!",
  variant: SUCCESS_VARIANT_TYPE
};

// Config
export const CONFIG_LOADED_SUCCESS = {
  message: "Configuration loaded!",
  variant: SUCCESS_VARIANT_TYPE
};

export const CONFIG_LOADED_ERROR = {
  message: "Error on configuration load",
  variant: ERROR_VARIANT_TYPE
};

export const GET_CONTENT_ERROR = {
  message: "Error fetching Contents!",
  variant: ERROR_VARIANT_TYPE
};

export const CONTENT_DELETED_SUCCESS = {
  message: "Drafted content deleted",
  variant: SUCCESS_VARIANT_TYPE
};

export const CONTENT_DELETED_ERROR = {
  message: "Failed to delete drafted content",
  variant: ERROR_VARIANT_TYPE
};

export const CONTENT_MARKED_AS_DELETED_SUCCESS = {
  message: "Content marked to be deleted",
  variant: SUCCESS_VARIANT_TYPE
};

export const CONTENT_MARKED_AS_DELETED_ERROR = {
  message: "Failed to mark content to be deleted",
  variant: ERROR_VARIANT_TYPE
};

export const CONTENT_SAVED_SUCCESS = {
  message: "Content saved!",
  variant: SUCCESS_VARIANT_TYPE
};

export const CONTENT_SAVED_ERROR = {
  message: "Failed to save content",
  variant: ERROR_VARIANT_TYPE
};

export const UNPUBLISH_CONTENT_SUCCESS = {
  message: "Unpublication task created!",
  variant: SUCCESS_VARIANT_TYPE
};

export const UNPUBLISH_CONTENT_ERROR = {
  message: "Failed to unpublish content!",
  variant: ERROR_VARIANT_TYPE
};

export const PUBLISH_CONTENT_SUCCESS = {
  message: "Publication task created!",
  variant: SUCCESS_VARIANT_TYPE
};

export const PUBLISH_CONTENT_ERROR = {
  message: "Failed to publish content!",
  variant: ERROR_VARIANT_TYPE
};

export const UPDATE_JSON_SCHEMA_SUCCESS = {
  message: "JSON Schema updated!",
  variant: SUCCESS_VARIANT_TYPE
};

export const UPDATE_JSON_SCHEMA_ERROR = {
  message: "Error on JSON Schema update!",
  variant: ERROR_VARIANT_TYPE
};

export const CHANGE_PASSWORD_SUCCESS = {
  message: "Password updated!",
  variant: SUCCESS_VARIANT_TYPE
};

export const CHANGE_PASSWORD_ERROR = {
  message: "Failed to change password!",
  variant: ERROR_VARIANT_TYPE
};

export const GET_PUBLIC_CONFIGURATION_ERROR = {
  message: "Failed to load Public Configuration!",
  variant: ERROR_VARIANT_TYPE
};

export const GET_PUBLIC_FAQS_ERROR = {
  message: "Failed to load FAQs!",
  variant: ERROR_VARIANT_TYPE
};

export const GET_PUBLIC_TCPP_ERROR = {
  message: "Failed to load Privacy Policy and Terms & Conditions!",
  variant: ERROR_VARIANT_TYPE
};

export const GET_PUBLIC_STATIC_ERROR = {
  message: "Failed to load Static Configuration!",
  variant: ERROR_VARIANT_TYPE
};

export const UNDO_DELETE_CONTENT_SUCCESS = {
  message: "Success on Undo Content Delete!",
  variant: SUCCESS_VARIANT_TYPE
};

export const UNDO_DELETE_CONTENT_ERROR = {
  message: "Failed to Undo Content Delete!",
  variant: ERROR_VARIANT_TYPE
};

export const DELETE_MULTIPLE_ASSETS_SUCCESS = {
  message: "Assets successfully deleted!",
  variant: SUCCESS_VARIANT_TYPE
};

export const DELETE_MULTIPLE_ASSETS_ERROR = {
  message: "Failed to delete Assets!",
  variant: ERROR_VARIANT_TYPE
};

export const GET_ENV_DIFF_FILE_ERROR = {
  message: "Failed to download Diff file!",
  variant: ERROR_VARIANT_TYPE
};

export const GET_ENV_DIFF_FILE_SUCCESS = {
  message: "File Downloaded!",
  variant: SUCCESS_VARIANT_TYPE
};

export const CREATE_TAG_VERSION_ERROR = {
  message: "Failed to create tagged version!",
  variant: ERROR_VARIANT_TYPE
};

export const CREATE_TAG_VERSION_SUCCESS = {
  message: "Tagged version created!",
  variant: SUCCESS_VARIANT_TYPE
};

export const REPORT_FIELD_VALUE_ERROR = {
  message: "Failed to create field value report!",
  variant: ERROR_VARIANT_TYPE
};

export const REPORT_FIELD_VALUE_SUCCESS = {
  message: "Field value report created!",
  variant: SUCCESS_VARIANT_TYPE
};

export const GET_MODULES_ERROR = {
  message: "Error on getting modules from backend",
  variant: ERROR_VARIANT_TYPE
};

export const GET_VERSIONS_ERROR = {
  message: "Error on getting minimum versions from backend",
  variant: ERROR_VARIANT_TYPE
};

export const GET_PLATFORMS_ERROR = {
  message: "This application has not an available OS platforms",
  variant: ERROR_VARIANT_TYPE
};

// Retry Task
export const RETRY_TASK_REQUEST_ERROR = {
  message: "Retry task error! Please retry again",
  variant: ERROR_VARIANT_TYPE
};

export const RETRY_TASK_REQUEST_SUCCESS = {
  message: "Retry task in progress!",
  variant: SUCCESS_VARIANT_TYPE
};
