import React from "react";
import Selector from "../selector/Selector";
import get from "lodash/get";
import useLanguageField from "./hooks/useLanguageField";

export default function LanguageField({
  input: { name, onChange, value, ...restInput },
  meta,
  relatedContents,
  label,
  ...rest
}) {
  const { options } = useLanguageField(relatedContents);

  return (
    <Selector
      {...rest}
      label={label}
      options={options}
      name={name}
      helperText={meta.touched ? meta.error : undefined}
      error={meta.error && meta.touched}
      inputProps={restInput}
      onChange={value => onChange(get(value, "value", undefined))}
      value={value}
    />
  );
}
