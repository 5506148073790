const invalidValueError = "Invalid JSON";

export default function validateJSON(value, _params, _type, _scope) {
  if (!value) {
    return null;
  }

  try {
    JSON.parse(value);
  } catch (error) {
    return getValidationJSONErrorMessage(value, error);
  }

  return null;
}

function getValidationJSONErrorMessage(value, error) {
  if (error.message) {
    const lineError = getLineError(value, error);

    if (lineError) {
      return `${
        error.message.split(" position ")[0].split(" at line")[0]
      } at line ${lineError}`;
    }

    return error.message;
  }
  return invalidValueError;
}

function getLineError(value, error) {
  const errorMessage = error.message;

  const hasPosition = errorMessage.includes("position");

  if (hasPosition) {
    const errorPosition = Number(errorMessage.split("JSON at position ").pop());
    return value.substr(0, errorPosition).split("\n").length - 2;
  }

  const hasLine = errorMessage.includes("at line");

  if (hasLine) {
    return (
      Number(errorMessage.split(" at line ").pop().split(" column")[0]) - 2
    );
  }

  return null;
}
