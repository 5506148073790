const invalidJSONSchemaError = "Invalid JSON Schema";

export default function validateJSONSchema(value) {
  if (!value) {
    return null;
  }

  try {
    JSON.parse(value);
  } catch (err) {
    return err.message || invalidJSONSchemaError;
  }

  return null;
}
