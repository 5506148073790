import React from "react";
import Paper from "@material-ui/core/Paper";
import { Box, Divider, makeStyles, Typography } from "@material-ui/core";
import { ThemeProvider } from "../../../store/themeContext";
import Button from "../button/Button";
import { YELLOW } from "../../styles/theme";

export default function AppErrorFallback({ error, resetErrorBoundary }) {
  const classes = useStyles();

  return (
    <ThemeProvider>
      <Paper className={classes.root}>
        <Box p={4}>
          <Typography variant="h4" noWrap>
            Something went wrong
          </Typography>
          <Divider />
          <pre className={classes.errorContainer}>{error?.message}</pre>
          <Button
            color={YELLOW}
            onClick={resetErrorBoundary}
            label="Try again"
          />
        </Box>
      </Paper>
    </ThemeProvider>
  );
}

const useStyles = makeStyles({
  root: {
    width: "100%",
    padding: "2rem 0",
    height: "100%"
  },
  errorContainer: {
    maxHeight: 300,
    overflowY: "scroll",
    overflowX: "hidden"
  }
});
