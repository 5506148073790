import Api from "../Api";
import {
  BACKEND_MEDIA_URL,
  MEDIA_CATEGORIES_URL
} from "../constants/endpoints";

export function getMediaCategories(application) {
  return Api.get(
    `${BACKEND_MEDIA_URL}${MEDIA_CATEGORIES_URL}?application=${application}`
  );
}

export function addMediaCategory(name, application) {
  return Api.post(`${BACKEND_MEDIA_URL}${MEDIA_CATEGORIES_URL}`, {
    data: {
      name,
      application
    }
  });
}

export function deleteMediaCategory(id) {
  return Api.delete(`${BACKEND_MEDIA_URL}${MEDIA_CATEGORIES_URL}/${id}`);
}

export function getCategoryDetails(categoryId) {
  return Api.get(
    `${BACKEND_MEDIA_URL}${MEDIA_CATEGORIES_URL}/${categoryId}/assets`
  );
}

export function uploadAsset(categoryId, name, file) {
  const bodyFormData = new FormData();
  bodyFormData.set("categoryId", categoryId);
  bodyFormData.set("name", name);
  bodyFormData.set("file", file);

  return Api.post(
    `${BACKEND_MEDIA_URL}${MEDIA_CATEGORIES_URL}/${categoryId}/assets`,
    bodyFormData,
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      }
    }
  );
}

export function deleteAsset(categoryId, assetId) {
  return Api.delete(
    `${BACKEND_MEDIA_URL}${MEDIA_CATEGORIES_URL}/${categoryId}/assets/${assetId}`
  );
}

export function deleteMultipleAssets(categoryId, assets) {
  const payload = {
    assetIds: assets.map(asset => asset.id)
  };
  return Api.delete(
    `${BACKEND_MEDIA_URL}${MEDIA_CATEGORIES_URL}/${categoryId}/assets`,
    {
      data: payload,
      headers: {
        "Content-Type": "application/json"
      }
    }
  );
}
