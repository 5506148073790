import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import TextFieldMUI from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useAuthorization } from "../../../store/authorizationContext";
import { VIEW_CONTENT_SECRET_VARIABLE_ACTION } from "../../../roles/roles";

function TextField({
  id,
  input: { name, onChange, value },
  meta,
  field,
  label,
  placeholder,
  required,
  disabled,
  fullWidth,
}) {
  const [showFieldValue, setShowfieldValue] = useState(false);
  const { isUserAllowedTo } = useAuthorization();

  const onChangeText = useCallback(
    event => {
      const value = get(event, "target.value", "");
      const trimmedValue = value.trim() && value;
      onChange(trimmedValue);
    },
    [onChange]
  );

  const handleClickOnShowFieldValue = () => {
    setShowfieldValue(!showFieldValue);
  };

  const MAX_VISIBLE_CHARS = 3;
  const checkVisibilityValue = value => (field.isSecretVariable && !showFieldValue)
    ? `${value.substr(0, MAX_VISIBLE_CHARS)}***${value.substr(value.length - MAX_VISIBLE_CHARS)}`
    : value;

  return (
    <TextFieldMUI
      id={id}
      name={name}
      label={label}
      fullWidth={fullWidth}
      required={required}
      disabled={field.isSecretVariable && !showFieldValue ? true : disabled}
      placeholder={placeholder}
      type="text"
      helperText={meta.touched ? meta.error : undefined}
      error={meta.error && meta.touched}
      InputProps={{
        startAdornment: field && field.icon && (
          <InputAdornment position="start">{field.icon}</InputAdornment>
        ),
        endAdornment: field && field.isSecretVariable && (
          <InputAdornment position="end">
            <IconButton
              tabIndex={-1}
              id={`${id}-show-field-value`}
              aria-label="Toggle field value visibility"
              disabled={!isUserAllowedTo(VIEW_CONTENT_SECRET_VARIABLE_ACTION)}
              onClick={() => handleClickOnShowFieldValue()}
            >
              {showFieldValue ? (
                <Visibility id={`${id}-show-field-value-on`} />
              ) : (
                <VisibilityOff id={`${id}-show-field-value-off`} />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
      onChange={onChangeText}
      value={checkVisibilityValue(value)}
    />
  );
}

TextField.propTypes = {
  id: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  meta: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
};

TextField.defaultProps = {
  field: {},
  meta: {},
};

export default TextField;
