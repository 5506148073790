import { useRef, useState, useLayoutEffect } from "react";

export default function useDimensions() {
  const ref = useRef();

  const [dimensions, setDimensions] = useState({});

  useLayoutEffect(() => {
    const current = ref.current;
    if (current) {
      const dimensions = current.getBoundingClientRect().toJSON();
      setDimensions(dimensions);
    }
  }, [ref]);

  return [ref, dimensions];
}
